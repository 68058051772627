import React, { useEffect, useState } from 'react'
import userimg from '../../assets/SVGs/usersvg.svg'
import { useAuth } from '../../context/AuthContext'
import Logo from '../../assets/SVGs/PKT1 SVG white.svg'
import LogOutIcon from '../../assets/SVGs/LogOutIcon.svg'
import { useSidebar } from '../Sidebar/hook/useSidebar'

const Header = () => {

  const {CerrarSesion, RefrescarSesion, User, UserData, AutoRefreshToken} = useAuth()

  const [FlagHeadOpcs, setFlagHeadOpcs] = useState(false)
  

  
  return (
    <div className='App-Header'>
      <div className='HeaderTitulo'>
        <h3 className='TextoTitulo'>
          {localStorage.getItem('ActCat') ? JSON.parse(localStorage.getItem('ActCat')).toUpperCase() : ''}
        </h3>
      </div>

      {/* <div className='HeaderLogo'>
        <img className='HeaderLogoImg' src={Logo} alt="" />
      </div> */}

      <div className='AreaUsuario'>
        <img className='Header-userImg' src={userimg} alt="" onClick={()=>setFlagHeadOpcs(!FlagHeadOpcs)}/>
        <ul className='HeaderOpcs' style={FlagHeadOpcs ? {} : {display:'none'}} onMouseLeave={()=>setFlagHeadOpcs(false)}>
          <li className='Head-Opc' onClick={()=>CerrarSesion()}>
           <p className='txt-Opc'>Cerrar sesión</p> <img src={LogOutIcon} alt="" />
          </li>
          {/* <li className='Head-Opc' onClick={()=>RefrescarSesion(User.token, User.refreshToken)}>Refrescar token</li> */}
        </ul>
      </div>
    </div>
  )
}

export default Header