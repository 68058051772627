import styled from 'styled-components';
import '../App.css'

const LayoutBtn = styled.div`
outline: none;

.accept {
    cursor: pointer;
    background: #3BAF29;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 100%;
    
}
.deny {
    cursor: pointer;
    background: #F40000;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 100%;

}
.custom{
    cursor: pointer;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 100%;
}
.iconoMini {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    border: none;
}
.add{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    background: var(--AzulPKT1);
}
.img-descript {
    width: 75%;
    height: 30px;
}
.txt-descript {
    margin: auto;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    margin: 5px;
}
.img-add{
    width: 70%;
}

.btn-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}
`
const LayoutInpt = styled.div`
/* display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
width: 100%; */


.InptBtn-Distr{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1.8fr 0.2fr;
    grid-template-rows: 0.6fr 1.4fr;
    gap: 0px 0px; 
    grid-template-areas: 
        "Texto ."
        "Input Boton"; 
}



.OnlyInpt-Distr{
    width: 100%
}

.Titul-core{
    margin: 0;
    grid-area: Texto;
}

.Inpt-core {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Inpt-Btn {
    grid-area: Input;
    outline: none;
    border: 2px solid #666770;
    border-radius: 4px 0px 0px 4px;
    color: #333333;
    font-size: 16px;
    padding: 4px;
    width: 100%;
    /* outline: none; */
    box-sizing: border-box;
}


.Inpt {
    border: 2px solid #666770;
    border-radius: 4px;
    color: #333333;
    font-size: 16px;
    padding: 4px;
    width: 100%;
    /* outline: none; */
    box-sizing: border-box;
}

/* .InptSlct-Distr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
} */

.Slct{
    border: 2px solid #666770;
    border-radius: 4px;
    color: #333333;
    font-size: 16px;
    padding: 4px;
    width: 100%;
}

input.date::-webkit-calendar-picker-indicator {
    opacity: 0.6;
}

.Btn-Show{
    grid-area: Boton;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;

    border: 2px solid #666770;
    border-left: none;
}


.Btn-Hide{
    display: none;
}


`
const LayoutPaginacion = styled.section`
    
display: flex;
align-items: center;
justify-content: space-between;
width: 99%;
margin: auto;

.Paginacion{
    user-select: none;
    display: flex;
    flex-direction: row;
    /* width: 30%; */
    justify-content: flex-end;
    align-items: flex-start;
}

.next-plus{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.next{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.prev-plus{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.prev{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.pg{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px 10px;
    min-height: 30px;
    /* min-width: 100px; */
    /* width: 25%; */

    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    color: #9597a7;
    font-size: 20px;
    font-weight: bold;
}

.next:hover{
    background: #d9eede;
}
.next-plus:hover{
    background: #d9eede;
}
.prev:hover{
    background: #d9eede;
}
.prev-plus:hover{
    background: #d9eede;
}


`
const TablaLayout = styled.section`
width: 100% !important;
margin:auto;
margin-top: 1px;
 @media only screen and (min-width: 300px) {
    .Texto-Tabla{
        color: var(--TextoColor);
        margin: 0 ;
    }
    .TablaSinpaginador{
        width: 100%;
        margin: auto;
    }
    .Cabezera-TablaSinPaginador{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 7px;
    }
    .Cuerpo-TablaSinPaginador{
        display: flex;
        flex-direction: row;
        width: 300%;
        align-items: center;
        border-bottom: 2px solid #94959B;
        font-size: 14px;
        /* height: 30px; */
        padding: 10px 0px;
    }
    .ItemHeader-TablaSinPaginador{
        text-align:center;
        font-weight: bold;
        color: ${(props) => props.Azul};
        margin-right: 10px;
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 100px;

    }
    .ItemCuerpo-TablaSinpaginador{
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        min-width: 110px;
    }
    /* .Cuerpo-TablaSinPaginador:hover{
        background: #3BAF29;
        color: #fff;
        cursor: pointer;
    } */
   



    // TABLA PAGINADOR                          <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

    .dots-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        /* margin-right: 5px;
        margin-top: 45px; */
        top: 60%;
        right: 10px;
        padding: 2px 4px;
        border-radius: 8px;
        z-index: 100;
        background: #dbdded;
        list-style: none;
        box-shadow: 0px 2px 3px #808080;
    }
    .dots-menu-inverted {
        display: flex;
        flex-direction: column;
        position: absolute;
        /* margin-right: 5px; */
        /* margin-bottom: 45px; */
        bottom: 60%;
        right: 10px;
        padding: 2px 4px;
        border-radius: 8px;
        z-index: 100;
        background: #dbdded;
        list-style: none;
        box-shadow: 0px 2px 3px #808080;
    }
    


    .cont-dots {
        display: flex;
        justify-content: space-evenly;
        height: 30px;
        width: 30px;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .cont-dots-active {
        display: flex;
        justify-content: space-evenly;
        height: 30px;
        width: 30px;
        flex-direction: column;
        align-items: center;
        background: #dbdded;
        border-radius: 100%;
        cursor: pointer;
    }

    .ItemCuerpo-AccionesPaginador {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span.dotAction {
        border-radius: 50%;
        width: 5px;
        height: 5px;
    }

    ul.dmDown{
        position: absolute;
        right: 8px;
        top: 55%;
        z-index: 99;
        background: #dbdded;
        border-radius: 5px;
        padding: 2px;
    }
    
    ul.dmUp{
        position: absolute;
        right: 8px;
        bottom: 55%;
        z-index: 99;
        background: #dbdded;
        border-radius: 5px;
        padding: 2px;
    }

    li.dm-row {
        display: flex;
        align-items: center;
        padding: 3px 30px 3px 4px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        border-radius: 8px;
    }
    li.dm-row:hover {
        background: #bfc1d4;
    }
    .cont-rowIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F0F1F3;
        border-radius: 8px;
        border: 2px solid #9597A7;
        margin-right: 5px;
        height: 30px;
        width: 30px;
    }

    img.row-icon {
        width: 22px;
    }

    .row-txt{
        margin: 0;
        color: #666770;
    }


    .ContenedorTablaFiltrosPaginador{
        display: flex; 
        flex-direction: column;
        width: 98%;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .iconnuevo-peticion{
        position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    }
    .containerFiltrosPaginador{
       position: relative;
        width: 90%;
        margin: auto;
    }
    .CamposFiltrosPaginaror{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .CamposFiltrosPaginaror-Izquierdo{
        display: flex;
        flex-direction: row;
    }
    .Cabezera-TablaPaginador{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 10px 10px 0px 0px;
        background:  ${(props) => props.BkHeader};
        color: #fff;
        font-weight: bold;
        width: 100%;
        overflow-x: hidden;
    }
    .Cuerpoprincipal{
        box-sizing: border-box;
        height: 50vh;
        width: 100%;
        max-height: 50vh;
        overflow-x: scroll;
        border-left: 2px solid grey;
        border-right: 2px solid grey;
        border-bottom: 2px solid grey;
        padding-right: 0px;
        background: rgb(255, 255, 255);
        overflow-x: hidden;
    }

    


    .ItemCuerpo-Tablapaginador{
        text-align: center;
    }
    .contenedororigenPeticionesTabla{
        /* display: flex;
        flex-direction: row;
        width: 50px;
        height: 30px;
        align-items: center;
        padding-left: 2%; */

        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        height: 32px;
        width: 100%;
        justify-content: space-around;
    }
    .ContainerActions{
        //style={{background:'salmon', width:'50%', padding:'2px', borderRadius:'100%'}}
        background-color: gray;
        width: 50%;
        padding: 2px;
        border-radius: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    width: 30px;

    
    /* position: absolute;
    right: 3px;
    top: 5px;
    width: 17px !important;
    height: 35px;
    border-radius: 100%;
    margin-right: 4px; */
    /* width: 100%; */

}
.ContainerActions span{
    /* position: absolute; */
    /* background: #9597A7; */
    border-radius: 50%;
    width: 5px;
    height: 5px;
    /* right: 15px; */
}
.active{
position: absolute;
right: 3px;
top: 5px;
width: 35px !important;
height: 35px;
background-color: #D9D9D9;
border-radius: 100%;
/* opacity: 40%; */
cursor: pointer;
}
/* .ContainerActions span:nth-child(1){
    bottom: 26px;
}
.ContainerActions span:nth-child(2){
    bottom: 16px;
}
.ContainerActions span:nth-child(3){
    bottom: 6px;
} */
.ContainerSubAction{
    display: flex;
    flex-direction: column !important;
    position: absolute !important;
    top: 43px;
    right: 25px;
    background: #DBDDED !important;
    border-radius: 8px;
    z-index: 2;
    box-shadow: -1px 7px 17px rgb(0 0 0 / 35%);    
    border: solid #D4D3D3 1px;
    color: #666770;
    font-weight: bold;
    cursor: pointer;
    width: 225px;
}
.ItemSubmenu{
    display: flex;
    justify-content: space-around;
    height: 49px;
}
.ItemSubmenu img{
    padding-right: 10px;
}
.ItemSubmenu p{
    text-align: left;
    max-width: 80%;
}
}
@media only screen and (max-width: 1366px) {

}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .Cuerpo-TablaSinPaginador{
      
        width: 100%;
       
    }
    .Cabezera-TablaPaginador{
       
        width: 100%;
    }
    .ContenedorTablaFiltrosPaginador{
      
        flex-direction: row;
     
    }
    .CamposFiltrosPaginaror{
      
        flex-direction: row;
        
    }
    .iconnuevo-peticion{
        position: relative;
        cursor: pointer;
    }
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {

}
`;


const LayoutContainer = styled.section`


height: 100vh;
    
.logintitulo{
    color: var(--AzulPKT1)
}


@media only screen and (min-width: 0px){
    display: none;
}

@media only screen and (min-width: 300px){
    display: block;

    .ContainerPrivRout{
        height: 100%;
        background: var(--Fondo);
        display: flex;
        flex-direction: column;

    }
    
    //HEADER
    .ContainerHeader{
    }
    
    .App-Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        border-radius: 15px;
        margin: auto;
        background: var(--AzulHeader);
        color: var(--Blanco);
        position: relative;
    }
    .AreaUsuario{
        margin: 0px 2%;
    }
    .HeaderTitulo{
        display: none;
    }
    .HeaderLogo{
        margin: 0 2%;
    }
    .HeaderLogoImg{
        width: 100px;
    }
    .Header-userImg{
        cursor: pointer;
    }

    //HEADSIDE
    .HeadSide{
        display: none;
    }

    //SIDEBAR
    .ContainerSidebar{
        display: none;
        background: var(--Blanco);
        transition: all 0.7s ease-out 0s;
    }

    //BODY
    .ContainerAppBody { 
        grid-area: Body; 
        width: 100%;
        height: 100%;
        padding: 0 1.5%;
        overflow-y: scroll;
        box-sizing: border-box;
    }

    //FOOTER
    .ContainerFooter{
        font-weight: var(--Negritas);
        background: var(--Blanco);
    }

    .App-Footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

    }
    .txt-foot{
        margin: 0% 1%;
    }


}

@media only screen and (min-width: 600px){

}

@media only screen and (min-width: 768px){

    .Atajos {
        margin-top: 5%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas: "a b c";
    }
 
    .Atajos-flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5rem;
    }
    
    .Atajo-flex-Elmnt {
        background: #3BAF2A;
        padding: 1px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 15%;
        margin: 10px auto;
        border-radius: 6px;
        padding: 0px 5px;
        cursor: pointer;
    }

    .Atajo-Elmnt {
        background: orange;
        padding: 1px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        margin: auto;
        border-radius: 6px;
    }
    
    .Welcome-User {
        display: flex;
        flex-direction: column;
        background: #2f3768;
        width: 30vw;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
    }
    .WelcomeText {
        margin: 5px 0;
        color: #fff;
    }
    .AtajoText {
        margin: 5px 0;
        color: #fff;
        font-weight: bold
    }
    
    .buscaProd {
        margin-right: 15px;
    }
    .FiltActivs {
        margin-right: 15px;
    }
    .ContainerPrivRout{
        display: grid; 
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 10fr;
        grid-template-rows: 2fr 20fr 1fr;
        gap: 0px 0px; 
        grid-template-areas: 
            "HeadSide Header"
            "Sidebar Body"
            "Footer Footer"; 
        
    }
    //HEADER
    .ContainerHeader { 
        grid-area: Header; 
    }
    .App-Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        border-radius: 15px;
        margin: auto;
        background: var(--AzulHeader);
        color: var(--Blanco);
    }
    .AreaUsuario{
        margin: 0px 2%;
    }
    .HeaderTitulo{
        display: block;
        margin: 0px 2%;
    }
    .HeaderLogo{
        display: none;
    }
    .TextoTitulo{
        margin: 0;
    }



    
    //HEADSIDE
    
    .ContainerHeadSide{
        background: var(--Blanco);
        display: flex;
        position: relative;
    }

    .HeadSide{
        display: flex;
        position: relative;
        flex: 1;
        position: relative;
        overflow: hidden;
        transition: all 0.7s ease-out 0s;
    }
    
    .LogoBackground{
        flex: 1;
        position: relative;
        overflow: hidden;
        transition: all 0.7s ease-out 0s;
        width: 150px;
    }

    .Logopkt1{
        position: absolute;
        top: 0;
        width: 100%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .img-LogoPKT1{
        width: 100px;
        margin-bottom: 10px;
    }
    ul.HeaderOpcs {
        position: absolute;
        right: 0;
        top: 55px;
        transition: all 0.5s ease 0s;
        background: rgb(255, 255, 255);
        list-style: none;
        display: inline-block;
        border-radius: 7px;
        z-index: 2;
        box-shadow: rgb(145, 143, 143) 2px 6px 23px -12px;
        margin: 0;
        padding: 0;
    }

    li.Head-Opc {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333;
        font-weight: bold;
        padding: 5px;
        margin: 5px 5px;
    }

    li.Head-Opc:hover {
        cursor: pointer;
        background: #b4dbb0;
        padding: 5px;
        border-radius: 4px;
    }
    .txt-Opc{
        margin: 0;
        margin-right: 15px;
        margin-right: 15px;
    }
    



    //SIDEBAR
    .ContainerSidebar { 
        grid-area: Sidebar;
        display: block;
    }
    .ContainerLogo{
        
    }
    .SideElement {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0;
        width: 100%;
        justify-content: flex-start;
        cursor: pointer;
    }

    .icon-sidebar {
        width: 32px;
        flex: 1;
    }

    .txt-SideElement {
        flex: 4;
        margin: auto 10px;
    }

    .IndicadorCategoria{
        width: 20px;
        
    }
    .Sidemenu-Bluebar{
        width: 4px;
        height: 40px;
        background: var(--AzulPKT1);
        margin-right: 5px;
    }

    .collapse-SideMenu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .collapser{
        cursor: pointer;
    }
    .img-collapser{
        /* margin-right: 10px */
    }


    //BODY

    .Seccion-Area {
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        align-items: center;
    }
    .herramientas {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        /* height: 10%; */
        width: 100%;
    }
    .filtros {
        flex: 9;
        display: flex;
        flex-direction: row;
        align-items: flex-end ;
        justify-content: flex-start;
    }

    .fchCreacion {
        margin-right: 15px;
    }
    .fchTimbrado {
        margin-right: 15px;
    }
    .tipoFactura {
        margin-right: 15px;
    }
    .estatus {
        margin-right: 15px;
    }
    .metodoPago {
        margin-right: 15px;
    }


    .agregar {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .tabla {
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        /* height: 75%; */
        width: 100%;
    }
    .paginacion {
        flex: 1.5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* height: 15%; */
        width: 100%;
    }

    



    //FOOTER
    .ContainerFooter { 
        grid-area: Footer; 
    }
    .icon-sidebar{
        width: 32px
    }


}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
    
` 

const LayoutLogin = styled.section`

@media only screen and (min-width: 0px){
    display: none;
}

@media only screen and (min-width: 300px) {
    
    display: flex;
    flex: 1;
    width: 100%;
    height: 100vh;

.login-principal{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}
.contenedor-login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.cuerpo-login {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    height: 90%;
    width: 90%;
    margin: auto;
}
.formulario-login {
    background: rgba(255, 255, 255, 0.32);
    backdrop-filter: blur(5px);
    border-radius: 0px 0px 15px 15px;
}
.contenedor-datos {
    display: flex;
    height: 100%;
    width: 90%;
    margin: auto;
}
.cuerpo-formulario {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}
h1{
    font-size: 32px;
    margin: 12px 0px;
    color: white;
    text-shadow: rgb(70, 73, 71) 0.1em 0.1em 0.2em;
}
h2 {
    color: white;
    margin: 12px 0px;
}

.boton-logear {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: 5% 0;
}




/* input[type="text"] {
    border-radius: 5px;
    border: 0px solid;
    padding: 8px 4px;
    font-size: 16px;
} */
/* button {
    background: #3baf29;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
    border: 0px;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
} */

.logImg{
    display: none;
}
.logImgMov{
    display: block;
    width: 100%;
}
.img-logearse{
    width: 16px;
    margin-right: 10px;
}
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
.cuerpo-login {
    flex-direction: row;
    height: auto;
}
.formulario-login{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 15px 0px 0px 15px;
}

.contenedorimg {
    flex: 1;
}
    
.logImg{
    display: block;
    width: 100%;
    height: 100%;
}
.logImgMov{
    display: none;
    
}
.boton-logear {
    display: flex;
    margin: 10% 0;
    justify-content: flex-start;
}
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
    
`
const LayoutPreloader = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
const ModalLayout = styled.section`
    height: 100%;
    
 @media only screen and (min-width: 300px) {
    .customModal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: flex-start

;
    }
    .generalInfoShow{
    background: #c3c3c3;
    border-radius: 6px;
    padding: 10px;
    /* width: 20%; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
}

.CambStatus-Clteinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #d5d5d5;
    margin: 15px 0px;
    border-radius: 10px;
    border: 2px solid gray;
    box-sizing: border-box;
}

.txt-CambStatus {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 10px;
}

.ReCorreo-InfoFact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #d5d5d5;
    margin: 15px 0px;
    border-radius: 10px;
    border: 2px solid gray;
    box-sizing: border-box;
}
.ReCorreo-infos {
    height: 99%;
    margin: auto 0px 0px 0px;
}
.ReCorreo-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.txt-ReCorreo {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 10px;
}

.CancelFact-InfoFact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #d5d5d5;
    margin: 15px 0px;
    border-radius: 10px;
    border: 2px solid gray;
    box-sizing: border-box;
}
.CancelFact-infos {
    height: 99%;
    margin: auto 0px 0px 0px;
}
.CancelFact-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.txt-CancelFact {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 10px;
}

.generalInfoShow p{
    color: #535353;
    font-weight: bold;
    margin: 0;
    text-align: center;
}
.Header-Modal{
    background: ${(props) => props.colorHeader};
    position: relative;
    display:flex;
    align-items: center;
    border-radius: 10px;
    /* flex: 1; */
}
.Body-modal {
    height: 100%;
}

.CambStatus-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.btn-CambiarEstatus {
    display: flex;
    justify-content: center;
}



    .titulo-modal{
    flex: 3;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    box-sizing: border-box;
    padding: 5px 0px;
}
.titulo-modal h3{
    color: ${(props) => props.Blanco};
    margin: 0px;
    padding: 0px;
}
.cerrarmodal{
    cursor: pointer;
    position: absolute;
    right: 10px;
}
.cerrarmodal p{
    color: ${(props) => props.Blanco};
    margin: 0px;
    padding: 0px;
    font-weight: bold;
    font-size: 22px;
    width: 32px;
    text-align: center;
}
.container-Origen-MODALPETICIONES{
    
}
.imgOrigen{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.containerimgorigen{
    width: 150px;
    margin: 40px;
}
// MODAL DATOS ONSITE
.HeaderPaginatorNew{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
}


.btnSeleccionartodo button{
    padding:11px 24px 11px 24px; 
    border-radius:7px; 
    color:#fff; 
    font-weight:bold; 
    background:#444971; 
    cursor:pointer;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.35));
    font-size: 15px;
}
.btnSeleccionartodo button:hover{
    background: #151B4E;
}
.btnAceptar{
    padding-top: 7px;
    display: flex;
    justify-content: center;
    position: sticky;
}
.btnAceptar button {
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    height: 36px;
    width: 130px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    margin: auto;
}
.btnAceptar button:hover {
    background-color: #2E901F;
}
.ContainerCheckAddressButton{
        display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3px;
    }
    .containerDatosResumen h4{
        margin-bottom: 5px;
        color: #151B4E;
    }
    .containerDatosResumen p{
        margin-top: 0px;
        color: #333333;
    }
    .ContainerCheckAddress p{
        text-align: center;
    color: rgb(102, 103, 112);
    font-weight: 500;
    margin-bottom: 10px;
    }
    .Boton_Aceptar button{
        background-color: #3BAF29;
    color: rgb(255, 255, 255);
    width: 130px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    font-weight: bold;
    margin-right: 20px;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    }
    .Boton_NuevaPeticion button{
    border-radius: 7px;
    padding-top: 4px;
    border: none;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    cursor: pointer;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 20px;
}
.Boton_NuevaPeticion button:hover{
    color: rgb(255, 255, 255);
    background: rgb(21, 27, 78);
}
.Contenido-Grid-NuevadireccionRecoleccion{
    display: grid; 
    grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 29px 8px; 
  grid-template-areas: 
    "Mapa NUEVO"; 
    padding-top: 20px;
}
.MapaNueva{
    grid-area: Mapa;
}
.Nuevadireccion{
    grid-area: NUEVO;
}
.CambioCiudad{
    display:flex;
   
    width: 100%;
    justify-content: center;
    align-items: center
}
    .ContainerCiudadesCompara{
        display: flex;
        justify-content: space-between;
    }
    .ContenedorbottonesModal{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .Contenedorbotones{
        display: flex;
        justify-content: space-around;
    }
    .BotonSucces{
        background: #2E901F;
        border: 1px solid gray;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        padding-right: 25px;
        padding-left: 25px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 6px;
    }
    .ContainerModalDetallesPeticion{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        align-items: center;
        text-align: center;
    }
    .ContainerOrigenDetallesPeticion{
        display: flex;
        align-items: center;
        width: 46px;

    }
    .ContainerOrigenDetallesPeticion img{
        width: 100%;
        display: block;
        margin: auto;
    }
    .itemSoliPet h3{
        color: #151B4E;
        font-size: 18px;
    }
    .itemSoliPet p{
        font-weight: bold;
        color:#333333;
    }
    .ContainerModalDetallesPeticion div p {
        margin-bottom: 10px;
    }
    .TimlineDetallesPetiones{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .TimlineDetallesPetiones li {
        margin-right: 30px;
    }
    .SeparadorHistorialDetallesPeticion{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .SeparadorHistorialDetallesPeticion h4{
        margin-bottom: 5px;
        font-size: 18px;
        color: #151B4E;
        font-weight: bold;
    }
    .ContainerEditarPEticionDetalles{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .BotonEditerPeticionEstatusDetalles{
        background: #FFB531;
        margin-right: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
    }
    .BotonCancelarPeticionEstatusDetalles{
        background: red;
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
    }
    // BOTON
    .BotonAceptar{
        background: #3BAF29;
        margin-right: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
        border: 1px solid grey;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
    }
    .BotonCancelar{
        background: red;
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
        border: 1px solid grey;
        color: #fff
    }

    .BotonCancelarPeticionEstatusDetalles p{
        margin: 0px;
        color: #fff;
        font-weight:bold;
    }
    .BotonEditerPeticionEstatusDetalles p {
        margin: 0px;
        color: #fff;
        font-weight:bold;
    }








  // MODAL MERCANCIA
  .containerimgMercanciaPeticion{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 20px;
  }
  
  .containerimgMercanciaPeticion img{
    width: 100%;
  }
  .ContenedorImagen{
    display: flex;
    justify-content: center;
    width: 5vw;
    height: 11vh;
    padding: 20px;
    border-radius: 10px;
  }
  .ContenedorImagenActivo{
    display: flex;
    justify-content: center;
    width: 5vw;
    height: 11vh;
    padding: 20px;
    background: #3BAF29;
    border-radius: 10px;
  }
  .ContenedorImagen:hover{
    display: flex;
    justify-content: center;
    width: 5vw;
    height: 11vh;
    padding: 20px;
    background: #3BAF29;
    border-radius: 10px;
    cursor: pointer;
  }
  .containerInfoInputs{
    display: flex;
    width: 75%;
    margin: auto;
  }


  //MODAL FACTURAS PENDIENTES DE PAGAR
    .filter-tipoFact {
        display: flex;
        align-items: flex-end;
        margin: 1rem 0;
    }
    .BotonBuscar {
        margin-left: 1rem;
    }
    
    .TablaPendings-Head {
        flex: 1;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: "check CFDI FchFact TotalFact AbonadoFact SaldoFact"; 

        background: #5B5F83;
        color: white;
        font-weight: bold;
        border-radius: 2px;
            flex: 1;
    }
    .TablaPendings-Body {
        flex: 9;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .TablaPendings-Row {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: "check CFDI FchFact TotalFact AbonadoFact SaldoFact";

        background: #ffffff;
        border: 2px solid gray;
        margin: 2px 0px;
    }

    .TablaPendings-Chek{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: check; 
    }
    .TablaPendings-CFDI{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: CFDI;
    }
    .TablaPendings-FchFact{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: FchFact;
    }
    .TablaPendings-TotalFact{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: TotalFact;
    }
    .TablaPendings-AbonadoFact{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: AbonadoFact;
    }
    .TablaPendings-SaldoFact{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: SaldoFact;
    }

    input.Chek-AllSelects {
        cursor: pointer;
        transform: scale(2);
    }
    p.TablaPendings-Txt{
        margin: 8px 0;
    }
    .Bt-PendingFacts {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }
    .Tabla-PendingFacts {
        flex: 1;
    }
    .cont-PendingFacts {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    
}
@media only screen and (max-width: 1366px) {
 
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {

}
`

const LayoutAlterCliente = styled.section`
width: 100%;

.cont-infoClte {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
box-sizing: border-box;
margin: 15px 0;
}
.cont-tipoClte-cp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
p.txt-infoClte {
    margin: 10px 0px;
}
.cinta-Titulo {
    background: #151F56;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}
.cont-Titulo {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.txt-cintaTitulo{
    margin: 0;
    text-align: center;
}

.txt-cintaEnlace{
    margin: 5px 0px;
}
.Alter-Client {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cont-alterClte {
    width: 90%;
    display: flex;
    flex-direction: column;
}   
.cont-Alters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 80%; */
    width: 100%;
}
.cont-enlacepkt1 {
    width: 80%;
    /* height: 100%; */
    margin: auto;
}
.infoShow-pkt1 {
    background: rgb(255, 255, 255);
    padding: 4px 8px;
    border-radius: 10px;
    border: 2px solid gray;
    /* width: 100%; */
    text-align: center;
    box-sizing: border-box;
}
.txt-cltepkt1 {
    margin: 0;
    min-width: 10px;
    min-height: 16px;
}
.info-CltePKT1 {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-start;
}

.info-client {
    background: white;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgb(149, 151, 167) 1px 3px 12px;
}
.cont-enlacepkt1 {
    width: 80%;
    margin: 0 auto;
    background: white;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgb(149, 151, 167) 1px 3px 12px;
}
.enlace-cliente {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.enlace-titulo {
    display: flex;
    justify-content: space-between;
}
.cont-idpkt1 {
    display: flex;
    flex-direction: column;
}
.cont-enlazar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 20px 0;
}
.buscar {
    display: flex;
}

`
const LayoutAddFactura = styled.section`
width: 100%;
height: 100%;

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.AddFact{
    overflow-y: scroll;
    background: white;
    border-radius: 6px;
    height: 98%;
}
.inpt-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.txt-inpt {
    margin: 0;
    margin-right: 10px;
}
p.devcaution {
    margin: 0;
    color: #e30606;
    font-weight: bold;
    position: absolute;
    right: 0;
}
.InptTotalito {
    display: flex;
    justify-content: flex-end;
}


.barra, .barra_recept {
    background: #848484;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
    margin-left: 2%;
    margin-top: 10px;
    padding: 2px 2px 2px 20px;
}

.separador {
    background: gray;
    width: 100%;
    color: white;
    font-weight: bold;
    padding: 5px;
    border-radius: 6px;
    box-sizing: border-box;
}

.Tipo-Fact {
    display: flex;
}
.infoFactura-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.datosPago-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.datosClte-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.in-FactData {
    margin: 5px 0px;
}
.list-Clientes {
    position: absolute;
    max-height: 10rem;
    width: 100%;
    overflow-y: scroll;
    background: white;
    box-sizing: border-box;
}
.listed-client:hover{
    background: #b4dbb0;
}
.listed-client{
    border: 1px solid gray;
    border-top: none;
    padding: 4px 0px 4px 4px;
}
.In-ProdName {
    position: relative;
    width: 100%;
}
.list-Prods {
    position: absolute;
    max-height: 10rem;
    width: 100%;
    overflow-y: scroll;
    background: white;
    box-sizing: border-box;
    z-index: 99;
}
.listed-prod {
    border: 1px solid gray;
    border-top: none;
    padding: 4px 0px 4px 4px;
}
.listed-prod:hover{
    background: #b4dbb0;
}
.inptElmnt-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}


.resumenBody-cont {
    display: flex;
    width: 100%;
}

.infoFactura-grid {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1.3fr 0.4fr 1.3fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "datosPago-grid . datosClte-grid"; 
  width: 95%; 
}
.datosPago-grid {
    grid-area: datosPago-grid; 
    display: flex;
    justify-content: flex-start;
}
.datosClte-grid {
    grid-area: datosClte-grid; 
    display: flex;
    justify-content: flex-start;
}

.infoFactura-flex {
    width: 100%;
}
.inDatosPago-cont {
    margin: 10px 0;
}
.inDatosClte-cont {
    margin: 10px 0;
}

.PaymentArea {  
    display: grid;
    grid-template-columns: 1fr 1.2fr 0.8fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "observs . totals";
    width: 95%;
    margin: auto;
    margin-top: 15px;
}

.observs { 
    grid-area: observs;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
 }
 .boton-aceptar {
    display: flex;
    justify-content: center;
}

.totals { grid-area: totals; }



.resumen-head {
    display: flex;
    background: gray;
    color: white;
    border-radius: 4px;
    padding: 10px 0px;
    margin-bottom: 2px;
}
.resumen-body {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-top: 8px;
}
.resumenBody-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0px;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 8px 0px;
}
.resumen-elemnt {
    display: flex;
    align-items: center;
    justify-content: center;
}


.Resumed-subtotal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Resumed-descuento {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Resumed-subDesc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Resumed-impTrasl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Resumed-total {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.ResumedName {
    background: gray;
    padding: 8px;
    flex: 1;
    color: white;
    font-weight: bold;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    text-align: center;
}
p.Resumed-text {
    flex: 1;
    text-align: right;
    margin: 0;
}


.inFact-container {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 8px;
    align-items: center;
}

.cinta-Titulo-lefted {
    background: #151F56;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    
}

.cont-Titulo {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
}
.txt-cintaTitulo{
    margin: 0;
    text-align: center;
}



.resumFact-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 8px;
}

`
const LayoutAlterProductos = styled.section`
    height: 100%;

    

    .Cuerpo-TablaSinPaginador:hover{
        background: #b4dbb0;
    }
    
    .cont-enlacepkt1 {
        width: 95%;
        margin: auto;
    }

    .AlterProducto {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .listadoProductos {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        background: white;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        box-shadow: rgb(149, 151, 167) 1px 3px 12px;
    }
    /* .InNomProd {
        width: 100%;
    } */

    .contsProds {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }
    
    .datosProds {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .list-clvsUnidad {
        position: absolute;
        max-height: 10rem;
        width: 100%;
        overflow-y: scroll;
        background: white;
        box-sizing: border-box;
        /* padding-left: 4px; */
    }

    .listed-clve {
        padding: 4px 0px 4px 4px;
    }
    
    .listed-clve:hover {
        background: #b4dbb0;
    }

    .inptsDatosProds {
        width: 80%;
        margin: 0 auto;
        background: white;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        box-shadow: rgb(149, 151, 167) 1px 3px 12px;
    }

    .botonAgregarProducto {
        width: 50%;
        margin: 0px auto;
    }

`
const LayoutAddAbono = styled.section`




.AddAbono {
    overflow-y: scroll;
    background: white;
    border-radius: 6px;
    height: 98%;
    box-sizing: border-box;
    padding: 0;
}
.InAbono-Container {
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-sizing: border-box;
}

    .Abono-Info {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1.5fr 0.2fr 1.5fr 0.2fr 1.5fr 0.2fr 1.5fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
        "In-BuscarClte In-BuscarClte In-BuscarClte . In-FchPago"; 
        padding: 1rem 0;
    }
.In-BuscarClte{
    grid-area: In-BuscarClte;
    position: relative;
}
.In-FchPago{
    grid-area: In-FchPago;
}

.In-FchPago {
    grid-area: In-FchPago;
    display: flex;
    justify-content: flex-end;
}

.Abono-DatosPago {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 1.5fr 0.2fr 1.5fr 0.2fr 1.5fr 0.2fr 1.5fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "In-MetedoPago . In-ImportePago . In-Banco . In-Referencia"; 
    padding: 1rem 0;
}

.In-MetedoPago { grid-area: In-MetedoPago; }
.In-ImportePago { grid-area: In-ImportePago; }
.In-Banco { grid-area: In-Banco; }
.In-Referencia { grid-area: In-Referencia; }



.Abono-SelectionFacts {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0;
}
.Abono-Txt{
    margin: 0;
    font-weight: bold;
    color: #333;
}


.TablaFacts-Container {
    padding: 10px;
    border-radius: 8px;
    box-sizing: border-box;
}
/* .TablaFacts-Head {
    display: flex;
    margin: auto;
    background: #5B5F83;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;
    box-sizing: border-box;
} */

.TablaFacts-Head {
    background: #5B5F83;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;

    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'TablaElmnt-CFDI TablaElmnt-FechaFactura TablaElmnt-ImporteFactura TablaElmnt-SaldoFactura TablaElmnt-SaldoAbonar TablaElmnt-Accion';
}
.TablaFacts-Body{
    padding: 0 10px;
}

.TablaElmnt-Body {
    border: 1px solid gray;
    box-sizing: border-box;
    padding: 010px;
    margin: 10px 0;
    border-radius: 8px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'TablaElmnt-CFDI TablaElmnt-FechaFactura TablaElmnt-ImporteFactura TablaElmnt-SaldoFactura TablaElmnt-SaldoAbonar TablaElmnt-Accion';
}
.In-saldo{
    display: flex;
}


.TablaElmnt-CFDI{
    display:flex;
    align-items:center;
    justify-content: center;

    grid-area: TablaElmnt-CFDI;
}
.TablaElmnt-FechaFactura{
    display:flex;
    align-items:center;
    justify-content: center;

    grid-area: TablaElmnt-FechaFactura;
}
.TablaElmnt-ImporteFactura{
    display:flex;
    align-items:center;
    justify-content: center;

    grid-area: TablaElmnt-ImporteFactura;
}
.TablaElmnt-SaldoFactura{
    display:flex;
    align-items:center;
    justify-content: center;

    grid-area: TablaElmnt-SaldoFactura;
}
.TablaElmnt-SaldoAbonar{
    display:flex;
    align-items:center;
    justify-content: center;

    grid-area: TablaElmnt-SaldoAbonar;
}
.TablaElmnt-Accion{
    display:flex;
    align-items:center;
    justify-content: center;

    grid-area: TablaElmnt-Accion;
}
.Tabla-Txt{
    margin: 0;
}
.Resum-Txt{
    margin: 0;
    flex: 6;
    font-weight: bold;
}

.Resumen {
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: flex-end;
}
.Resum-Container {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: space-between;
    align-self: flex-end;
    background: rgb(149, 151, 167);
    padding: 6px;
    border-radius: 6px;
    width: 25%;
    margin: 1%;
    box-sizing: border-box;
}
.Resum-Info {
    background: rgba(255, 255, 255, 0.87);
    border-radius: 4px;
    padding: 6px;
    color: rgb(53, 53, 53);
    font-weight: bold;
    flex: 3;
    text-align: right;
}

.Bt-GenerarAbono{
    display: flex;
    justify-content: center;
    padding: 10px;
}
.list-Clientes {
    position: absolute;
    max-height: 10rem;
    width: 100%;
    overflow-y: scroll;
    background: white;
    box-sizing: border-box;
}
.listed-client {
    border: 1px solid gray;
    border-top: none;
    padding: 4px 0px 4px 4px;
}
.listed-client:hover {
    background: #b4dbb0;
}
.In-Saldo {
    width: 50%;
}

.In-ImportePago {
    display: flex;
    justify-content: flex-end;
}










`

const LayoutReporteador = styled.section`
    height: 100%;
    display: flex;
        
.Contenedor-Reportes {
    overflow-y: scroll;
    max-height: 98%;
    width: 85%;
    margin: auto;
    background: white;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgb(149, 151, 167) 1px 3px 12px;
}
.Titluo-Reportes {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Titulo-Cinta {
    background: #151F56;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
}
.Titulo-Texto{
    margin: 0;
}
.Filtros-Reportes {
    margin: 2rem auto;
}
.Filtros-Default {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Tipo-Reporte {
    display: flex;
    flex: 1;
}   
.Fechas-Reporte {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.Separador-Linea {
    width: 98%;
    border-bottom: 3px solid #9597a7;
    margin: 2rem auto;
}
.Generar-Reportes {
    margin-top: 3rem;
    margin-bottom: 1rem;
}
.Boton-Generar {
    display: flex;
    justify-content: center;
    align-items: center;
}


`

export {
    LayoutBtn, LayoutInpt, LayoutContainer, LayoutLogin, LayoutPreloader, LayoutPaginacion, TablaLayout, ModalLayout,
    LayoutAlterCliente, LayoutAddFactura, LayoutAlterProductos, LayoutReporteador, LayoutAddAbono
}

