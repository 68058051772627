import React, { useEffect, useState } from 'react'
import AnLoader from '../../utils/components/Preloader/AnLoader'
import { LayoutProductos } from '../../themes/layout'
import { useProductos } from './hook/useProductos'
import { useAuth } from '../../context/AuthContext'
import TablaPaginador from '../../utils/components/TablaPaginador/TablaPaginador'
import Btn from '../../utils/components/boton/Btn'
import Inpt from '../../utils/components/input/Inpt'

import Cruz from '../../assets/SVGs/Cruz.svg'
import Editar from '../../assets/SVGs/Editar.svg'
import HabilProd from '../../assets/SVGs/plusCircle.svg'
import DesHabilProd from '../../assets/SVGs/minusCircle.svg'
import lupa from '../../assets/SVGs/Lupa.svg'
import Paginacion from '../../utils/components/Paginacion/Paginacion'
import ModalComponent from '../../utils/components/Modal/Modal'
import useModal from '../../utils/components/Modal/hook/useModal'

const Productos = () => {

    const {Autorized} = useAuth()
    const {
        Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setRegsPg, ChangeRegs, Productos_ProPrevPg, Productos_PrevPg, Productos_NextPg, Productos_ProNextPg, ProductoStatusData,
        BuscarProd, setBuscarProd, Filtro, setFiltro, NavAlter, ObtenerProductos, Modal_ProductoStatus, OpenModal_ProductoStatus, CloseModal_ProductoStatus, AbrirModalProdStatus,
        HabilitarProducto, DeshabilitarProducto, EditarProducto
    } = useProductos()


    const [Activedots, setActivedots] = useState()
    const ActivarDots = (dato) =>{
        if (dato === Activedots) {
          setActivedots()
        }else{  
          setActivedots(dato)
        }
      }

    const RenderItemCabezeraTablaPaginador = () => {
      return (
        <>
          <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
              <p>ID</p>
          </div>
          <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
              <p>Clave SAT</p>
          </div>
          <div className='ItemHeader-TablaPaginador' style={{ flex: 10 }}>
              <p>Descripción</p>
          </div>
          <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
              <p>Estatus</p>
          </div>
          <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
          </div>
        </>
      )
    }
    const RenderItemCuerpoTablaPaginador = () => {
      return (
        <div style={{ background: '#fff' }}>
          <>
            {
              InfoTab?.map((res, indx) => {
                return (
                  <>
                    <div className='Cuerpo-TablaSinPaginador' style={{position:'relative'}}>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                          <p className='Texto-Tabla'>{res.id}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 4, textAlign:'center' }}>
                          <p className='Texto-Tabla'>{res.claveProducto}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 10 }}>
                          <p className='Texto-Tabla'>{res.descripcion}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2, textAlign:'center' }}>
                          <p className='Texto-Tabla'>{res.estatus ? 'Activo' : 'Inactivo'}</p>
                      </div>
  
                      <div className='ItemCuerpo-AccionesPaginador' style={Autorized('Productos', 'Paginador') ? {flex:'1'} : { flex:'1', opacity:'0'}} onClick={()=>ActivarDots(res.id)}>
  
                          <div className={Activedots === res.id ? 'cont-dots-active' : 'cont-dots'} style={Autorized('Productos', 'Paginador')  ? {} : {cursor:'default', display:'none'}}>
                            <span className='dotAction' style={{ background: '#9597A7' }}></span>
                            <span className='dotAction' style={{ background: '#9597A7' }}></span>
                            <span className='dotAction' style={{ background: '#9597A7' }}></span>
                          </div>
                          
                          <ul className={ (( InfoTab.length < 8 ) || ((InfoTab.length > 8) && (indx < InfoTab.length - 2))) ? 'dots-menu' : 'dots-menu-inverted'} style={Activedots === res.id ? {} : {display:'none'}}>
  
                              <li className='dm-row' style={Autorized('Productos', 'Actualizar') ? {} : {display:'none'}} onClick={(e)=>NavAlter('Editar', res)}> {/*  */} 
                                  <div className='cont-rowIcon'> <img className='row-icon' src={Editar} alt="" /> </div>  <p className='row-txt' >Editar</p> 
                              </li>
                              
                              <li className='dm-row' style={Autorized(res.estatus ? ('Productos', 'Deshabilitar') : ('Productos', 'Habilitar') ) ? {display:'none'} : {}} onClick={(e)=>AbrirModalProdStatus(res)}> 
                                  <div className='cont-rowIcon'> <img className='row-icon' src={res.estatus ? DesHabilProd : HabilProd} alt="" /> </div>  <p className='row-txt' >{res.estatus ? 'Deshabilitar' : 'Habilitar'}</p>  
                              </li>

                          </ul>
  
                      </div>
  
                    </div>
                  </>
                )
              })
            }
          </>
        </div>
  
      )
    }
      
    const RenderItemModalCambiarEstatus = (info) =>{
      return(
        
        <div className='CambStatus-container'>
          <div className='CambStatus-infos'>
            
            <div className='CambStatus-Clteinfo'>
              <p className='txt-CambStatus'>ID:</p>
              <p className='txt-CambStatus'>{info?.id}</p>
            </div>
            
            <div className='CambStatus-Clteinfo'>
              <p className='txt-CambStatus'>Descripción:</p>
              <p className='txt-CambStatus'>{info?.descripcion}</p>
            </div>
            
            <div className='CambStatus-Clteinfo'>
              <p className='txt-CambStatus'>Clave de producto SAT:</p>
              <p className='txt-CambStatus'>{info?.claveProducto}</p>
            </div>
            
            <div className='CambStatus-Clteinfo'>
              <p className='txt-CambStatus'>Clave de unidad SAT:</p>
              <p className='txt-CambStatus'>{info?.claveUnidad}</p>
            </div>
    
          </div>
    
          
          <div className='btn-CambiarEstatus'>
            
            <Btn
              type={'accept'}
              descript={'Aceptar'} 
              clicked={info?.estatus ?  ()=> DeshabilitarProducto(info) : ()=> HabilitarProducto(info) }
            />
          </div>
        </div>
        
      )
    } 
    const DotsMenu = (indx) =>{
      if (InfoTab.length > 7) {
        if (indx > InfoTab.length - 4) {
          return('dots-menu-inverted')
        }else{
          return('dots-menu')
        }
      }
    }



useEffect(() => {
  ObtenerProductos()
}, [])

    
return (
  Autorized('Productos', 'Paginador')
  ?
    Loading 
    ?
      <AnLoader tittle = 'Cargando clientes'></AnLoader>
    :
      <div className='Seccion-Area'>
          <div className='herramientas'>
              <div className='filtros'>
                <div className='buscaProd'>
                  <Inpt
                      type={'text'}
                      tittle={'Buscar producto'}
                      haveBtn={true}
                      value={BuscarProd}
                      setValue={setBuscarProd}
                      
                      colorBtn={'#3A9338'}
                      imagen={lupa}
                      metodo={ObtenerProductos}
                  />
                </div>

                  
                <div className='FiltActivs'>
                  <Inpt
                    type={'select'}
                    opciones={[
                      { "key": 0, "description" : 'Todos'},
                      { "key": 1, "description" : 'Activos'},
                      { "key": 2, "description" : 'Inactivos'},
                    ]}
                    tittle={'Tipo Factura'}
                    value={Filtro}
                    setValue={setFiltro}
                  />
                </div>

              </div>
              
              <div className='agregar' style={Autorized('Facturas', 'Agregar') ? {} : {display:'none'}}>
                  <Btn
                      type={'add'}
                      imagen={Cruz}
                      clicked={NavAlter}
                  />
              </div>
          </div>
          <div className='tabla'>
          {
              Autorized('Productos', 'Paginador')
              ?

              <TablaPaginador
                  Cabezera={RenderItemCabezeraTablaPaginador}
                  Cuerpo={RenderItemCuerpoTablaPaginador}
              />
              
              :
              <p>No autorizado</p>
          }
          </div>
          <div className='paginacion'>


            <Paginacion
              RegsPg={RegsPg}
              setRegsPg={ChangeRegs}

              Pg={Pg}
              MaxPg={MaxPg}
              NextPg={Productos_NextPg}
              PrevPg={Productos_PrevPg}
              ProNextPg={Productos_ProNextPg}
              ProPrevPg={Productos_ProPrevPg}
            />

          </div>

        <ModalComponent
            StatusModal={Modal_ProductoStatus}
            CerrarModal={CloseModal_ProductoStatus}
            Titulo={`${ProductoStatusData?.estatus === true ? 'Deshabilitar producto' : 'Rehablitar producto'}`}
            Contenido={RenderItemModalCambiarEstatus(ProductoStatusData)}
            ancho={'40%'}
            alto={'50%'}

          />
      </div>
  :
    <p>No autorizado</p>
  )
}

export default Productos