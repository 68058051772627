import React, { useEffect, useState } from 'react'
import { useClientes } from './hook/useClientes'
import { useAuth } from '../../context/AuthContext'
import { useSidebar } from '../Sidebar/hook/useSidebar'
import AnLoader from '../../utils/components/Preloader/AnLoader'
import Paginacion from '../../utils/components/Paginacion/Paginacion'
import Inpt from '../../utils/components/input/Inpt'
import lupa from '../../assets/SVGs/Lupa.svg'
import TablaPaginador from '../../utils/components/TablaPaginador/TablaPaginador'
import Cruz from '../../assets/SVGs/Cruz.svg'
import Editar from '../../assets/SVGs/Editar.svg'
import HabilClte from '../../assets/SVGs/HabilitClte.svg'
import DesHabilClte from '../../assets/SVGs/DeshabilitClte.svg'

import Btn from '../../utils/components/boton/Btn'
import ModalComponent from '../../utils/components/Modal/Modal'

const Clientes = () => {
  

  const {Autorized} = useAuth()
  const {MoveCategory} = useSidebar()
  const {
    Modal_ClienteStatus, OpenModal_ClienteStatus, CloseModal_ClienteStatus,
    Pg, MaxPg, RegsPg, InfoTab, TextoSearch, Loading, Cliente_NextPg,  Cliente_PrevPg,  Cliente_ProNextPg,  Cliente_ProPrevPg, setRegsPg, ChangeRegs, GetPages, setTextoSearch,
    ObtenerClientes, FltrActiveClte, setFltrActiveClte, FltrTypeClte, setFltrTypeClte,  TipoCliente, setTipoCliente, CP, setCP,  Nombre, setNombre, RFC, setRFC, Correo, setCorreo, RegimFisc, setRegimFisc, CFDI, setCFDI, IDpkt1, setIDpkt1, Namepkt1, setNamepkt1,
    AgregarCliente, ObtenerRegimens, ObtenerCFDIs, NavAlter, ClteStatusData, AbrirModalCambioStatus, HabilitarClte, DesHabilitarClte, Activedots, setActivedots, ActivarDots
  } = useClientes()

  const DotsMenu = (indx) =>{
    if (InfoTab.length > 7) {
      if (indx > InfoTab.length - 4) {
        return('dots-menu')
      }else{
        return('dots-menu')
      }
    }
  }



  useEffect(() => {
      ObtenerClientes(0)
      MoveCategory('Clientes')
      ObtenerRegimens()
      ObtenerCFDIs()

    }, [])

  const RenderItemCabezeraTablaPaginador = () => {
    return (
      <>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p>ID</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Tipo Cliente</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 8}}>
            <p>Nombre</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>RFC</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Régimen</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>CP</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 5 }}>
            <p>Correo</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Estatus</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p></p>
        </div>
      </>
    )
  }
  const RenderItemCuerpoTablaPaginador = () => {
    return (
      <div style={{ background: '#fff' }}>
        <>
          {
            InfoTab?.map((res, indx) => {
              return (
                <>
                  <div className='Cuerpo-TablaSinPaginador' style={{position:'relative'}}>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                        <p className='Texto-Tabla'>{res.id}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                        <p className='Texto-Tabla'>{res.tipocliente === 'E' ? 'Externo' : 'Interno'}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 8, textAlign:'left' }}>
                        <p className='Texto-Tabla'>{res.nombre}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                        <p className='Texto-Tabla'>{res.rfc}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                        <p className='Texto-Tabla'>{res.regimenfiscal}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                        <p className='Texto-Tabla'>{res.cp}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 5 }}>
                        <p className='Texto-Tabla'>{res.correo}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                        <p className='Texto-Tabla'>{res.estatus ? 'Activo' : 'Inactivo'}</p>
                    </div>

                    <div className='ItemCuerpo-AccionesPaginador' style={Autorized('Clientes', 'Habilitar') || Autorized('Clientes', 'Deshabilitar') || Autorized('Clientes', 'Actualizar') ? {flex:'1'} : { flex:'1', opacity:'0'}} onClick={()=>ActivarDots(res.id)}>

                        <div className={Activedots === res.id ? 'cont-dots-active' : 'cont-dots'} style={Autorized('Clientes', 'Habilitar') || Autorized('Clientes', 'Deshabilitar') || Autorized('Clientes', 'Actualizar') ? {} : {cursor:'default'}}>
                          <span className='dotAction' style={{ background: '#9597A7' }}></span>
                          <span className='dotAction' style={{ background: '#9597A7' }}></span>
                          <span className='dotAction' style={{ background: '#9597A7' }}></span>
                        </div>
                        
                        <ul className={(( InfoTab.length < 8 ) || ((InfoTab.length > 8) && (indx < InfoTab.length - 2))) ? 'dots-menu' : 'dots-menu-inverted'} style={Activedots === res.id ? {} : {display:'none'}}>

                          <li className='dm-row' style={Autorized('Clientes', 'Actualizar') ? {} : {display:'none'}} onClick={(e)=>NavAlter('Editar', res)}> 
                             <div className='cont-rowIcon'> <img className='row-icon' src={Editar} alt="" /> </div>  <p className='row-txt' >Editar</p> 
                          </li>
                          
                          <li className='dm-row' style={Autorized(res.estatus ? ('Clientes', 'Deshabilitar') : ('Clientes', 'Habilitar') ) ? {display:'none'} : {}} onClick={(e)=>AbrirModalCambioStatus(res)}>
                              <div className='cont-rowIcon'> <img className='row-icon' src={res.estatus ? HabilClte : DesHabilClte} alt="" /> </div>  <p className='row-txt' >{res.estatus ? 'Deshabilitar' : 'Habilitar'}</p>  
                          </li>

                        </ul>

                    </div>

                  </div>
                </>
              )
            })
          }
        </>
      </div>

    )
  }

  const RenderItemModalCambiarEstatus = () =>{
  return(
    
    <div className='CambStatus-container'>
      <div className='CambStatus-infos'>
        
        <div className='CambStatus-Clteinfo'>
          <p className='txt-CambStatus'>ID:</p>
          <p className='txt-CambStatus'>{ClteStatusData?.id}</p>
        </div>
        
        <div className='CambStatus-Clteinfo'>
          <p className='txt-CambStatus'>Nombre:</p>
          <p className='txt-CambStatus'>{ClteStatusData?.nombre}</p>
        </div>

      </div>

      
      <div className='btn-CambiarEstatus'>
        
        <Btn
          type={'accept'}
          descript={'Aceptar'} 
          clicked={ClteStatusData?.estatus ? DesHabilitarClte  : HabilitarClte }
        />
      </div>
    </div>
    
  )
 }  

  return (
    Autorized('Clientes', 'Paginador')
    ?
      Loading 
      ?
        <AnLoader tittle = 'Cargando clientes'></AnLoader>
      :   
        <div className='Seccion-Area'>
            <div className='herramientas'>
              <div className='filtros'>

                <div className='buscar'>
                  <Inpt
                    type={'text'}
                    tittle={'Buscar'}
                    placehold={'Nombre o RFC'}
                    value={TextoSearch}
                    setValue={setTextoSearch}
                    haveBtn={true}
                    colorBtn={'#3A9338'}
                    metodo={ObtenerClientes}
                    imagen={lupa}
                    forceLayoutSty={{marginRight:'20px'}}
                  ></Inpt>
                </div>

                <div className='tipoCliente'>
                  <Inpt
                    type={'select'}
                    opciones={[
                      { "key": 0, "description" : 'Todos'},
                      { "key": 1, "description" : 'Internos'},
                      { "key": 2, "description" : 'Externos'},
                    ]}
                    tittle={'Tipo Cliente'}
                    value={FltrTypeClte}
                    setValue={setFltrTypeClte}
                    forceLayoutSty={{marginRight:'20px'}}

                  ></Inpt>
                </div>

                <div className='estatus'>
                  <Inpt
                    type={'select'}
                    opciones={[
                      { "key": 0, "description" : 'Todos'},
                      { "key": 1, "description" : 'Activos'},
                      { "key": 2, "description" : 'Inactivos'},
                    ]}
                    tittle={'Estatus'}
                    value={FltrActiveClte}
                    setValue={setFltrActiveClte}

                  />
                </div>
                
              </div>
              
              <div className='agregar' style={Autorized('Clientes', 'Agregar') ? {} : {display:'none'}}>
                  <Btn
                    type={'add'}
                    imagen={Cruz}
                    clicked={NavAlter}
                  />
              </div>
            </div>
            <div className='tabla'>
              {
                Autorized('Clientes', 'Paginador')
                ?

                  <TablaPaginador
                    Cabezera={RenderItemCabezeraTablaPaginador}
                    Cuerpo={RenderItemCuerpoTablaPaginador}
                  />
                :
                  <p>No autorizado</p>
              }
            </div>
            <div className='paginacion'>

              <Paginacion
                RegsPg={RegsPg}
                setRegsPg={ChangeRegs}

                Pg={Pg}
                MaxPg={MaxPg}
                NextPg={Cliente_NextPg}
                PrevPg={Cliente_PrevPg}
                ProNextPg={Cliente_ProNextPg}
                ProPrevPg={Cliente_ProPrevPg}
              />
            </div>
            
            <ModalComponent
                StatusModal={Modal_ClienteStatus}
                CerrarModal={CloseModal_ClienteStatus}
                Titulo={`${ClteStatusData?.estatus === true ? 'Deshabilitar cliente' : 'Rehablitar cliente'}`}
                Contenido={RenderItemModalCambiarEstatus()}
                ancho={'35%'}
                alto={'35%'}

              />

        </div>
    :
      <p>No autorizado</p>
  )
}

export default Clientes

// https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Clientes/Paginador?TipoCliente=0&Filtro=0&Buscar=&Pagina=0&Registros=10
// https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Clientes/Paginador?TipoCliente=0&Filtro=0&Buscar=&Pagina=1&Registros=10
// https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Clientes/Paginador?Pagina=1&Registros=10