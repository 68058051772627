import React from 'react'
import Logo from '../../assets/SVGs/PKT1 SVG white.svg'
import LogoLetra from '../../assets/SVGs/K1 SVG white.svg'
import LogoBack from '../../assets/SVGs/LogoBack.svg'

const HeadSide = () => {
  return (
    <div className='HeadSide'>
      <div className='LogoBackground'>      
        <img className='img-Logoback' src={LogoBack} alt="" />
        <div className='Logopkt1'>
          <img className='img-LogoPKT1' src={Logo} alt="" />
        </div>
      </div>
    </div>
  )
}

export default HeadSide