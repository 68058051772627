import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext'
import Btn from '../utils/components/boton/Btn'
import Inpt from '../utils/components/input/Inpt';
import { LayoutListado, TablaLayout } from '../themes/layout';
import Paginacion from '../utils/components/Paginacion/Paginacion';
import { usePaginacion } from '../utils/components/Paginacion/hook/usePaginacion';
import { useSidebar } from '../components/Sidebar/hook/useSidebar';


const Dashboard = () => {
  
    const {MoveCategory} = useSidebar()
    
    const {UserData, User} = useAuth();

    const columnasTabla = [
        {
            size: 1,
            ident: 'ID',
            value: row => <div>{row.id}</div>
        },
        {
            size: 2,
            ident: 'Tipo Cliente',
            value: row => <div>{row.tipocliente === 'E' ? 'Externo' : 'Interno'}</div>
        },
        {
            size: 5,
            ident: 'Nombre',
            value: row => <div>{row.nombre}</div>
        },
        {
            size: 3,
            ident: 'RFC',
            value: row => <div>{row.rfc}</div>
        },
        {
            size: 2,
            ident: 'Régimen',
            value: row => <div>{row.regimenfiscal}</div>
        },
        {
            size: 2,
            ident: 'CP',
            value: row => <div>{row.cp}</div>
        },
        {
            size: 3,
            ident: 'Correo',
            value: row => <div>{row.correo}</div>
        },
        {
            size: 2,
            ident: 'Estatus',
            value: row => <div>{row.estatus ? 'Activo' : 'Inactivo'}</div>
        }
    ];
    const datosTabla = [
        {
            "id": 49,
            "idclientepkt1": null,
            "nombreclientepkt1": null,
            "tipocliente": "E",
            "nombre": "Pito Perez PerazAA",
            "correo": "asdasd23@asdas.com",
            "rfc": "PITZ010214AS3",
            "regimenfiscal": "616",
            "usocfdi": "G03",
            "cp": "81234",
            "estatus": false
        },
        {
            "id": 48,
            "idclientepkt1": null,
            "nombreclientepkt1": null,
            "tipocliente": "E",
            "nombre": "Pito Perez Peraz",
            "correo": "asdasd2@asdas.com",
            "rfc": "PITP010214AS3",
            "regimenfiscal": "616",
            "usocfdi": "G03",
            "cp": "81234",
            "estatus": false
        },
        {
            "id": 47,
            "idclientepkt1": null,
            "nombreclientepkt1": null,
            "tipocliente": "E",
            "nombre": "Pito Perez",
            "correo": "asdasd1@asdas.com",
            "rfc": "PITX010214AS3",
            "regimenfiscal": "616",
            "usocfdi": "G03",
            "cp": "81234",
            "estatus": false
        },
        {
            "id": 46,
            "idclientepkt1": 136343,
            "nombreclientepkt1": null,
            "tipocliente": "E",
            "nombre": "JUANITO ALCACHOFA",
            "correo": "chofa72@gmail.com",
            "rfc": "JUANCHOFA0072",
            "regimenfiscal": "616",
            "usocfdi": "G03",
            "cp": "81200",
            "estatus": false
        },
        {
            "id": 45,
            "idclientepkt1": 21301,
            "nombreclientepkt1": null,
            "tipocliente": "E",
            "nombre": "TESSTRANGE",
            "correo": "asd@as.com",
            "rfc": "ADADASDADASDA",
            "regimenfiscal": "628",
            "usocfdi": "G03",
            "cp": "12312",
            "estatus": true
        },

    ];


    useEffect(() => {
        
        MoveCategory('inicio')
        
    }, [])
    
  return (
    <div className='Atajos-Cont'>

        <div className='Welcome-User'>
            <h1 className='WelcomeText'>BIENVENIDO:</h1>
            <p className='WelcomeText'>{UserData?.descripcion}</p>

        </div>


        {/* <div className='Atajos-flex'>

            <div className='Atajo-flex-Elmnt'>
                <p className='AtajoText'>Generar factura</p>
            </div>
            
            <div className='Atajo-flex-Elmnt'>
                <p className='AtajoText'>Generar Abono</p>
            </div>

            <div className='Atajo-flex-Elmnt'>
                <p className='AtajoText'>Agrear producto</p>
            </div>

            <div className='Atajo-flex-Elmnt'>
                <p className='AtajoText'>Agrear cliente</p>
            </div>

        </div> */}

        {/* <div className='Atajos'>

            <div className='Atajo-Elmnt'>
                <p className='WelcomeText'>Generar factura</p>
            </div>
            
            <div className='Atajo-Elmnt'>
                <p className='WelcomeText'>Agrear producto</p>
            </div>

            <div className='Atajo-Elmnt'>
                <p className='WelcomeText'>Generar Abono</p>
            </div>


        </div> */}
        
    </div>
  )
}

export default Dashboard