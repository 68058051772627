import React, { useState } from 'react'
import iconFact from '../../assets/SVGs/Facturacion.svg'
import iconOper from '../../assets/SVGs/Operaciones.svg'
import iconInicio from '../../assets/SVGs/Inicio.svg'
import { useSidebar } from './hook/useSidebar'
import SelectorCategoria from '../../assets/SVGs/SelectorCategoria.svg'
import Abonos from '../../assets/SVGs/StackCoins.svg'
import Catalogo from '../../assets/SVGs/catalogar.svg'
import Reports from '../../assets/SVGs/analisis.svg'
import Test from '../../assets/SVGs/TestIcon.svg'
import Arrow from '../../assets/SVGs/Arrow.svg'


const Sidebar = () => {
  const {ActualCategory, MoveCategory, Autorized} = useSidebar()

  
  

  return (
    <div className='App-Sidebar'>

      <div className='Sidemenu'>
        
        <div className='SideElement' onClick={() => MoveCategory('inicio')} style={{}}>
          <div className='Sidemenu-Bluebar' style={ActualCategory === 'inicio' ? {} : {background:'none'}}></div>
          <img className='icon-sidebar' src={iconInicio} alt="" />
          <p className='txt-SideElement'>Inicio</p>
          <img className='IndicadorCategoria' src={SelectorCategoria} style={ActualCategory === 'inicio' ? {} : {opacity:'0'}} alt="" />
          
        </div>
{/* Autorized('Clientes-Paginador') */}
        <div className='SideElement' onClick={() => MoveCategory('Clientes')} style={Autorized('Clientes', 'Paginador') ? {} : {display:'none'}}>
          <div className='Sidemenu-Bluebar' style={ActualCategory === 'Clientes' ? {} : {background:'none'}}></div>
          <img className='icon-sidebar' src={iconOper} alt="" />
          <p className='txt-SideElement'>Clientes</p>
          
          <img className='IndicadorCategoria' src={SelectorCategoria} style={ActualCategory === 'Clientes' ? {} : {opacity:'0'}} alt="" />
          
        </div>
        
        <div className='SideElement' onClick={() => MoveCategory('Facturas')} style={Autorized('Facturas', 'Paginador') ? {} : {display:'none'}}>
          <div className='Sidemenu-Bluebar' style={ActualCategory === 'Facturas' ? {} : {background:'none'}}></div>
          <img className='icon-sidebar' src={iconFact} alt="" />
          <p className='txt-SideElement'>Facturación</p>
          
          <img className='IndicadorCategoria' src={SelectorCategoria} style={ActualCategory === 'Facturas' ? {} : {opacity:'0'}} alt="" />
        </div>

        
        <div className='SideElement' onClick={() => MoveCategory('Productos')} style={Autorized('Productos', 'Paginador') ? {} : {display:'none'}}>
          <div className='Sidemenu-Bluebar' style={ActualCategory === 'Productos' ? {} : {background:'none'}}></div>
          <img className='icon-sidebar' src={Catalogo} alt="" />
          <p className='txt-SideElement'>Productos SAT</p>
          
          <img className='IndicadorCategoria' src={SelectorCategoria} style={ActualCategory === 'Productos' ? {} : {opacity:'0'}} alt="" />
        </div>
        
        <div className='SideElement' onClick={() => MoveCategory('Abonos')} style={Autorized('Abonos', 'Paginador') ? {} : {display:'none'}}>
          <div className='Sidemenu-Bluebar' style={ActualCategory === 'Abonos' ? {} : {background:'none'}}></div>
          <img className='icon-sidebar' src={Abonos} alt="" />
          <p className='txt-SideElement'>Abonos</p>
          
          <img className='IndicadorCategoria' src={SelectorCategoria} style={ActualCategory === 'Abonos' ? {} : {opacity:'0'}} alt="" />
        </div>
        
        <div className='SideElement' onClick={() => MoveCategory('Reportes')} style={Autorized('Reportes', 'Paginador') ? {} : {display:'none'}}>
          <div className='Sidemenu-Bluebar' style={ActualCategory === 'Reportes' ? {} : {background:'none'}}></div>
          <img className='icon-sidebar' src={Reports} alt="" />
          <p className='txt-SideElement'>Reportes</p>
          
          <img className='IndicadorCategoria' src={SelectorCategoria} style={ActualCategory === 'Reportes' ? {} : {opacity:'0'}} alt="" />
        </div>
        
        

      </div>

      {/* <div className='collapse-SideMenu'>
        <div className='collapser'>  
          <img className='img-collapser' src={Arrow} alt="" />
          <img className='img-collapser' src={Arrow} alt="" />
        </div>
      </div> */}
    </div>
  )
}

export default Sidebar