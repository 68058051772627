import React, { useState } from 'react'

const useModal = () => {
    const [Modal_ClientesEnlazar, setModal_ClienteAgregar] = useState(false)
    const [Modal_ClienteStatus, setModal_ClienteStatus] = useState(false)
    const [Modal_ProductoStatus, setModal_ProductoStatus] = useState(false)
    const [Modal_FacturasReCorreo, setModal_FacturasReCorreo] = useState(false)
    const [Modal_FacturasVerPDF, setModal_FacturasVerPDF] = useState(false)
    const [Modal_FacturasCancelarFact, setModal_FacturasCancelarFact] = useState(false)
    const [Modal_AbonosPendingFacts, setModal_AbonosPendingFacts] = useState(false)

    
    const OpenModal_ClientesEnlazar = () =>{
        setModal_ClienteAgregar(true)
    }
    const CloseModal_ClientesEnlazar = () =>{
        setModal_ClienteAgregar(false)
    }
    const OpenModal_ClienteStatus = () =>{
        setModal_ClienteStatus(true)
    }
    const CloseModal_ClienteStatus = () =>{
        setModal_ClienteStatus(false)
    }

    
    const OpenModal_ProductoStatus = () =>{
        setModal_ProductoStatus(true)
    }
    const CloseModal_ProductoStatus = () =>{
        setModal_ProductoStatus(false)
    }

    const OpenModal_FacturasReCorreo = () =>{
        setModal_FacturasReCorreo(true)
    }
    const CloseModal_FacturasReCorreo = () =>{
        setModal_FacturasReCorreo(false)
    }

    const OpenModal_FacturasVerPDF = () =>{
        setModal_FacturasVerPDF(true)
    }
    const CloseModal_FacturasVerPDF = () =>{
        setModal_FacturasVerPDF(false)
    }

    
    const OpenModal_FacturasCancelarFact = () =>{
        setModal_FacturasCancelarFact(true)
    }
    const CloseModal_FacturasCancelarFact = () =>{
        setModal_FacturasCancelarFact(false)
    }

    
    const OpenModal_AbonosPendingFacts = () =>{
        setModal_AbonosPendingFacts(true)
    }
    const CloseModal_AbonosPendingFacts = () =>{
        setModal_AbonosPendingFacts(false)
    }




  return {
    //Modal_State               |       Open/Close
    Modal_ProductoStatus,               OpenModal_ProductoStatus, CloseModal_ProductoStatus,
    Modal_ClientesEnlazar,              OpenModal_ClientesEnlazar, CloseModal_ClientesEnlazar,
    Modal_ClienteStatus,                OpenModal_ClienteStatus, CloseModal_ClienteStatus,
    Modal_FacturasReCorreo,             OpenModal_FacturasReCorreo, CloseModal_FacturasReCorreo,
    Modal_FacturasVerPDF,               OpenModal_FacturasVerPDF, CloseModal_FacturasVerPDF,
    Modal_FacturasCancelarFact,         OpenModal_FacturasCancelarFact, CloseModal_FacturasCancelarFact,
    Modal_AbonosPendingFacts,           OpenModal_AbonosPendingFacts, CloseModal_AbonosPendingFacts
    //modal   |   Open/Close
  }
}

export default useModal