import React from 'react'
import { HashRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import { PrivateRoute } from './privateRoute'
import Login from '../pages/login'
import Clientes from '../components/Clientes/Clientes'
import Dashboard from '../pages/dashboard'
import Facturas from '../components/Facturas/Facturas'
import AgregarCliente from '../components/Clientes/AlterCliente'
import AddFact from '../components/Facturas/AddFact'
import Productos from '../components/Productos/Productos'
import Abonos from '../components/Abonos/Abonos'
import { AlterProducto } from '../components/Productos/AlterProducto'
import Reportes from '../components/Reportes/Reportes'
import AddAbono from '../components/Abonos/AddAbono'



const Navigation = () => {
  

  return (
    <Router>
        <Routes>
            <Route path='/login' element={<Login/>}/>
             
            <Route exact path='/' element={
              
              <PrivateRoute>
                <Dashboard></Dashboard>
              </PrivateRoute>
            }/>

            
            <Route path='/inicio' element={
              <PrivateRoute>
                <Dashboard></Dashboard>
              </PrivateRoute>
            }/>

            <Route path='/Clientes' element={
              <PrivateRoute>
                <Clientes></Clientes>
              </PrivateRoute>
            }/>
            
            <Route path='/Clientes/:alterclte' element={
              <PrivateRoute>
                <AgregarCliente></AgregarCliente>
              </PrivateRoute>
            }/>

            <Route path='/Facturas' element={
              <PrivateRoute>
                <Facturas></Facturas>
              </PrivateRoute>
            }/>
            
            <Route path='/Facturas/:addfact' element={
              <PrivateRoute>
                <AddFact></AddFact>
              </PrivateRoute>
            }/>

            <Route path='/Abonos' element={
              <PrivateRoute>
                  <Abonos></Abonos>
              </PrivateRoute>
            }/>
            
            <Route path='/Abonos/:addabono' element={
              <PrivateRoute>
                <AddAbono></AddAbono>
              </PrivateRoute>
            }/>

            <Route path='/Productos' element={
              <PrivateRoute>
                <Productos></Productos>
              </PrivateRoute>
            }/>
            
            <Route path='/Productos/:alterprod' element={
              <PrivateRoute>
                <AlterProducto></AlterProducto>
              </PrivateRoute>
            }/>

            <Route path='/Reportes' element={
              <PrivateRoute>
                <Reportes></Reportes>
              </PrivateRoute>
            }/>
             
        </Routes>
    </Router>
  )
}

export default Navigation