import React from 'react'
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import HeadSide from '../components/Header/HeadSide';

export const PrivateRoute = ({children}) => {
  const {User} = useAuth();
  
  if (!User) {
    return(
      <Navigate to='/login'/>
    )
  }
  return <>
    <div className='ContainerPrivRout'>
      
      <div className='ContainerHeader'>
        <Header></Header>
      </div>
      <div className='ContainerHeadSide'>
        <HeadSide></HeadSide>
      </div>
      
      <div className='ContainerSidebar'>
        <Sidebar></Sidebar>
      </div>
        
        <div className='ContainerAppBody'>{children}</div>
        <div className='ContainerFooter'>
          <Footer></Footer>
        </div>
        
    </div>   
  </>  

}
