import React, { useEffect, useState } from 'react'
import { LayoutAlterCliente } from '../../themes/layout'
import Btn from '../../utils/components/boton/Btn'
import { useClientes } from './hook/useClientes'
import Inpt from '../../utils/components/input/Inpt'
import { useLocation, useParams } from 'react-router-dom'
import ModalComponent from '../../utils/components/Modal/Modal'
import useModal from '../../utils/components/Modal/hook/useModal'
import lupa from '../../assets/SVGs/Lupa.svg'
import TablaPaginador from '../../utils/components/TablaPaginador/TablaPaginador'
import Swal from 'sweetalert2'
import axios from 'axios'
import Paginacion from '../../utils/components/Paginacion/Paginacion'
import AnLoader from '../../utils/components/Preloader/AnLoader'


const AgregarCliente = () => {
    
    const {alterclte} = useParams()
    const {state} = useLocation()

    
    const [original, setoriginal] = useState(state)
    
  const {Modal_ClientesEnlazar, OpenModal_ClientesEnlazar, CloseModal_ClientesEnlazar} = useModal()
  const [infotav, setinfotav] = useState()
    const { 
        Pg, MaxPg, RegsPg, InfoTab, TextoSearch, Loading, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg, ChangeRegs, GetPages, setTextoSearch, ObtenerClientes, EditClte, Changelistpkt1, ObtenerCltesPKT1,
        TipoCliente, setTipoCliente, CP, setCP,  Nombre, setNombre, RFC, setRFC, Correo, setCorreo, RegimFisc, setRegimFisc, CFDI, setCFDI,
        IDpkt1, setIDpkt1, Namepkt1, setNamepkt1, AddClte, NavBack,
        CltePKT1_NextPg, CltePKT1_PrevPg, CltePKT1_ProNextPg, CltePKT1_ProPrevPg, FlagAlterCliente, setFlagAlterCliente
    } = useClientes()

    const handleEditarClte = () =>{
        EditClte(original);
        
    }

    const handleEnlace = (id, nombre) =>{
        setIDpkt1(id)
        setNamepkt1(nombre)
        CloseModal_ClientesEnlazar()
    }

    const handleBuscarCltepkt1 = () =>{
        ObtenerCltesPKT1()
    }


    
    const RenderItemCabezeraListaPaginador = () => {
    return (
        <>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p>ID</p>
        </div>
        
        <div className='ItemHeader-TablaPaginador' style={{ flex: 6}}>
            <p>Nombre</p>
        </div>
        
        </>
    )
    }
    const RenderItemCuerpoListaPaginador = () => {

    useEffect(() => {
        

    ObtenerCltesPKT1(undefined, 1, 10)
    }, [])

    return (
        <div style={{ background: '#fff' }}>
        <>
            {
            InfoTab?.map(res => {
                return (
                <>
                    <div className='Cuerpo-TablaSinPaginador' style={{cursor:'pointer'}}>
                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                            <p className='Texto-Tabla'>{res.id}</p>
                        </div>
                        
                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 6, textAlign:'left' }} onClick={(e)=>handleEnlace(res.id, res.nombrecompleto)}>
                            <p className='Texto-Tabla'>{`${res.nombre} ${res.apellidop} ${res.apellidom}`}</p>
                        </div>
                    
                    </div>
                </>
                )
            })
            }
        </>
        </div>

    )
    }
    const RenderItemEnlacePKT1 = () =>{
        return(
            <div className='cont-enlacepkt1'>
                <div className='buscar'>
                    <Inpt
                        type={'text'}
                        tittle={'Buscar'}
                        placehold={'Nombre o RFC'}
                        value={TextoSearch}
                        setValue={setTextoSearch}
                        haveBtn={true}
                        colorBtn={'#3A9338'}
                        metodo={handleBuscarCltepkt1}
                        imagen={lupa}
                        // forceLayoutSty={{marginRight:'20px'}}
                    ></Inpt>
                </div>

                <div className='listado'>
                    
                    <TablaPaginador
                        Cabezera={RenderItemCabezeraListaPaginador}
                        Cuerpo={RenderItemCuerpoListaPaginador}
                    />

                    <Paginacion
                        RegsPg={RegsPg}
                        setRegsPg={Changelistpkt1}
                        // BaseUrl={BaseUrl}
                        // ChangeRegs={ChangeRegs}

                        Pg={Pg}
                        MaxPg={MaxPg}
                        NextPg={CltePKT1_NextPg}
                        PrevPg={CltePKT1_PrevPg}
                        ProNextPg={CltePKT1_ProNextPg}
                        ProPrevPg={CltePKT1_ProPrevPg}
                    />
                </div>
                
                    
            </div>
        )
    }
    useEffect(() => {
        if (state.id) {
            setTipoCliente(state.tipocliente)
            setNombre(state.nombre)
            setCP(state.cp)
            setRFC(state.rfc)
            setCorreo(state.correo)
            setRegimFisc(state.regimenfiscal)
            setCFDI(state.usocfdi)
            setIDpkt1(state.idclientepkt1)
            setNamepkt1(state.nombreclientepkt1)
        }
    }, [])
    
  return (
    
    <LayoutAlterCliente style={{width:'100%', height:'100%'}}>
        
        {
            FlagAlterCliente
            ?
            <AnLoader tittle = {alterclte === 'Editar' ? 'Guardando cliente' : 'Agregando cliente'}></AnLoader>
            :
        <div className='Alter-Client'>
            <div className='cont-alterClte'>
                <div className='cont-Alters'>
                    <div className='info-client' style={{flex:'1'}}>
                        
                        <div className='cont-Titulo'>
                            <div className='cinta-Titulo'>
                                <h2 className='txt-cintaTitulo' >{alterclte === 'Editar' ? 'Editar los datos del cliente' : 'Ingresar datos del cliente'}</h2>
                            </div>
                        </div>
                        <div className='cont-tipoClte-cp'>
                            
                            <div className='cont-infoClte' style={{flex:'2'}}>
                            
                            {/* <p className='txt-infoClte' style={{flex:'3'}}> Tipo cliente: </p> */}
                            <Inpt
                                type={'select'}
                                value={TipoCliente}
                                setValue={setTipoCliente}
                                tittle={'Tipo cliente:'}
                                defaultOpc={'Seleccionar'}
                                forceLayoutSty={{flex:'4'}}
                                opciones={[
                                { "key": 'I', "description" : 'Interno'},
                                { "key": 'E', "description" : 'Externo'},
                                ]}

                            />
                            </div>

                            <div className='cont-infoClte' style={{flex:'1', marginLeft: '15px'}}>
                            
                            {/* <p className='txt-infoClte' style={{flex:'1', textAlign:'center'}}> CP: </p> */}
                            <Inpt
                                type={'text'}
                                value={CP}
                                setValue={setCP}
                                tittle={'CP'}
                                forceLayoutSty={{flex:'2'}}
                                maxLength={5}

                            />
                            </div>
                        </div>
                        <div className='cont-infoClte'>
                            {/* <p className='txt-infoClte' style={{flex:'2'}}> Nombre: </p> */}
                            <Inpt
                                type={'text'}
                                value={Nombre}
                                setValue={setNombre}
                                tittle={'Nombre:'}
                                maxLength={50}
                                forceLayoutSty={{flex:'5'}}

                            />
                        </div>
                        <div className='cont-infoClte'>

                            {/* <p className='txt-infoClte' style={{flex:'2'}}> RFC: </p> */}
                            <Inpt
                                type={'text'}
                                value={RFC}
                                setValue={setRFC}
                                tittle={'RFC:'}
                                maxLength={13}
                                forceLayoutSty={{flex:'5'}}

                            />
                        </div>
                        <div className='cont-infoClte'>

                            {/* <p className='txt-infoClte' style={{flex:'2'}}> Correo: </p> */}
                            <Inpt
                                type={'text'}
                                value={Correo}
                                setValue={setCorreo}
                                tittle={'Correo:'}
                                forceLayoutSty={{flex:'5'}}

                            />
                        </div>
                        <div className='cont-infoClte' style={{flex:'4'}}>
                            
                            {/* <p className='txt-infoClte' style={{flex:'3'}}> Regimen fiscal: </p> */}
                            <Inpt
                                type={'select'}
                                value={RegimFisc}
                                setValue={setRegimFisc}
                                tittle={'Regimen fiscal:'}
                                defaultOpc={'Seleccionar'}
                                forceLayoutSty={{flex:'3'}}
                                opciones={state.listRegims}

                            />
                            </div>
                        <div className='cont-infoClte' style={{flex:'4'}}>
                        
                        {/* <p className='txt-infoClte' style={{flex:'3'}}> Uso CFDI: </p> */}
                        <Inpt
                            type={'select'}
                            value={CFDI}
                            setValue={setCFDI}
                            tittle={'Uso CFDI:'}
                            defaultOpc={'Seleccionar'}
                            forceLayoutSty={{flex:'3'}}
                            opciones={state.listCFDIs}

                        />
                        </div>

                    </div>

                    <div className='enlace-cliente' style={{flex:'1'}}>
                        <div className='cont-enlacepkt1'>
                                    
                            <div className='cont-Titulo'>
                                <div className='cinta-Titulo'>
                                    <h2 className='txt-cintaTitulo' >Enlazar con cliente PKT1</h2>
                                </div>
                            </div>
                            
                            <div className='cont-enlazar'>
                                    
                                <div className='cont-idpkt1'>
                                    
                                    <div className='info-CltePKT1'>
                                        <p className='txt-cltepkt1'>ID cliente PKT1</p>
                                        <div className='infoShow-pkt1'><p className='txt-cltepkt1'>{IDpkt1}</p></div>
                                    </div>

                                    
                                    <div className='info-CltePKT1'>
                                        <p className='txt-cltepkt1'>Nombre cliente PKT1</p>
                                        <div className='infoShow-pkt1'><p className='txt-cltepkt1'>{Namepkt1}</p></div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                            <div className='EnlaceOpciones' style={{display:'flex', justifyContent: 'space-around'}}>

                                <div className='btn-enlazarCltes'>
                                    <Btn
                                        type={'custom'}
                                        descript={'Buscar'}
                                        forceBtnSty={{background:'#151b4e'}}
                                        clicked={OpenModal_ClientesEnlazar}
                                        disabled={TipoCliente !== 'E'}
                                        />
                                </div>
                                <div className='btn-limpiarEnlace'>
                                    <Btn
                                        type={'custom'}
                                        descript={'Limpiar'}
                                        forceBtnSty={{background:'#151b4e'}}
                                        clicked={()=>handleEnlace(undefined, undefined)}
                                        disabled={IDpkt1 == undefined ? true : false}
                                        />
                                </div>
                            
                            </div>
                        </div>
                        
                        <div className='Btns-Submit' style={{margin:'0 auto', display:'flex'}}>

                            <Btn
                                type={'accept'}
                                descript={alterclte === 'Editar' ? 'Guardar' : 'Agregar'}
                                clicked={alterclte === 'Editar' ? handleEditarClte : AddClte }
                            />
                        </div>
                        
                    </div>
                </div>

            </div>


            
                <ModalComponent
                StatusModal={Modal_ClientesEnlazar}
                CerrarModal={CloseModal_ClientesEnlazar}
                Titulo={'Seleccionar cliente PKT1'}
                Contenido={RenderItemEnlacePKT1()}
                ancho={'40%'}
                alto={'85%'}

                />


        </div>
        }


    </LayoutAlterCliente>
    
  )
}

export default AgregarCliente