import axios from 'axios'
import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import Swal from 'sweetalert2'
import { usePaginacion } from '../../../utils/components/Paginacion/hook/usePaginacion'
import useModal from '../../../utils/components/Modal/hook/useModal'
import { useNavigate } from 'react-router-dom'
import { toHaveAccessibleErrorMessage } from '@testing-library/jest-dom/matchers'

export const useClientes = (props) => {
  const navigate = useNavigate()
  const {User} = useAuth()
  const {Modal_ClienteStatus, OpenModal_ClienteStatus, CloseModal_ClienteStatus} = useModal()
  const {Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setRegsPg, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg } = usePaginacion()

  const [TextoSearch, setTextoSearch] = useState('')
  // const [Pg, setPg] = useState(0)
  // const [MaxPg, setMaxPg] = useState(10)
  // const [RegsPg, setRegsPg] = useState(10)
  // const [Loading, setLoading] = useState(false)
  // const [InfoTab, setInfoTab] = useState()

  
  const [FltrTypeClte, setFltrTypeClte] = useState(0)
  const [FltrActiveClte, setFltrActiveClte] = useState(0)

  const [DataRegimens, setDataRegimens] = useState()
  const [DataCFDIs, setDataCFDIs] = useState()

  const [TipoCliente, setTipoCliente] = useState()
  const [CP, setCP] = useState()
  const [Nombre, setNombre] = useState()
  const [RFC, setRFC] = useState()
  const [Correo, setCorreo] = useState()
  const [RegimFisc, setRegimFisc] = useState()
  const [CFDI, setCFDI] = useState()
  const [IDpkt1, setIDpkt1] = useState()
  const [Namepkt1, setNamepkt1] = useState()
  
  const [ClteStatusData, setClteStatusData] = useState()
  const [FlagAlterCliente, setFlagAlterCliente] = useState()

  const AbrirModalCambioStatus = (data) =>{
    setClteStatusData(data)
    OpenModal_ClienteStatus()
  }
  const NavAlter = (nav, data) => {
    if (nav === 'Editar') {
      navigate(nav, {state:{
          "id": data.id,
          "idclientepkt1": data.idclientepkt1,
          "nombreclientepkt1": data.nombreclientepkt1,
          "tipocliente": data.tipocliente,
          "nombre": data.nombre,
          "correo": data.correo,
          "rfc": data.rfc,
          "regimenfiscal": data.regimenfiscal,
          "usocfdi": data.usocfdi,
          "cp": data.cp,
          "estatus": data.estatus,

          "listRegims": DataRegimens,
          "listCFDIs": DataCFDIs
        }})
        
    }else{
      navigate('Agregar', {state: {
        "listRegims": DataRegimens,
        "listCFDIs": DataCFDIs}})
    }
  }
  const NavBack = () =>{
    navigate('/Clientes')
  }
  const ObtenerClientes = async() =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=${FltrTypeClte}&Filtro=${FltrActiveClte}&Buscar=${TextoSearch === undefined ? '' : TextoSearch}&Pagina=${Pg === 0 ? 1 : Pg}&Registros=${RegsPg === 0 ? 10 : RegsPg}`)
  }
  const ObtenerCltesPKT1 = async(nombrepkt, pgpkt, regspkt) =>{
    GetPages(`${process.env.REACT_APP_API_URL_WS_V2}Cliente/paginator/all?search=${TextoSearch === undefined ? '' : TextoSearch}&page=${Pg === 0 ? 1 : Pg}&registerbypage=${regspkt}`)
  }

  const AddClte = async() =>{
    setFlagAlterCliente(true)
    let dataClient = {
      "idclientepkt1": IDpkt1,
      "tipocliente": TipoCliente === 'Interno' ? 'I' : 'E',
      "nombre": Nombre,
      "correo": Correo,
      "rfc": RFC,
      "regimenFiscal": RegimFisc,
      "usoCfdi": CFDI,
      "cp": CP,
      "estatus": true
    }

    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Agregar`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + User.token
      },
      data : dataClient
    };
    await axios(config)
    .then( res =>{
      
      Swal.fire({
        title: 'Cliente agregado',
        text: '',
        icon: 'success',
        position: 'top',
        timer: 2500,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })

      

      NavBack()
      setFlagAlterCliente(false)
    })
    .catch( err =>{
      
      Swal.fire({
        title: 'Error al agregar cliente',
        text: `${err.response.data.message}`,
        icon: 'error',
        position: 'top',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
      setFlagAlterCliente(false)

    })

  }
  const EditClte = async(previaData) =>{
    setFlagAlterCliente(true)
    let dataClient = { //Este enredo es debido aque el servicio API, necesita que solo envies los datos modificados
      "idclientepkt1": IDpkt1 !== previaData.idclientepkt1 ? IDpkt1 : undefined ,
      "tipocliente": TipoCliente !== previaData.tipocliente ? TipoCliente === 'Interno' ? 'I' : 'E' : undefined,
      "nombre": Nombre !== previaData.nombre ? Nombre : undefined ,
      "correo": Correo !== previaData.correo ? Correo : undefined ,
      "rfc": RFC !== previaData.rfc ? RFC : undefined ,
      "regimenFiscal": RegimFisc !== previaData.regimenfiscal ? RegimFisc : undefined ,
      "usoCfdi": CFDI !== previaData.usocfdi ? CFDI : undefined ,
      "cp": CP !== previaData.cp ? CP : undefined ,
      // "estatus": previaData.estatus,
    }
    
    
    if (dataClient === previaData) {
      
      
      Swal.fire({
        title: 'Error al actualizar cliente',
        text: 'No hay cambios en los datos del cliente',
        icon: 'error',
        position: 'top',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })

      setFlagAlterCliente(false)
    } else {
      
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Actualizar/${previaData.id}`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
        data : dataClient
      };
      await axios(config)
      .then( res =>{
        
        Swal.fire({
          title: 'Datos Actualizados',
          // text: '',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })

        NavBack()
        setFlagAlterCliente(false)
        
      })
      .catch( err =>{
        
        Swal.fire({
          title: 'Error al actualizar cliente',
          text: `${err.response.data.message}`,
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        
      setFlagAlterCliente(false)
      })
    }


  }
  const HabilitarClte = async() =>{
    
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Habilitar/${ClteStatusData?.id}`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + User.token
      },
    };

    await axios(config)
    .then( res =>{
       
      Swal.fire({
        title: 'Cliente habilitado',
        // text: '',
        icon: 'success',
        position: 'top',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
      CloseModal_ClienteStatus()
      ObtenerClientes()
    })
    .catch( err =>{
      
      Swal.fire({
        title: 'Error al habilitar cliente',
        // text: '',
        icon: 'error',
        position: 'top',
        // timer: 2000,
        // timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
    })

    // https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Clientes/Habilitar/1
  }
  const DesHabilitarClte = async() =>{
    
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Deshabilitar/${ClteStatusData?.id}`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + User.token
      },
    };

    await axios(config)
    .then( res =>{
      
      Swal.fire({
        title: 'Cliente deshabilitado',
        // text: '',
        icon: 'success',
        position: 'top',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })

      CloseModal_ClienteStatus()
      ObtenerClientes()
    })
    .catch( err =>{
      
      Swal.fire({
        title: 'Error al deshabilitar cliente',
        // text: '',
        icon: 'error',
        position: 'top',
        // timer: 2000,
        // timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })

    })

    // https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Clientes/Deshabilitar/1
  }





  const ObtenerRegimens = async() =>{
    await axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetFiscalRegimeAll`)
    .then( res =>{
      setDataRegimens(res.data?.map(res=>{return(res)}))
    })
    .catch( err =>{
      console.log(err)
    })
  }
  const ObtenerCFDIs = async() =>{
    await axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetUsesCFDIAll`)
    .then( res =>{
      setDataCFDIs(res.data?.map(res=>{return(res)}))
    })
    .catch( err =>{
      console.log(err)
    })
  }



  
  const Cliente_ProPrevPg = () =>{
    ProPrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=${FltrTypeClte === undefined ? '' : FltrTypeClte}&Filtro=${FltrActiveClte === undefined ? '' : FltrActiveClte}&Buscar=${Nombre === undefined ? '' : Nombre}&Pagina=1&Registros=${RegsPg}`)
  }
  const Cliente_PrevPg = () =>{
    PrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=${FltrTypeClte === undefined ? '' : FltrTypeClte}&Filtro=${FltrActiveClte === undefined ? '' : FltrActiveClte}&Buscar=${Nombre === undefined ? '' : Nombre}&Pagina=${Pg-1}&Registros=${RegsPg}`)
  }
  const Cliente_NextPg = () =>{
    NextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=${FltrTypeClte === undefined ? '' : FltrTypeClte}&Filtro=${FltrActiveClte === undefined ? '' : FltrActiveClte}&Buscar=${Nombre === undefined ? '' : Nombre}&Pagina=${Pg+1}&Registros=${RegsPg}`)
  }
  const Cliente_ProNextPg = () =>{
    ProNextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=${FltrTypeClte === undefined ? '' : FltrTypeClte}&Filtro=${FltrActiveClte === undefined ? '' : FltrActiveClte}&Buscar=${Nombre === undefined ? '' : Nombre}&Pagina=${MaxPg}&Registros=${RegsPg}`)
  }

  const ChangeRegs = (Regis) =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=${FltrTypeClte === undefined ? '' : FltrTypeClte}&Filtro=${FltrActiveClte === undefined ? '' : FltrActiveClte}&Buscar=${Nombre === undefined ? '' : Nombre}&Pagina=1&Registros=${Regis}`)
  }

  const CltePKT1_ProPrevPg = () =>{
    ProPrevPg(`${process.env.REACT_APP_API_URL_WS_V2}Cliente/paginator/all?search=${TextoSearch === undefined ? '' : TextoSearch}&page=1&registerbypage=${RegsPg}`)
  }
  const CltePKT1_PrevPg = () =>{
    PrevPg(`${process.env.REACT_APP_API_URL_WS_V2}Cliente/paginator/all?search=${TextoSearch === undefined ? '' : TextoSearch}&page=${Pg-1}&registerbypage=${RegsPg}`)
  }
  const CltePKT1_NextPg = () =>{
    NextPg(`${process.env.REACT_APP_API_URL_WS_V2}Cliente/paginator/all?search=${TextoSearch === undefined ? '' : TextoSearch}&page=${Pg+1}&registerbypage=${RegsPg}`)
  }
  const CltePKT1_ProNextPg = () =>{
    ProNextPg(`${process.env.REACT_APP_API_URL_WS_V2}Cliente/paginator/all?search=${TextoSearch === undefined ? '' : TextoSearch}&page=${MaxPg}&registerbypage=${RegsPg}`)
  }
  
  const Changelistpkt1 = (RegsPg) =>{
    GetPages(`${process.env.REACT_APP_API_URL_WS_V2}Cliente/paginator/all?search=${TextoSearch === undefined ? '' : TextoSearch}&page=1&registerbypage=${RegsPg}`)
  }

  
  
  
  const [Activedots, setActivedots] = useState()
  const ActivarDots = (dato) =>{
    // alert('as')
    if (dato === Activedots) {
      setActivedots()
    }else{  
      setActivedots(dato)
    }
  }
  
    return {
      Modal_ClienteStatus, OpenModal_ClienteStatus, CloseModal_ClienteStatus,
      Pg, MaxPg, RegsPg, InfoTab, TextoSearch, Loading, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg, ChangeRegs, setTextoSearch,
      ObtenerCltesPKT1, Changelistpkt1, ObtenerClientes, FltrActiveClte, setFltrActiveClte, FltrTypeClte, setFltrTypeClte, TipoCliente, setTipoCliente, CP, setCP, Nombre, setNombre, RFC, setRFC, Correo, setCorreo, RegimFisc, setRegimFisc, CFDI, setCFDI, IDpkt1, setIDpkt1, Namepkt1, setNamepkt1, ObtenerRegimens, ObtenerCFDIs,
      DataRegimens, setDataRegimens, DataCFDIs, setDataCFDIs, AddClte, EditClte, NavAlter,
      Cliente_NextPg,  Cliente_PrevPg,  Cliente_ProNextPg, Cliente_ProPrevPg, CltePKT1_NextPg, CltePKT1_PrevPg, CltePKT1_ProNextPg, CltePKT1_ProPrevPg,
      ClteStatusData, AbrirModalCambioStatus, HabilitarClte, DesHabilitarClte, NavBack, Activedots, setActivedots, ActivarDots, FlagAlterCliente, setFlagAlterCliente
    }
}
