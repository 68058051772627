import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { usePaginacion } from '../../../utils/components/Paginacion/hook/usePaginacion'
import { useNavigate } from 'react-router-dom'
import { useFacturacion } from '../../Facturas/hook/useFacturacion'

import Swal from 'sweetalert2'
import moment from 'moment'
import axios from 'axios'
import useModal from '../../../utils/components/Modal/hook/useModal'

export const useAbonos = () => {

  const navigate = useNavigate()
  const {Autorized, User} = useAuth()
  const {formatMoney} = useFacturacion()
  const {Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setLoading, setRegsPg, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg } = usePaginacion()
  const {
    Modal_AbonosPendingFacts, OpenModal_AbonosPendingFacts, CloseModal_AbonosPendingFacts,
    Modal_FacturasReCorreo, OpenModal_FacturasReCorreo, CloseModal_FacturasReCorreo,
    Modal_FacturasVerPDF, OpenModal_FacturasVerPDF, CloseModal_FacturasVerPDF,
    Modal_FacturasCancelarFact, OpenModal_FacturasCancelarFact, CloseModal_FacturasCancelarFact
  } = useModal()
  

  //EStados para paginador abonos
  
  const [PagsFltr_TipoFactura, setPagsFltr_TipoFactura] = useState(0)
  const [PagsFltr_TipoPago, setPagsFltr_TipoPago] = useState(0)
  const [PagsFltr_Estatus, setPagsFltr_Estatus] = useState(0)
  const [PagsFltr_FchInicio, setPagsFltr_FchInicio] = useState(moment().format('YYYY-MM-01'))
  const [PagsFltr_FchFinal, setPagsFltr_FchFinal] = useState(moment().format('YYYY-MM-DD'))

  const [CancelarAbonoData, setCancelarAbonoData] = useState()
  const [ReCorreoData, setReCorreoData] = useState()
  const [DestinoCorreo, setDestinoCorreo] = useState()
  const [B64, setB64] = useState()

  //Inputs para complementos de pago
  const hoyFch = (moment().format('YYYY-MM-DD'))
  const [BuscarCliente, setBuscarCliente] = useState('')
  const [FchPago, setFchPago] = useState(hoyFch)
  const [MetodoPago, setMetodoPago] = useState(0)
  const [MetodosDePago, setMetodosDePago] = useState()
  const [ImporteAbonar, setImporteAbonar] = useState(0)
  const [BancoSelected, setBancoSelected] = useState()
  const [Bancos, setBancos] = useState()
  const [Referencia, setReferencia] = useState()
  const [FlagSrchCltes, setFlagSrchCltes] = useState(false)
  const [LoadingPendings, setLoadingPendings] = useState()
  


  // Estados necesarias para funciones
  const [ClienteId, setClienteId] = useState()
  const [ResCltes, setResCltes] = useState()

  // Estados del modal
  const [FltrTipoFact, setFltrTipoFact] = useState(0)

  //Estados y funciones para la seleccion de facturas pendientes
  const [PendingFacts, setPendingFacts] = useState([])

  const [FactsSelects, setFactsSelects] = useState([])
  const [IdsFactsSelects, setIdsFactsSelects] = useState([])
  const [Flag_AllSelected, setFlag_AllSelected] = useState(false)
  const [ImportRestant, setImportRestant] = useState(0)
  const [TotalAbonar, setTotalAbonar] = useState(0)

  
  const [FlagSendingMail, setFlagSendingMail] = useState(false)

  //Estado para mantener opciones de la tabla
  const [Activedots, setActivedots] = useState()
  const ActivarDots = (dato) =>{
    if (dato === Activedots) {
      setActivedots()
    }else{  
      setActivedots(dato)
    }
  }
  const NavAlter = () => {
    ObtenerMetodPagos()
    ObtenerBancos()
    navigate('Agregar')
  
  }    
  
  const ObtenerFacturasPendientes = async() =>{
      try { //Obtener Facturas Pendientes
          let config = {
              method: 'get',
              url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Facturas/Pendientes-Pagar?IdCliente=${ClienteId}&Pagina=1&RegistrosPorPagina=20`,
              headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + User.token
              },
          };
          let res = await axios(config)
          if (res.status === 200) {
            
            OpenModal_AbonosPendingFacts()
            AsignarFactsPends(res.data.resultado)
          } else if (res.status === 204){
            Swal.fire({
              title: '',
              text: `Este cliente no cuenta con facturas para abonar`,
              icon: 'warning',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
          }
          
          } catch (err) {
            console.log(err)
            Swal.fire({
                title: '',
                text: `No se encontraron facturas por abonar para este cliente`,
                icon: 'error',
                position: 'top',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#52C150'
            })
          }
  }
  const AllSelecting = (evt) =>{
          // props.GetAbonoTotal()
      if (evt === true) {
          AddAll()
      }else if (evt === false) {
          DelAll()
      }
  }
  const SeleccionarFactPend = (Fact, evt) =>{
      if (evt === true) {
          AddFact(Fact)
      }else{
          RemoveFact(Fact)
      }
      
  }
  const AddFact = (Fact) =>{
      let IdSelecteds = [...IdsFactsSelects, Fact.id]
      let ActualSelecteds = [...FactsSelects, Fact]

      if (IdSelecteds.length === PendingFacts.length) {
          setFlag_AllSelected(true)
      }

      setFactsSelects(ActualSelecteds)
      setIdsFactsSelects(IdSelecteds)


      
  }
  const RemoveFact = (Fact) =>{
    

  const IdSelecteds = IdsFactsSelects.filter((Slcts, index) => Slcts !== Fact.id)
  const ActualSelecteds = FactsSelects.filter((filt, index) => filt.id !== Fact.id)

  if (IdsFactsSelects.length - PendingFacts.length === 0) {
      setFlag_AllSelected(false)
  }

  setFactsSelects(ActualSelecteds)
  setIdsFactsSelects(IdSelecteds)

  }
  const AddAll = () =>{
  let AllFacts = [];
  let AllIds = [];

  // Recorrer RowAbno, sacar las rows con abono y guardarlas en otro arreglo (tambien la suma de sus abonos)
  // Al intentar insertar tomar como tope la suma de abonos y evitar hacer operaciones con los ids de las rows guardadas

  PendingFacts.map( (Fact) => {
      AllFacts.push(Fact)
      AllIds.push(Fact.id)
  })
  
  setFactsSelects(AllFacts)
  setIdsFactsSelects(AllIds)

  setFlag_AllSelected(true)
  }
  const DelAll = () =>{
  setFactsSelects([])
  setIdsFactsSelects([])
  setFlag_AllSelected(false)
  }
      
  const AsignarFactsPends = (data) =>{
    data?.forEach(Fact => {
      Fact.total = 0;
    });
    setPendingFacts(data)
  }
      
      
  const ObtenerClientes = async(data) =>{
      setBuscarCliente(data)
      if (data.length >= 3) {
      let config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=0&Filtro=1&Buscar=${data}&Pagina=1&Registros=10`,
          headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
          },
      };
      await axios(config)
      .then( (res) =>{
          setResCltes(res.data.resultado)
          setFlagSrchCltes(true)
      })
      .catch( (err) =>{
          console.log(err)
      })
      }else{
      setFlagSrchCltes(false)
      }
  }
  const EstablecerClientes = (data) =>{ 
      setBuscarCliente(data.nombre)
      setClienteId(data.id)
      setFlagSrchCltes(false)

  }
  const ObtenerMetodPagos = async() =>{

      let config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Formas/Pago`,
          headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
          },
      };

      try { const res = await axios(config)
          setMetodosDePago(res.data)
          
      } catch (err) {
          console.log(err)
      }
  }
  const ObtenerBancos = async() =>{
    // https://wsdesarrollo.paq1.com.mx/api/v2/Banco/GetBank
    
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL_WS_V2}Banco/GetBank`,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
      },
    };
    try { const res = await axios(config)
      let Banks = res.data.map( bank => {
        return{
          "key": bank.nombre,
          "description": bank.nombre
        }
      })
      setBancos(Banks)
      
    } catch (error) {
      
    }
  }


  const AbrirModal_AbonosPendingFacts = async() =>{
      if (ClienteId > 0) {
          ObtenerFacturasPendientes()
        }else{
          
          Swal.fire({
              title: 'Selecciona un cliente',
              text: `Busca y selecciona un cliente antes de seleccionar una factura`,
              icon: 'warning',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
        }

  }

  const CerrarModal_AbonosPendingFacts = () =>{
    GetAbonoTotal()
    CloseModal_AbonosPendingFacts()
  }

  const CerrarModal_ReCorreo = () =>{
    setDestinoCorreo('')
    CloseModal_FacturasReCorreo()
  }


  const CambiarImporteAbonar = (importe) =>{
    setImporteAbonar(parseFloat(importe))
    
    let totls = 0;  
    FactsSelects?.map(Fact =>{
      totls += parseFloat(Fact.total)

    })


    totls = parseFloat(totls.toFixed(2))
    setTotalAbonar(totls)
    setImportRestant(importe - totls)
  }
  
  const GetAbonoTotal = () =>{
    let totls = 0;  
    FactsSelects?.map(Fact =>{
      totls += parseFloat(Fact.total)

    })


    totls = parseFloat(totls.toFixed(2))
    setTotalAbonar(totls)
    setImportRestant(ImporteAbonar - totls)
  }

  const CambiarValorAbonar = (valor, idFact) =>{
    let nuevoArreglo = [...FactsSelects]
    nuevoArreglo[idFact].total = parseFloat(valor.toFixed(2))
    setFactsSelects(nuevoArreglo)
    GetAbonoTotal()
    
  }

  const SaldarFactura = (Fact, idFact) =>{
    
    if (ImportRestant - Fact.saldo >= 0) {
      CambiarValorAbonar(Fact.saldo, idFact)
    }else if (ImportRestant > 0){
      CambiarValorAbonar(ImportRestant, idFact)
    }
  }

  const LimpiarFactura = (idFact) =>{
    CambiarValorAbonar(0, idFact)
  }

  const EmitirAbono = async() =>{
    let facturasAbonar = FactsSelects?.map((Fact, id) =>{
      return{
          "id": Fact.id,
          "total": Fact.total
      }
    })
      let dataAbono = {
        "idSucursal": 1,
        "idUsuario": User.idusuario,
        "idCliente": ClienteId,
        "formaPago": MetodoPago,
        "moneda": "MXN",
        "total": ImporteAbonar,
        "fechaAbono": moment(FchPago).format('DD-MM-YYYY'),
        "banco": BancoSelected,
        "referencia": Referencia,
        "facturas": facturasAbonar
      }
      if (ImporteAbonar < TotalAbonar) {
        Swal.fire({
          title: 'Importe incorrecto',
          text: 'El importe declarado es mayor al importe a abonar, verifica las cantidades',
          icon: 'warning',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      }else if (MetodoPago < 1) {
        Swal.fire({
          title: 'Seleccione un metodo de pago',
          // text: 'El importe declarado es mayor al importe a abonar, verifica las cantidades',
          icon: 'warning',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      }else if (ImporteAbonar !== TotalAbonar) {
        if (TotalAbonar === 0) {
          Swal.fire({
              title: 'Importe incorrecto',
              text: 'El importe declarado no puede ser cero',
              icon: 'warning',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
        }else{
          Swal.fire({
              title: 'Importe incorrecto',
              text: `El total del importe a abonar de la factura no coincide con el importe declarado`,
              icon: 'warning',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
        }
      }else{
        setLoadingPendings(true)
        
        let config = {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Abonar/Factura`,
          headers: { 
              'Authorization': 'Bearer ' + User.token, 
              'Content-Type': 'application/json'
          },
          data : dataAbono
        };
        try { const res = await axios(config)
          
          Swal.fire({
            title: 'Abono realizado',
            // text: `${err.response.data.message}`,
            icon: 'success',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
          navigate('/Abonos')
          setLoadingPendings(false)

        } catch (err) {
          if (err.response.status === 401) {
            
            Swal.fire({
              title: 'Error al abonar',
              text: `Sesión Caducada`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
            })
          }else{
            Swal.fire({
              title: 'Error al abonar',
              text: `${err.response.data.message}`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
            })
          }
          setLoadingPendings(false)
        }

      }
  }
  const CancelarAbono = async(idFact)=>{
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Cancelar/Abono/${idFact}?IdMotivo=03&IdUsuario=${User.idusuario}&FechaCancelacion=${hoyFch}`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + User.token
      },
    }
    setLoading(true)
    try { await axios(config)
      
      Swal.fire({
        title: 'Factura cancelada',
        // text: '',
        icon: 'success',
        position: 'top',
        timer: 1800,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
      setLoading(false)
      setCancelarAbonoData()
      ObtenerAbonos()
      CloseModal_FacturasCancelarFact()
      
    } catch (err) {
      setLoading(false)
      if (err.response.status === 401) {
        Swal.fire({
          title: 'Error al generar la factura',
          text: 'Su sesión ha caducado',
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      }else if (err.response.status === 400) {
        
        Swal.fire({
          title: 'Error al generar la factura',
          text: `${err.response.data.message}`,
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      }
    }

  }

  // https://pkt1internodesarrollo.paq1.com.mx/Api/V2/Facturacion/Retimbrar/Abono/38
  const RetimbrarAbono = async(dataFact) =>{
    
    if (dataFact.estatus === 0) {
      
      setLoading(true)
      await axios.get(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Retimbrar/Abono/${dataFact.id}`, {
        headers: {
          'Authorization': 'Bearer ' + User.token
        }
      })
      .then(res =>{
        setLoading(false);
        Swal.fire({
          title: 'Abono timbrado',
          // text: ``,
          icon: 'success',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        ObtenerAbonos()
      })
      .catch(err =>{
        setLoading(true)
  
          setLoading(false)
          Swal.fire({
            title: 'Error al retimbrar abono',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })

      })
    }
      

  }
  
  const ReenviarCorreo = async(data, Tipo) =>{
    setFlagSendingMail (true)
    await  axios.get(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Correo/Enviar/${data.serie}/${data.folio}?Tipo=${Tipo}&Correos=${data.correoCliente}`, {
        headers: {
            'Authorization': 'Bearer ' + User.token
        }
    })
    .then(res => { 
      
      Swal.fire({
        title: 'Correo Enviado',
        // text: `${err.response.data.message}`,
        icon: 'success',
        position: 'top',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
    })
      CloseModal_FacturasReCorreo()
      setFlagSendingMail (false)
    })
    .catch(err => { 
      setFlagSendingMail (false)
        if (err.response.status === 401 ) {
          Swal.fire({
              title: 'Error al reenviar correo',
              text: `${err.response.data.message}`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
        }else{
            Swal.fire({
                title: 'Error al reenviar correo',
                text: `${err.response.data.message}`,
                icon: 'error',
                position: 'top',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#52C150'
            })
        }
    })  
    
  }

  
  const ObtenerAbonos = () =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg === 0 ? 1 : Pg}&RegistrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`)
  }
  
  const BuscarAbonos = () =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${1}&RegistrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`)
  }
  
  const ChangeRegs = (Regis) =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg === 0 ? 1 : Pg}&RegistrosPorPagina=${Regis}`)
  }
  
  const Abonos_ProPrevPg = () =>{
    ProPrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${1}&RegistrosPorPagina=${RegsPg}`)
  }
  const Abonos_PrevPg = () =>{
    PrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg - 1}&RegistrosPorPagina=${RegsPg}`)
  }
  const Abonos_NextPg = () =>{
    NextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg + 1}&RegistrosPorPagina=${RegsPg}`)
  }
  const Abonos_ProNextPg = () =>{
    ProNextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador/Abonos?Busqueda=${BuscarCliente}&IdSucursal=1&MetodoPago=${MetodoPago}&Filtro=${PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${MaxPg}&RegistrosPorPagina=${RegsPg}`)
  }



  const AbrirModalReCorreo = (data) =>{
    OpenModal_FacturasReCorreo()
    setReCorreoData(data)

  }
  const AbrirModalVerPDF = async(data) =>{
    //https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Facturacion/PDF/CLMMAFDD/39
      await axios.get(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/PDF/${data.serie}/${data.folio}`,{
        headers: {
          'Authorization': 'Bearer ' + User.token
        }
      })
    .then( res => {


      // investigar como agregandole el nombre al pdf (para no mostrar nombre en base 64)


      
        setB64('data:application/pdf;base64,' + res.data.data) //cambiar para despues res.data.data
        OpenModal_FacturasVerPDF(true)
    })
    .catch(err =>{
      console.log(err)
      
      if (err.response.status === 401 ) {
        Swal.fire({
            title: 'Error al reenviar correo',
            text: `La sesión ha caducado`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })
      }else{
          Swal.fire({
              title: 'Error al reenviar correo',
              text: `${err.response.data.message}`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
      }

    })
  }
  const AbrirModalCancelarFactura = async(data) =>{
    OpenModal_FacturasCancelarFact()
    setCancelarAbonoData(data)
  }
  


  return {
    Autorized,
    Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setLoading, setRegsPg, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg,
    Activedots, setActivedots, ActivarDots, NavAlter, formatMoney,
    
    ObtenerFacturasPendientes, AllSelecting, SeleccionarFactPend, AddFact, RemoveFact, AddAll, DelAll, ObtenerClientes, EstablecerClientes, ObtenerMetodPagos, AbrirModal_AbonosPendingFacts, CerrarModal_AbonosPendingFacts, 
    BuscarCliente, FchPago, MetodoPago, MetodosDePago, ImporteAbonar, BancoSelected, Referencia, FlagSrchCltes, ClienteId, ResCltes, FltrTipoFact, PendingFacts, FactsSelects, IdsFactsSelects, Flag_AllSelected,
    Modal_AbonosPendingFacts, OpenModal_AbonosPendingFacts, CloseModal_AbonosPendingFacts, 
    setFchPago, setMetodoPago, setImporteAbonar, setBancoSelected, setReferencia, setFltrTipoFact, Bancos,
    CambiarValorAbonar, TotalAbonar, ImportRestant, SaldarFactura, LimpiarFactura, CambiarImporteAbonar, EmitirAbono,
    PagsFltr_TipoFactura, PagsFltr_TipoPago, PagsFltr_Estatus, PagsFltr_FchInicio, PagsFltr_FchFinal,
    setPagsFltr_TipoFactura, setPagsFltr_TipoPago, setPagsFltr_Estatus, setPagsFltr_FchInicio, setPagsFltr_FchFinal,
    ObtenerAbonos, ChangeRegs, Abonos_ProPrevPg, Abonos_PrevPg, Abonos_NextPg, Abonos_ProNextPg, BuscarAbonos,
    BuscarCliente, setBuscarCliente, LoadingPendings, CancelarAbonoData, ReCorreoData, DestinoCorreo, B64, setDestinoCorreo,
    Modal_FacturasReCorreo, OpenModal_FacturasReCorreo, CloseModal_FacturasReCorreo, CerrarModal_ReCorreo,
    Modal_FacturasVerPDF, OpenModal_FacturasVerPDF, CloseModal_FacturasVerPDF, FlagSendingMail,
    Modal_FacturasCancelarFact, OpenModal_FacturasCancelarFact, CloseModal_FacturasCancelarFact,
    AbrirModalReCorreo, AbrirModalVerPDF, AbrirModalCancelarFactura, ReenviarCorreo, CancelarAbono, RetimbrarAbono, ObtenerBancos
    

  }
}

