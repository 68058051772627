import React, { useState } from 'react'
import usuario from '../../assets/iconos/usuario.png'
import contra from '../../assets/iconos/contra.png'
import logimg from '../../assets/imagenes/paklog.png'
import logimgmovil from '../../assets/imagenes/paklogmov.png'
import { useCardLogin } from './hook/useCardLogin'
import Inpt from '../../utils/components/input/Inpt'
import Btn from '../../utils/components/boton/Btn'

const CardLogin = () => {

    const {username, setusername, password, setpassword, Logearse} =useCardLogin()
    

  return (
    <div className='contenedor-login'>
        <div className='cuerpo-login'>
            <form className='formulario-login'>
                <div className='contenedor-datos'>
                    <div className='cuerpo-formulario'>

                        <div className='inputs-logear'>    
                            <h1>Bienvenido</h1>
                            <h2><span><img className='img-logearse' src={usuario} alt="" ></img></span>Usuario</h2>
                            <Inpt
                                type={'text'}
                                placehold={''}
                                value={username}
                                setValue={setusername}
                                metodo={Logearse}
                            ></Inpt>

                            <h2><span><img className='img-logearse' src={contra} alt="" ></img></span>Contraseña</h2>

                            <Inpt
                                type={'password'}
                                placehold={''}

                                value={password}
                                setValue={setpassword}
                                metodo={Logearse}
                            ></Inpt>
                        </div>
                        <div className='boton-logear'>
                            <Btn
                                forceLayoutSty={{display:'flex', userSelect:'none', width:'50%'}}
                                type={'accept'}
                                descript={'Iniciar sesión'}
                                clicked={Logearse}
                            ></Btn>
                        </div>

                    </div>
                </div>
            </form>
            <div className='contenedorimg'>
                <img className='logImg' src={logimg} alt="" />
                <img className='logImgMov' src={logimgmovil} alt="" />
            </div>
        </div>
    </div>
  )
}

export default CardLogin