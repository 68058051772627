import React, { useEffect, useState } from 'react'
import { LayoutAddAbono } from '../../themes/layout'
import Inpt from '../../utils/components/input/Inpt'
import Btn from '../../utils/components/boton/Btn'
import { useAuth } from '../../context/AuthContext'
import { useAbonos } from './hook/useAbonos'
import ModalComponent from '../../utils/components/Modal/Modal'
import useModal from '../../utils/components/Modal/hook/useModal'
import lupa from '../../assets/SVGs/Lupa.svg'
import moment from 'moment'
import AnLoader from '../../utils/components/Preloader/AnLoader'


const AddAbono = () => {
    const {
        formatMoney, ObtenerFacturasPendientes, AllSelecting, SeleccionarFactPend, AddFact, RemoveFact, AddAll, DelAll, ObtenerClientes, EstablecerClientes, ObtenerMetodPagos, AbrirModal_AbonosPendingFacts, CerrarModal_AbonosPendingFacts,
        BuscarCliente, FchPago, MetodoPago, MetodosDePago, ImporteAbonar, BancoSelected, Referencia, FlagSrchCltes, ClienteId, ResCltes, FltrTipoFact, PendingFacts, FactsSelects, IdsFactsSelects, Flag_AllSelected,
        Modal_AbonosPendingFacts, OpenModal_AbonosPendingFacts, CloseModal_AbonosPendingFacts,
        setFchPago, setMetodoPago, setImporteAbonar, setBancoSelected, setReferencia, setFltrTipoFact, Bancos,
        CambiarValorAbonar, TotalAbonar, ImportRestant, SaldarFactura, LimpiarFactura, CambiarImporteAbonar, EmitirAbono, LoadingPendings, ObtenerBancos
     } = useAbonos()




    const RenderItemPendingFacts = () =>{
        return(
            
                <div className='cont-PendingFacts'>
                    <div className='filter-tipoFact'>
                        <div className='In-TipoFact'>
                            <Inpt
                                type={'select'}
                                tittle={'Tipo de factura'}
                                value={FltrTipoFact}
                                setValue={setFltrTipoFact}
                                opciones={[
                                    { "key": 0, "description" : 'Todas'},
                                    { "key": 2, "description" : 'Venta de Activos'},
                                    { "key": 4, "description" : 'Ingresos adicionales'}
                                ]}

                            />
                        </div>
                        
                        <div className='BotonBuscar'>
                            <Btn
                                type={'iconoMini'}
                                imagen={lupa}
                                forceBtnSty={{background:'#3BAF29'}}
                                // clicked={BuscarFacturas}
                            
                            />
                        </div>

                    </div>

                    <div className='Tabla-PendingFacts'>

                        <div className='TablaPendings-Head'>
                            <div className='TablaPendings-Chek'>
                                <input type="checkbox" className='Chek-AllSelects' checked={Flag_AllSelected} onChange={(e)=>AllSelecting(e.target.checked)}/>
                            </div>
                            <div className='TablaPendings-CFDI'>
                                <p className='TablaPendings-Txt'>CFDI</p>
                            </div>
                            <div className='TablaPendings-FchFact'>
                                <p className='TablaPendings-Txt'>Fecha facturación</p>
                            </div>
                            <div className='TablaPendings-TotalFact'>
                                <p className='TablaPendings-Txt'>Total</p>
                            </div>
                            <div className='TablaPendings-AbonadoFact'>
                                <p className='TablaPendings-Txt'>Abonado</p>
                            </div>
                            <div className='TablaPendings-SaldoFact'>
                                <p className='TablaPendings-Txt'>Saldo</p>
                            </div>
                        </div>
                        <div className='TablaPendings-Body'>
                            {
                                PendingFacts?.map( (Fact, indxFact) =>{
                                    return(
                                        <div className='TablaPendings-Row'>
                                            <div className='TablaPendings-Chek'>
                                                <input type="checkbox" className='Chek-AllSelects'  onChange={(e)=>SeleccionarFactPend(Fact, e.target.checked)} checked={IdsFactsSelects.includes(Fact.id)}/>
                                            </div>
                                            <div className='TablaPendings-CFDI'>
                                                <p className='TablaPendings-Txt'>{`${Fact.serie} - ${Fact.folio}`}</p>
                                            </div>
                                            <div className='TablaPendings-FchFact'>
                                                <p className='TablaPendings-Txt'>{Fact.fecha}</p>
                                            </div>
                                            <div className='TablaPendings-TotalFact'>
                                                <p className='TablaPendings-Txt'>{formatMoney(Fact.monto)}</p>
                                            </div>
                                            <div className='TablaPendings-AbonadoFact'>
                                                <p className='TablaPendings-Txt'>{formatMoney(Fact.pagado)}</p>
                                            </div>
                                            <div className='TablaPendings-SaldoFact'>
                                                <p className='TablaPendings-Txt'>{formatMoney(Fact.saldo)}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='Bt-PendingFacts'>
                        <Btn
                            type={'accept'}
                            descript={'Aceptar'}
                            clicked={CerrarModal_AbonosPendingFacts}
                        />
                    </div>
                </div>    
        )
    }

    useEffect(() => {
    
        ObtenerMetodPagos()
        ObtenerBancos()
    }, [])
    



  return (
    <LayoutAddAbono>
        {
        MetodosDePago
        ?
            LoadingPendings ?
            
                <AnLoader tittle = {'Generando abono'}></AnLoader>
            :
                <div className='AddAbono'>

                <div className='InAbono-Container'>
                    <div className='Abono-Info'>

                        <div className='In-BuscarClte'>
                            <Inpt
                                type={'text'}
                                tittle={'Buscar cliente'}
                                value={BuscarCliente}
                                setValue={ObtenerClientes}
                            />
                            
                            <div className='list-Clientes' style={FlagSrchCltes ? {} : {display:'none'} }>
                            {
                            ResCltes?.map(Cliente =>{
                                return(
                                <div className='listed-client' style={{fontWeight:'bold', cursor: 'pointer'}} onClick={()=> EstablecerClientes(Cliente)}>{Cliente.nombre}</div>
                                )
                            })}
                            </div>
                        </div>
                        <div className='In-FchPago'>
                            <Inpt
                                type={'date'}
                                tittle={'Fecha de pago'}
                                value={FchPago}
                                setValue={setFchPago}
                            />
                        </div>

                    </div>
                    <div className='Abono-DatosPago'>
                        <div className='In-MetedoPago'>
                            <Inpt
                                type={'select'}
                                tittle={'Método de pago'}
                                value={MetodoPago}
                                setValue={setMetodoPago}
                                opciones={MetodosDePago}
                                defaultOpc={'Seleccionar'}
                            /> 
                        </div>  

                        <div className='In-ImportePago'>
                            <Inpt
                                type={'number'}
                                tittle={'Importe a abonar'}
                                value={ImporteAbonar}
                                setValue={CambiarImporteAbonar}
                                forceInputSty={{textAlign:'right'}}
                            /> 
                        </div>  

                        <div className='In-Banco' style={MetodoPago > 1 ? {} : {display:'none'}}>
                            <Inpt
                                type={'select'}
                                tittle={'Banco'}
                                value={BancoSelected}
                                setValue={setBancoSelected}
                                opciones={Bancos}
                                defaultOpc={'Seleccionar'}
                            />    
                        </div>   

                        <div className='In-Referencia' style={MetodoPago > 1 ? {} : {display:'none'}}>
                            <Inpt
                                type={'text'}
                                tittle={'Referencia'}
                                value={Referencia}
                                setValue={setReferencia}
                            />
                        </div>
                    </div>

                    <div className='Abono-SelectionFacts'>
                        <div className='Bt-SelectFacts'>
                            <Btn
                                type={'custom'}
                                descript={'Seleccionar facturas'}
                                forceBtnSty={{background:'#151b4e'}}
                                clicked={AbrirModal_AbonosPendingFacts}
                            />
                        </div>
                        <p className='Abono-Txt'>{`Facturas Seleccionadas: ${FactsSelects.length}`}</p>
                    </div>
                </div>

                <div className='TablaFacts-Container'>

                    <div className='TablaFacts-Head'>
                        <div className='TablaElmnt-CFDI'>
                            <p className='Tabla-Txt'>CFDI</p>

                        </div>
                        
                        <div className='TablaElmnt-FechaFactura'>
                            <p className='Tabla-Txt'>Fecha Factura</p>

                        </div>
                        
                        <div className='TablaElmnt-ImporteFactura'>
                            <p className='Tabla-Txt'>Importe Factura</p>

                        </div>
                        
                        <div className='TablaElmnt-SaldoFactura'>
                            <p className='Tabla-Txt'>Saldo Factura</p>

                        </div>
                        
                        <div className='TablaElmnt-SaldoAbonar'>
                            <p className='Tabla-Txt'>Saldo a Abonar</p>

                        </div>
                        
                        <div className='TablaElmnt-Accion'>

                        </div>

                    </div>

                </div>
                    
                <div className='TablaFacts-Body'>
                    {
                        FactsSelects?.map( (Fact, indxFact) =>{
                            return(
                                <div className='TablaElmnt-Body'>
                                    <div className='TablaElmnt-CFDI'>
                                        <p className='Tabla-Txt'>{`${Fact.serie} - ${Fact.folio}`}</p>
                                        
                                    </div>
                                    
                                    <div className='TablaElmnt-FechaFactura'>
                                        <p className='Tabla-Txt'>{`${Fact.fecha}`}</p>
                                        
                                    </div>
                                    
                                    <div className='TablaElmnt-ImporteFactura'>
                                        <p className='Tabla-Txt'>{`${formatMoney(Fact.monto)}`}</p>
                                        
                                    </div>
                                    
                                    <div className='TablaElmnt-SaldoFactura'>
                                        <p className='Tabla-Txt'>{`${formatMoney(Fact.saldo)}`}</p>
                                        
                                    </div>
                                    
                                    <div className='TablaElmnt-SaldoAbonar'>
                                        <div className='In-Saldo'>
                                            <Inpt
                                                type={'number'}
                                                value={Fact.total}
                                                setValue={(e)=> CambiarValorAbonar(e, indxFact)}
                                                minVal={0}
                                                maxVal={Fact.saldo}
                                                forceInputSty={{textAlign:'right'}}
                                            />
                                        </div>
                                        
                                    </div>
                                    
                                    <div className='TablaElmnt-Accion'>
                                        <div className='Bt-Accion'>
                                            <Btn
                                                type={'custom'}
                                                descript={Fact.total === 0 ? 'Saldar' : 'Limpiar'}
                                                forceBtnSty={{background:'#151b4e'}}
                                                clicked={Fact.total === 0 ? () => SaldarFactura(Fact, indxFact) : () => LimpiarFactura(indxFact)}
                                                disabled={Fact.total === 0 ? ImportRestant === 0 ? true : false : false}
                                            
                                            />
                                        </div>
                                    </div>
                                    
                                </div>

                            )
                        })
                    }
                        
                </div>

                <div className='Resumen'>

                    <div className='Resum-Container'>
                        <p className='Resum-Txt'>Importe restante</p>
                        <div className='Resum-Info'>
                            {formatMoney(ImportRestant)}
                        </div>
                    </div>
                    
                    <div className='Resum-Container'>
                        <p className='Resum-Txt'>Total a abonar</p>
                        <div className='Resum-Info'>
                            {formatMoney(TotalAbonar)}
                        </div>
                    </div>
                </div>

                <div className='Bt-GenerarAbono'>
                    <Btn
                        type={'accept'}
                        descript={'Aceptar'}
                        clicked={EmitirAbono}
                        disabled={FactsSelects.length < 1}
                    />
                </div>



                    <ModalComponent
                        StatusModal={Modal_AbonosPendingFacts}
                        CerrarModal={CerrarModal_AbonosPendingFacts}
                        Titulo={'Seleccionar facturas pendientes'}
                        Contenido={RenderItemPendingFacts()}
                        ancho={'65%'}
                        alto={'75%'}

                    />
                </div>
        :
            <AnLoader tittle = {'Cargando Abono'}></AnLoader>
        }
    </LayoutAddAbono>
  )
}

export default AddAbono

// type
// clicked
// descript
// forceLayoutSty
// forceBtnSty