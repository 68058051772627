import axios from 'axios'
import { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import moment from 'moment'
import Swal from 'sweetalert2'


export const useReportes = () => {

    
const {User} = useAuth()


const hoyFch = (moment().format('YYYY-MM-DD'))

const [FlagTypeReports, setFlagTypeReports] = useState(false)
const [TiposReportes, setTiposReportes] = useState([])
const [ReportSelected, setReportSelected] = useState(0)
const [FchInicio, setFchInicio] = useState(moment().format('YYYY-MM-01'))
const [FchFin, setFchFin] = useState(moment().format('YYYY-MM-DD'))
const [FlagSrchClte, setFlagSrchClte] = useState()
const [ClienteBusqueda, setClienteBusqueda] = useState()
const [ResCltes, setResCltes] = useState()
const [ClienteSelected, setClienteSelected] = useState()

const ObtenerTiposReportes = async() =>{
    setFlagTypeReports(true)
    let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Tipos/Reportes`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        }
    };

    try {
        let res = await axios(config)
        setTiposReportes(res.data)
        setFlagTypeReports(false)
    } catch (err) {
        console.error(err)
        setFlagTypeReports(false)
    }

}


const ObtenerReporte = () =>{

    if (ReportSelected == 0) {
        Swal.fire({
            title: 'Seleccione un tipo de reporte',
            // text: '',
            icon: 'warning',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })
        
    } else if (ReportSelected == 1) {  // Reporte Movimiento de saldos
        ClienteSelected ?
        DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Creditos/Saldos/Movimientos/Cliente/${ClienteSelected.id}?fechaInicio=${FchInicio}&fechaFin=${FchFin}`)
        :
            Swal.fire({
                title: 'Ingrese un cliente',
                text: 'Para reportes de Movimiento de saldos es necesario seleccionar un cliente',
                icon: 'warning',
                position: 'top',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#52C150'
            })
    } else if (ReportSelected == 2) {  // Reporte Ingresos adicionales
        DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Facturas/Ingresos/Adicionales/?IdCliente=${ClienteSelected ? ClienteSelected.id : 0}?fechaInicio=${FchInicio}&fechaFin=${FchFin}`)
    }else if (ReportSelected == 3) {   // Reporte Complemento de pago
        DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Facturas/Complementos/Pago?idCliente=${ClienteSelected ? ClienteSelected.id : 0}?fechaInicio=${FchInicio}&fechaFin=${FchFin}`)
    }else if (ReportSelected == 4) {   // Reporte Saldos de credito
        DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Creditos/Saldos/Clientes/?IdCliente=${ClienteSelected ? ClienteSelected.id : 0}?fechaInicio=${FchInicio}&fechaFin=${FchFin}`)
    }

}

const DownLoadReport = async(url) =>{
    try { let res = await axios.get(url)
        
        if (res.status === 204) {
            Swal.fire({
                title: 'No fue posible descargar el reporte',
                text: 'No se encontraron registros en ese rango de fechas',
                icon: 'warning',
                position: 'top',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#52C150'
            })

        }else{
            const link = document.createElement('a');
            link.href = url;
            link.download = "datos.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    } catch (err) {
        Swal.fire({
            title: 'Error al descargar reporte',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })   
    }
}

const EstablecerClientes = (data) =>{
    setClienteBusqueda(data.nombre)
    setClienteSelected(data)
    setFlagSrchClte(false)
}

const ObtenerClientes = async(data) =>{
    setClienteBusqueda(data)
    if (data.length >= 3) {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=0&Filtro=1&Buscar=${data}&Pagina=1&Registros=10`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
      };
      await axios(config)
      .then( (res) =>{
        setResCltes(res.data.resultado)
        setFlagSrchClte(true)
      })
      .catch( (err) =>{
        console.log(err)
      })
    }else{
      setFlagSrchClte(false)
    }
  }


// const GetReport = async() =>{

    // if (SlctdReport.id === 0) {
        // Swal.fire({
        //     title: 'Seleccione un tipo de reporte',
        //     // text: '',
        //     icon: 'warning',
        //     position: 'top',
        //     confirmButtonText: 'Aceptar',
        //     confirmButtonColor: '#52C150'
        // })
    //    }else if (SlctdReport.id === 1) { //Movimiento de saldos
            // SlctedClient ?
            // DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Creditos/Saldos/Movimientos/Cliente/${SlctedClient.id}?fechaInicio=${Fecha_I}&fechaFin=${Fecha_F}`)
            // :
            // Swal.fire({
            //     title: 'Ingrese un cliente',
            //     text: 'Para reportes de Movimiento de saldos es necesario seleccionar un cliente',
            //     icon: 'warning',
            //     position: 'top',
            //     confirmButtonText: 'Aceptar',
            //     confirmButtonColor: '#52C150'
            // })
    //    }else if (SlctdReport.id === 2) { //Ingresos adicionales
            // DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Facturas/Ingresos/Adicionales?IdCliente=${SlctedClient ? SlctedClient.id : 0}&FechaInicio=${Fecha_I}&FechaFin=${Fecha_F}`)
    //    }else if (SlctdReport.id === 3) { //Complemento de pago
            // DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Facturas/Complementos/Pago?idCliente=${SlctedClient ? SlctedClient.id : 0}&fechaInicio=${Fecha_I}&fechaFin=${Fecha_F}`)
    //    }else if (SlctdReport.id === 4) { //Saldos de credito
            // DownLoadReport(`${process.env.REACT_APP_API_REPORTES_URL}Creditos/Saldos/Clientes/?idCliente=${SlctedClient ? SlctedClient.id : 0}&FechaInicio=${Fecha_I}&FechaFin=${Fecha_F}&interno=true`)
    //    }else{
        // Swal.fire({
        //     title: 'Error inesperado',
        //     text: 'resultado inesperado, contacte con el soporte del sistema',
        //     icon: 'error',
        //     position: 'top',
        //     confirmButtonText: 'Aceptar',
        //     confirmButtonColor: '#52C150'
        // })
    //    }
// }


  return {
    TiposReportes, setTiposReportes, ReportSelected, setReportSelected, ObtenerTiposReportes,
    FchInicio, setFchInicio, FchFin, setFchFin, ClienteBusqueda, setClienteBusqueda, hoyFch,
    ObtenerReporte, EstablecerClientes, FlagSrchClte, setFlagSrchClte, ResCltes, ObtenerClientes,
    FlagTypeReports
  }
}


