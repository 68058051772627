import React, {useState} from 'react'

const useMode =() => {
    const [Darkmode, setDarkmode] = useState(false);
    const [ColorMode, setColorMode] = useState("#2F3768")
    const [BodyLightMode, setBodyLightMode] = useState("#EFF0F2")
    const [GrisFuerteLight, setGrisFuerteLight] = useState("#D9D9D9")
    const [Blanco, setBlanco] = useState("#fff")

    // COLORS DASHBOARD
    const [AzulFuerteLight, setAzulFuerteLight] = useState("#151F56");
    const [AzulBajoLight, setAzulBajoLight] = useState("#63698D")

    const HanlderActiveDarkMode = (data) =>{
        setDarkmode(data)
    }
  return {
    Darkmode,
    HanlderActiveDarkMode,
    ColorMode,
    BodyLightMode,
    AzulFuerteLight,
    AzulBajoLight,
    GrisFuerteLight,
    Blanco
  }
}

export default useMode