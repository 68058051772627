import React, { useEffect, useState } from 'react'

import AnLoader from '../../utils/components/Preloader/AnLoader'
import Paginacion from '../../utils/components/Paginacion/Paginacion'
import Inpt from '../../utils/components/input/Inpt'
import lupa from '../../assets/SVGs/Lupa.svg'
import TablaPaginador from '../../utils/components/TablaPaginador/TablaPaginador'

import Btn from '../../utils/components/boton/Btn'
import ModalComponent from '../../utils/components/Modal/Modal'
import { useAuth } from '../../context/AuthContext'
import { useFacturacion } from './hook/useFacturacion'
import ReCorreo from '../../assets/SVGs/ReCorreo.svg'
import VerDoc from '../../assets/SVGs/watchDoc.svg'
import Cruz from '../../assets/SVGs/Cruz.svg'
import Equis from '../../assets/SVGs/x_deleting.svg'
import moment, { isDate } from 'moment'

const Facturas = () => {


  const {Autorized, UserData} = useAuth()
  const {
    Facturas_ProPrevPg, Facturas_PrevPg, Facturas_NextPg, Facturas_ProNextPg,
    InfoTab, Loading, ObtenerFacturas, Pg, MaxPg, RegsPg, ChangeRegs, NavAlter,formatMoney,
    PagsFltr_TipoFactura, PagsFltr_TipoPago, PagsFltr_Estatus, PagsFltr_FchInicio, PagsFltr_FchFinal,
    setPagsFltr_TipoFactura, setPagsFltr_TipoPago, setPagsFltr_Estatus, setPagsFltr_FchInicio, setPagsFltr_FchFinal, CerrarModal_ReCorreo,
    BuscarFacturas, Modal_FacturasReCorreo, AbrirModalReCorreo, CloseModal_FacturasReCorreo, ReCorreoData, DestinoCorreo, setDestinoCorreo, B64, setB64,
    Modal_FacturasVerPDF, CloseModal_FacturasVerPDF, AbrirModalVerPDF, ReenviarCorreo, CancelarFactura, FlagSendingMail,
    Modal_FacturasCancelarFact, CloseModal_FacturasCancelarFact, AbrirModalCancelarFactura, CancelarFactData, Activedots, setActivedots,  ActivarDots,
    RetimbrarFactura
  } =useFacturacion()

  

const DotsItems = (estatus) =>{
  if ((Autorized('Facturas', 'ConsultarPDF') || Autorized('Facturas', 'EnviarCorreo') || Autorized('Facturas', 'Cancelar')) && estatus < 2) {
    return(true)
  }else{
    return(false)
  }
}


  const RenderModalReCorreo = () =>{
    return(
      FlagSendingMail ?
      
        <AnLoader style={{left:'35%'}} tittle = {'Enviando Correo'}/>
      :
      
        <div className='ReCorreo-container'>
          <div className='ReCorreo-infos'>

            <div className='ReCorreo-DestinoInfo' style={{marginTop:'10px'}}>
              <Inpt
                type={'text'}
                tittle={'Destino'}
                value={DestinoCorreo}
                setValue={setDestinoCorreo}
                placehold={ReCorreoData?.correoCliente}

              
              />
            </div>
            
            <div className='ReCorreo-InfoFact'>
              <p className='txt-ReCorreo'>Cliente:</p>
              <p className='txt-ReCorreo'>{ReCorreoData?.nombreCliente}</p>
            </div>
            
            <div className='ReCorreo-InfoFact'>
              <p className='txt-ReCorreo'>CFDI Factura:</p>
              <p className='txt-ReCorreo'>{`${ReCorreoData?.serie} - ${ReCorreoData?.folio}`}</p>
            </div>

          </div>

          
          <div className='btn-ReCorreo'>
            
            <Btn
              type={'accept'}
              descript={'Aceptar'} 
              clicked={()=>ReenviarCorreo(ReCorreoData, 0)}
            />
          </div>
        </div>
    )
  }
  
  const RenderModalVerPDF = () =>{
    return(
      
    <div className='ReCorreo-container'>
      <div className='ReCorreo-infos'>
        <object type='application/pdf' width='100%' height='100%' name='testeo' data={B64}></object>        
      </div>
    </div>
    )
  }

  const RenderModalCancelarFact = () =>{
    return(
      
    <div className='CancelFact-container'>
      <div className='CancelFact-infos'>
        
        <div className='CancelFact-InfoFact'>
          <p className='txt-CancelFact'>Cliente:</p>
          <p className='txt-CancelFact'>{CancelarFactData?.nombreCliente}</p>
        </div>
        
        <div className='CancelFact-InfoFact'>
          <p className='txt-CancelFact'>CFDI Factura:</p>
          <p className='txt-CancelFact'>{`${CancelarFactData?.serie} - ${CancelarFactData?.folio}`}</p>
        </div>

        <div className='CancelFact-InfoFact'>
          <p className='txt-CancelFact'>Cliente:</p>
          <p className='txt-CancelFact'>{formatMoney(CancelarFactData?.monto)}</p>
        </div>
      </div>

      
      <div className='btn-CancelFact'>
        
        <Btn
          type={'accept'}
          descript={'Aceptar'} 
          clicked={()=>CancelarFactura()}
        />
      </div>
    </div>
    )
  }

  const DotsMenu = (indx) =>{
    if (InfoTab.length > 7) {
      if (indx > InfoTab.length - 4) {
        return('dots-menu-inverted')
      }else{
        return('dots-menu')
      }
    }
  }


// indx < 7 ? 'dots-menu' : 'dots-menu-inverted'

      //   ActiveDots === Clients.id
      //     ?
      //       (ResData.length > 7 ? indx > ResData.length - 5 : false)

      //       ? { display: "block", bottom: "36px" } //Se despliega el submenu hacia arriba
      //       : { display: "block", top: "36px" } //Se despliega en submenu hacia abajo

      //     : { display: "none" }








  useEffect(() => {
    ObtenerFacturas()

  }, [])

  const CheckStatusFact_Names = (statu) =>{
            switch (statu) {
              case 0:
                return('Generada');

              case 1:
                return('Timbrada');

              case 2:
                return('Pendiente');

              case 3:
                return('Cancelada');

              default:
                break;
            }
  }

  const CheckStatusFact_Styles = (statu) =>{
    switch (statu) {
      case 0:
        return({background: '#09a306', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px', cursor: 'pointer'});

      case 1:
        return({background: '#4ce549', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px'});

      case 2:
        return({background: '#e7b424', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px'});

      case 3:
        return({background: '#f85858', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px'});

      default:
        break;
    }
}




  const RenderItemCabezeraTablaPaginador = () => {
    return (
      <>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>ID</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
            <p>Usuario</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>Creación</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 8 }}>
            <p>Cliente</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
            <p>Tipo Factura</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>Timbrado</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
            <p>CFDI</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>Estatus</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>Total</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p></p>
        </div>
      </>
    )
  }
  const RenderItemCuerpoTablaPaginador = () => {
    return (
      <div style={{ background: '#fff' }}>
        <>
          {
            InfoTab?.map((res, indx) => {
              return (
                <>
                  <div className='Cuerpo-TablaSinPaginador' style={{position:'relative'}}>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                        <p className='Texto-Tabla'>{res.id}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 4, textAlign:'center' }}>
                        <p className='Texto-Tabla'>{res.nombreCliente}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                        <p className='Texto-Tabla'>{res.fechaCreacion}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 8, textAlign:'center' }}>
                        <p className='Texto-Tabla'>{res.nombreCliente}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 4 }}>
                        <p className='Texto-Tabla'>{res.tipoFactura}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                        <p className='Texto-Tabla'>{res.fechaTimbrado}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 4 }}>
                        <p className='Texto-Tabla'>{res.serie} - {res.folio} </p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                        <p className='Texto-Tabla' title={res.estatus === 0 ? 'Retimbrar' : ''} onClick={()=>RetimbrarFactura(res)} style={CheckStatusFact_Styles(res.estatus)}
                        >
                          {CheckStatusFact_Names(res.estatus)}
                        </p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                        <p className='Texto-Tabla' style={{textAlign:'center'}}>{formatMoney(res.monto)}</p>
                    </div>

                    <div className='ItemCuerpo-AccionesPaginador' style={Autorized('Facturas', 'Paginador') ? {flex:'1'} : { flex:'1', opacity:'0'}} onClick={()=>ActivarDots(res.id)}>

                        <div className={Activedots === res.id ? 'cont-dots-active' : 'cont-dots'} style={DotsItems(res.estatus) ? {} : {display:'none',cursor:'pointer'}}>
                          <span className='dotAction' style={{ background: '#9597A7' }}></span>
                          <span className='dotAction' style={{ background: '#9597A7' }}></span>
                          <span className='dotAction' style={{ background: '#9597A7' }}></span>
                        </div>
                        
                        
                        <ul className={ (( InfoTab.length < 8 ) || ((InfoTab.length > 8) && (indx < InfoTab.length - 2))) ? 'dots-menu' : 'dots-menu-inverted'} style={Activedots === res.id && DotsItems(res.estatus)  ? {} : {display:'none'}}>
                          
                          <li className='dm-row' style={Autorized('Facturas', 'ConsultarPDF') && DotsItems(res.estatus) ? {} : {display:'none'}} onClick={()=>AbrirModalVerPDF(res)} >
                            <div className='cont-rowIcon'>
                              <img className='row-icon' src={VerDoc} alt="" />
                            </div>
                            <p className='row-txt' >Ver PDF</p> 
                          </li>

                          
                          <li className='dm-row' style={Autorized('Facturas', 'EnviarCorreo') && DotsItems(res.estatus) ? {} : {display:'none'}} onClick={()=>AbrirModalReCorreo(res)} >
                            <div className='cont-rowIcon'>
                              <img className='row-icon' src={ReCorreo} alt="" />
                            </div>
                            <p className='row-txt' >Reenviar correo</p> 
                          </li>

                          
                          <li className='dm-row' style={Autorized('Facturas', 'Cancelar') && DotsItems(res.estatus) ? {} : {display:'none'}} onClick={()=>AbrirModalCancelarFactura(res)} >
                            <div className='cont-rowIcon' style={{background: '#f96262'}}>
                              <img className='row-icon' src={Equis} alt="" />
                            </div>
                            <p className='row-txt' >Cancelar</p> 
                          </li>

                        </ul>

                    </div>

                  </div>
                </>
              )
            })
          }
        </>
      </div>

    )
  }

  return (
    Loading ?
    <AnLoader tittle = {CancelarFactData ? 'Cancelando factura' : 'Cargando facturas'}></AnLoader>
    :
    
    <div className='Seccion-Area'>

    <div className='herramientas'>
      <div className='filtros'>

        <div className='fchCreacion'>

          <Inpt
            type={'date'}
            tittle={'Fecha Inicio'}
            value={PagsFltr_FchInicio}
            setValue={setPagsFltr_FchInicio}

          ></Inpt>

        </div>

        <div className='fchTimbrado'>
          
          <Inpt
            type={'date'}
            tittle={'Fecha Fin'}
            value={PagsFltr_FchFinal}
            setValue={setPagsFltr_FchFinal}

          ></Inpt>

        </div>
        
        <div className='tipoFactura'>

          <Inpt
            type={'select'}
            opciones={[
              { "key": 0, "description" : 'Todas'},
              { "key": 1, "description" : 'Comisiones UPS'},
              { "key": 2, "description" : 'Venta de Activos'},
              { "key": 3, "description" : 'Cobro de Uniformes'},
              { "key": 4, "description" : 'Ingresos Adicionales'},
              { "key": 5, "description" : 'Energia electrica'},
            ]}
            tittle={'Tipo Factura'}
            value={PagsFltr_TipoFactura}
            setValue={setPagsFltr_TipoFactura}

          ></Inpt>
        </div>

        <div className='estatus'>

          <Inpt
            type={'select'}
            opciones={[
              { "key": 0, "description" : 'Todas'},
              { "key": 1, "description" : 'Generadas'},
              { "key": 2, "description" : 'Timbradas'},
              { "key": 3, "description" : 'Pend. Cancelar'},
              { "key": 4, "description" : 'Canceladas'},
            ]}
            tittle={'Estatus'}
            value={PagsFltr_Estatus}
            setValue={setPagsFltr_Estatus}

          ></Inpt>
            

        </div>

        <div className='metodoPago'>
        
        <Inpt
          type={'Método Pago'}
          opciones={[
            { "key": 0, "description" : 'Todos'},
            { "key": 1, "description" : 'PUE'},
            { "key": 2, "description" : 'PPD'},
          ]}
          tittle={'Método Pago'}
          value={PagsFltr_TipoPago}
          setValue={setPagsFltr_TipoPago}

        ></Inpt>

        </div>

        <div className='BotonBuscar'>
          <Btn
            type={'iconoMini'}
            imagen={lupa}
            forceBtnSty={{background:'#3BAF29'}}
            clicked={BuscarFacturas}
          
          />
        </div>



      </div>
      
      <div className='agregar' style={Autorized('Facturas', 'Agregar') ? {} : {display:'none'}}>
          <Btn
            type={'add'}
            imagen={Cruz}
            clicked={NavAlter}
          />
      </div>
    </div>

    <div className='tabla'>
      {
        Autorized('Facturas', 'Paginador')
        ?

          <TablaPaginador
            Cabezera={RenderItemCabezeraTablaPaginador}
            Cuerpo={RenderItemCuerpoTablaPaginador}
          />
          
        :
          <p>No autorizado</p>
      }
    </div>

    <div className='paginacion'>

      <Paginacion
        RegsPg={RegsPg}
        setRegsPg={ChangeRegs}

        Pg={Pg}
        MaxPg={MaxPg}
        NextPg={Facturas_NextPg}
        PrevPg={Facturas_PrevPg}
        ProNextPg={Facturas_ProNextPg}
        ProPrevPg={Facturas_ProPrevPg}
      />
      
    </div>
    
    <ModalComponent
      StatusModal={Modal_FacturasReCorreo}
      CerrarModal={CerrarModal_ReCorreo}
      Titulo={`Reenviar Correo`}
      Contenido={RenderModalReCorreo()}
      ancho={'40%'}
      alto={'45%'}

    />

    <ModalComponent
      StatusModal={Modal_FacturasVerPDF}
      CerrarModal={CloseModal_FacturasVerPDF}
      Titulo={`PDF Factura`}
      Contenido={RenderModalVerPDF()}
      ancho={'80%'}
      alto={'90%'}

    />
    
    <ModalComponent
      StatusModal={Modal_FacturasCancelarFact}
      CerrarModal={CloseModal_FacturasCancelarFact}
      Titulo={`Cancelar Factura`}
      Contenido={RenderModalCancelarFact()}
      ancho={'40%'}
      alto={'45%'}

    />
    

    </div>
  )
}

export default Facturas


{/* <ModalComponent
StatusModal={Modal_ClienteStatus}
CerrarModal={CloseModal_ClienteStatus}
Titulo={`${ClteStatusData?.estatus === true ? 'Deshabilitar cliente' : 'Rehablitar cliente'}`}
Contenido={RenderItemModalCambiarEstatus()}
ancho={'35%'}
alto={'35%'}

/> */}