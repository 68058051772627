import React, { useEffect } from 'react'
import { LayoutReporteador } from '../../themes/layout'
import { useReportes } from './hook/useReportes'
import AnLoader from '../../utils/components/Preloader/AnLoader'
import Inpt from '../../utils/components/input/Inpt'
import Btn from '../../utils/components/boton/Btn'

const Reportes = () => {

    const {
        TiposReportes, setTiposReportes, ReportSelected, setReportSelected, ObtenerTiposReportes,
        FchInicio, setFchInicio, FchFin, setFchFin, ClienteBusqueda, setClienteBusqueda, hoyFch,
        ObtenerReporte, EstablecerClientes, FlagSrchClte, setFlagSrchClte, ResCltes, ObtenerClientes,
        FlagTypeReports
    } = useReportes()
    

    useEffect(() => {
        ObtenerTiposReportes()
    }, [])
    

  return (
    <LayoutReporteador>
        {
            
            FlagTypeReports ?
            <AnLoader tittle = {'Cargando abonos'}></AnLoader>
            :
            
        <div className='Contenedor-Reportes'>
        <div className='Titluo-Reportes'>
            <div className='Titulo-Cinta'>
                <h1 className='Titulo-Texto'>Generar reportes por tipo de factura</h1>
            </div>
        </div>

        <div className='Filtros-Reportes'>
            <div className='Filtros-Default'>

                <div className='Tipo-Reporte'>
                    <Inpt
                        type={'select'}
                        tittle={'Tipo de reporte'}
                        value={ReportSelected}
                        setValue={setReportSelected}
                        opciones={TiposReportes}
                        defaultOpc={'Seleccionar'}
                    />
                </div>

                <div className='Fechas-Reporte'>

                    <div className='Reporte-FchInicio'>
                        <Inpt
                            type={'date'}
                            tittle={'Fecha Inicio'}
                            value={FchInicio}
                            setValue={setFchInicio}
                            maxVal={hoyFch}
                        />
                    </div>

                    <div className='Reporte-FchFin'>
                        <Inpt
                            type={'date'}
                            tittle={'Fecha Fin'}
                            value={FchFin}
                            setValue={setFchFin}
                            maxVal={hoyFch}
                        />
                    </div>
                </div>

            </div>

            <div className='Separador-Linea'></div>

            <div className='Filtros-Custom'>
            <div>
                <Inpt
                    type={'text'}
                    tittle={'Buscar cliente'}
                    value={ClienteBusqueda}
                    setValue={ObtenerClientes}
                    placehold={(ReportSelected == 1 ? 'Nombre del cliente' : 'Todos los clientes')}
                />
                
                <div className='list-Clientes' style={FlagSrchClte ? {} : {display:'none'} }>
                  {
                  ResCltes?.map(Cliente =>{
                    return(
                      <div className='listed-client' style={{fontWeight:'bold', cursor: 'pointer'}} onClick={()=> EstablecerClientes(Cliente)}>{Cliente.nombre}</div>
                    )
                  })}
                </div>
            </div>

            </div>
        </div>

        <div className='Generar-Reportes'>
            <div className='Boton-Generar'>
                <Btn
                    type={'accept'}
                    descript={'Generar'}
                    clicked={ObtenerReporte}
                />
            </div>
        </div>
    </div>
        }
    </LayoutReporteador>
  )
}

export default Reportes