import React, { useEffect, useState } from 'react'
import { LayoutAlterProductos } from '../../themes/layout'
import { usePaginacion } from '../../utils/components/Paginacion/hook/usePaginacion'
import { useProductos } from './hook/useProductos'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import TablaPaginador from '../../utils/components/TablaPaginador/TablaPaginador'
import Btn from '../../utils/components/boton/Btn'
import Inpt from '../../utils/components/input/Inpt'


export const AlterProducto = () => {
    
    const {alterprod} = useParams()
    const {state} = useLocation()
    
    const {
        Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setRegsPg, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, ObtenerClvesUnidSat,
        ObtenerClvesProdSat, BuscarClveSat, ClveProdSAT, ClveUnidSAT, DescriptSAT, setBuscarClveSat, setClveProdSAT, setClveUnidSAT, setDescriptSAT, EstablecerClaveProd,
        ResClvesUnidad, FlagClvesUnid, EstablecerClaveUnid, AgregarProducto, EditarProducto, GetUnitKey, NavBack
    } = useProductos()




    const RenderItemCabezeraListaPaginador = () => {
    return (
        <>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p>Clave</p>
        </div>
        
        <div className='ItemHeader-TablaPaginador' style={{ flex: 7}}>
            <p>Descripción SAT</p>
        </div>
        
        </>
    )
    }
    const RenderItemCuerpoListaPaginador = () => {

        useEffect(() => {
        ObtenerClvesProdSat()
        }, [])

        return (
            <div style={{ background: '#fff' }}>
            <>
                {
                InfoTab?.map(res => {
                    return (
                    <>
                        <div className='Cuerpo-TablaSinPaginador' style={{cursor:'pointer'}} onClick={()=> EstablecerClaveProd(res)}>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                                <p className='Texto-Tabla'>{res.clave}</p>
                            </div>
                            
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 6, textAlign:'center' }}>
                                <p className='Texto-Tabla'>{res.descripcion}</p>
                            </div>
                        
                        </div>
                    </>
                    )
                })
                }
            </>
            </div>

        )
    }
    
    
    useEffect(() => {
        if (state) {
            setClveProdSAT(state.claveProducto)
            setClveUnidSAT(state.claveUnidad)
            setDescriptSAT(state.descripcion)
            GetUnitKey(state.claveUnidad)
        }
    }, [])

    const handleEditarProducto = () =>{
        EditarProducto(state)
    }

  return (
    <LayoutAlterProductos>
        <div className='AlterProducto'>
            <div className='contsProds'>
                <div className='listadoProductos'>

                <div className='cont-enlacepkt1'>
                    <div className='InNomProd'>
                        <Inpt
                            type={'text'}
                            tittle={'Buscar producto'}
                            placehold={'Busqueda por Clave o Nombre'}
                            value={BuscarClveSat}
                            setValue={ObtenerClvesProdSat}
                        />
                    </div>

                    <div className='listaProds'>
                        <TablaPaginador
                            Cabezera={RenderItemCabezeraListaPaginador}
                            Cuerpo={RenderItemCuerpoListaPaginador}
                        />
                    </div>
                </div>


                </div>

                <div className='datosProds'>

                <div className='inptsDatosProds'>
                    <div className='claveProductoSat'style={{ marginBottom:'20px'}}>
                        <Inpt
                            type={'text'}
                            tittle={'Clave de producto SAT'}
                            placehold={'Selecione un producto de la lista'}
                            value={ClveProdSAT}
                            setValue={setClveProdSAT}
                            disabled={true}
                        />
                    </div>

                    <div className='claveUnidadSat' style={{position:'relative', marginBottom:'20px'}}>
                        <Inpt
                            type={'text'}
                            tittle={'Clave de unidad SAT'}
                            placehold={'EScribe para buscar clave de unidad'}
                            value={ClveUnidSAT}
                            setValue={ObtenerClvesUnidSat}
                        />
                        
                        <div className='list-clvsUnidad' style={FlagClvesUnid ? {} : {display:'none'} }>
                            {
                                ResClvesUnidad?.map(Clve =>{
                                    return(
                                        <div className='listed-clve' style={{fontWeight:'bold', cursor: 'pointer'}} onClick={()=> EstablecerClaveUnid(Clve)}>{Clve.key} - {Clve.name}</div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='descripcionSat'style={{ marginBottom:'20px'}}>
                        <Inpt
                            type={'text'}
                            tittle={'Descripción'}
                            placehold={''}
                            value={DescriptSAT}
                            setValue={setDescriptSAT}
                        />
                    </div>
                </div>


                <div className='Btns-Submit' style={{width:'100%', margin:'0 auto', display:'flex', justifyContent:'center'}}>  

                    <Btn
                        type={'accept'}
                        descript={'Guardar'}
                        clicked={alterprod === 'Editar' ? handleEditarProducto : AgregarProducto}
                    />
                </div>

                </div>
            </div>

        </div>
    </LayoutAlterProductos>
  )
}
