import { useState } from 'react'
import { usePaginacion } from '../../../utils/components/Paginacion/hook/usePaginacion'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../../context/AuthContext'
import Swal from 'sweetalert2'
import useModal from '../../../utils/components/Modal/hook/useModal'



export const useFacturacion = () => {
  const {UserData, User} = useAuth()
  const {Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setLoading, setRegsPg, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg } = usePaginacion()
  const { Modal_FacturasReCorreo, OpenModal_FacturasReCorreo, CloseModal_FacturasReCorreo,
          Modal_FacturasVerPDF, OpenModal_FacturasVerPDF, CloseModal_FacturasVerPDF,
          Modal_FacturasCancelarFact, OpenModal_FacturasCancelarFact, CloseModal_FacturasCancelarFact
        } = useModal()

  const navigate = useNavigate()
  const hoyFch = (moment().format('YYYY-MM-DD'))

  // ESTADOS USADOS EN EL PAGINADOR DE FACTURAS
  const [PagsFltr_TipoFactura, setPagsFltr_TipoFactura] = useState(0)
  const [PagsFltr_TipoPago, setPagsFltr_TipoPago] = useState(0)
  const [PagsFltr_Estatus, setPagsFltr_Estatus] = useState(0)
  const [PagsFltr_FchInicio, setPagsFltr_FchInicio] = useState(moment().format('YYYY-MM-01'))
  const [PagsFltr_FchFinal, setPagsFltr_FchFinal] = useState(moment().format('YYYY-MM-DD'))
  const [DataCFDIs, setDataCFDIs] = useState([])
  

  
  // ESTADOS USADOS EN DATOS PARA CREAR FACTURA
  const [Fltr_TypeFact, setFltr_TypeFact] = useState(0)
  const [FormaPago, setFormaPago] = useState('03')
  const [MetodoPago, setMetodoPago] = useState('PUE')
  const [TipoMoneda, setTipoMoneda] = useState('MXN')
  const [Cliente, setCliente] = useState()
  const [ClienteID, setClienteID] = useState()
  const [rfc, setrfc] = useState()
  const [usocfdi, setusocfdi] = useState()
  const [Observaciones, setObservaciones] = useState('')

  //ESTADOS USADOS PARA EL MANEJO DE PRODUCTOS PARA CREAR UNA FACTURA FIJA (UPS O CFE)
  
  const [Cantidad, setCantidad] = useState(0)
  const [UnidadSAT, setUnidadSAT] = useState()
  const [ClaveSAT, setClaveSAT] = useState()
  const [DescriptSAT, setDescriptSAT] = useState()
  const [ValUnit, setValUnit] = useState(0)
  const [Descuent, setDescuent] = useState(0)
  const [SubTotal, setSubTotal] = useState(0)
  

  //ESTADOS USADOS PARA MOSTRAR LA INFORMACION DEL RESUMEN DE LA FACTURA

  const [ResumsSubtotal, setResumsSubtotal] = useState(0)
  const [ResumsDescuento, setResumsDescuento] = useState(0)
  const [ResumsSubDesc, setResumsSubDesc] = useState(0)
  const [ResumsImpuestos, setResumsImpuestos] = useState(0)
  const [ResumsTotal, setResumsTotal] = useState(0)

  //ESTADO PARA EL PRELOADER 
  const [FacturandoPreloader, setFacturandoPreloader] = useState()

  //ESTADOS PARA EL MANEJO DEL MODAL DE PRODUCTOS EN FACTURA

  const [RowInfo, setRowInfo] = useState()
  const [RowsProds, setRowsProds] = useState([])
  const [ResProds, setResProds] = useState()

  const [idProductProds, setidProductProds] = useState()
  const [ClveProds, setClveProds] = useState()
  const [UnidNameProds, setUnidNameProds] = useState()
  const [CantidadProds, setCantidadProds] = useState(1)
  const [ValUnitProds, setValUnitProds] = useState()
  const [DescriptProds, setDescriptProds] = useState('')
  const [FlagSrchProds, setFlagSrchProds] = useState(false)
  const [FlagIndxProd, setFlagIndxProd] = useState()

  const [FlagSrchCltes, setFlagSrchCltes] = useState(false)
  const [ResCltes, setResCltes] = useState()  

  //ESTADOS PARA REENVIAR CORREOS
  const [CancelarFactData, setCancelarFactData] = useState()
  const [ReCorreoData, setReCorreoData] = useState()
  const [DestinoCorreo, setDestinoCorreo] = useState()
  const [B64, setB64] = useState()

  const [FlagSendingMail, setFlagSendingMail] = useState(false)



  //ESTADOS DE UTILIDAD EN AGREGAR FACTURA

  const [TiposFacturas, setTiposFacturas] = useState([])
  
  const [Activedots, setActivedots] = useState('')
  const ActivarDots = (dato) =>{
    if (dato === Activedots) {
      setActivedots('')
    }else{  
      setActivedots(dato)
    }
  }




  const AsignarFormaPago = (data) =>{
    setFormaPago(data)

    if (data === '99') {
      setMetodoPago('PPD')
    }else{
      setMetodoPago('PUE')
    }
  }

  const AsignarMetodoPago = (data) =>{
    setMetodoPago(data)

    if (data === 'PPD') {
      setFormaPago('99')
    }else{
      setFormaPago('03')
    }
  }



  const AbrirModalReCorreo = (data) =>{
    OpenModal_FacturasReCorreo()
    setReCorreoData(data)

  }
  const AbrirModalVerPDF = async(data) =>{
    //https://pkt1internodesarrollo.paq1.com.mx/Api/V3/Facturacion/PDF/CLMMAFDD/39
      await axios.get(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/PDF/${data.serie}/${data.folio}`,{
        headers: {
          'Authorization': 'Bearer ' + User.token
        }
      })
    .then( res => {


      // investigar como agregandole el nombre al pdf (para no mostrar nombre en base 64)


      
        setB64('data:application/pdf;base64,' + res.data.data) //cambiar para despues res.data.data
        OpenModal_FacturasVerPDF(true)
    })
    .catch(err =>{
      console.log(err)
      
      if (err.response.status === 401 ) {
        Swal.fire({
            title: 'Error al reenviar correo',
            text: `La sesión ha caducado`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })
      }else{
          Swal.fire({
              title: 'Error al reenviar correo',
              text: `${err.response.data.message}`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
      }

    })
  }
  const AbrirModalCancelarFactura = async(data) =>{
    OpenModal_FacturasCancelarFact()
    setCancelarFactData(data)
  }
  
  const CerrarModal_ReCorreo = () =>{
    setDestinoCorreo('')
    CloseModal_FacturasReCorreo()
  }

  const ReenviarCorreo = async(data, Tipo) =>{
    setFlagSendingMail (true)
    await  axios.get(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Correo/Enviar/${data.serie}/${data.folio}?Tipo=${Tipo}&Correos=${DestinoCorreo}`, {
        headers: {
            'Authorization': 'Bearer ' + User.token
        }
    })
    .then(res => { 
      
      Swal.fire({
        title: 'Correo Enviado',
        // text: `${err.response.data.message}`,
        icon: 'success',
        position: 'top',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
    })
    
    setFlagSendingMail (false)
      CloseModal_FacturasReCorreo()
    })
    .catch(err => { 
      setFlagSendingMail (false)
        if (err.response.status === 401 ) {
          Swal.fire({
              title: 'Error al reenviar correo',
              text: `${err.response.data.message}`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
          })
        }else{
            Swal.fire({
                title: 'Error al reenviar correo',
                text: `${err.response.data.message}`,
                icon: 'error',
                position: 'top',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#52C150'
            })
        }
    })  
    
  }
  const GetUnitKey = async(data) =>{
    await axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetUnitKeysAll?search=${data}`)
    .then(res =>{
        setUnidNameProds(`${res.data[0].key} - ${res.data[0].name}`)
    })
    .catch( err =>{
        console.log(err)
    })
  }

  const ObtenerProductos = async(data) =>{
    setDescriptProds(data)
    
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${data}&Filtro=1&Pagina=1&RegistrosPorPagina=20`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + User.token
      },
    };
    
    await axios(config)
    .then( (res) =>{
      setResProds(res.data.resultado)
      setFlagSrchProds(true)
    })
    
    .catch( (err) =>{
      console.log(err)
    })
  }
  const EstablecerProds = (data) =>{ 
    GetUnitKey(data.claveUnidad)
    setDescriptProds(data.descripcion)
    setUnidNameProds(data.claveUnidad)
    setClveProds(data.claveProducto)

  }

  const ObtenerClientes = async(data) =>{
    setCliente(data)
    if (data.length >= 3) {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Paginador?TipoCliente=0&Filtro=1&Buscar=${data}&Pagina=1&Registros=10`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
      };
      await axios(config)
      .then( (res) =>{
        setResCltes(res.data.resultado)
        setFlagSrchCltes(true)
      })
      .catch( (err) =>{
        console.log(err)
      })
    }else{
      setFlagSrchCltes(false)
    }
  }
  const EstablecerClientes = (data) =>{ 
    setCliente(data.nombre)
    setrfc(data.rfc)
    setClienteID(data.id)
    setusocfdi(data.usocfdi)
    setFlagSrchCltes(false)

  }

  

  const handleSelectTypeFact = (tipoFact) =>{
    let factutype = parseInt(tipoFact)

    if ( factutype === 1) {
      setCantidad(1)
      setCliente('UNITED PARCEL SERVICE DE MEXICO')
      setrfc('UPS891122HV8')
      setusocfdi('G03')
      setUnidadSAT('E48 - Unidad de servicio')
      setClaveSAT('80141600')
      setDescriptSAT('Actividades de ventas y promoción de negocios')
      handleTotalito(0)
    }else{
      setCliente('')
      setrfc('')

      let previototalito = 0
      RowsProds?.map(res=>{ 
        previototalito += res.totalsat
      })
      handleTotalito(previototalito)
    }
    setFltr_TypeFact(factutype)
  }


  const CrearRow = () =>{

      let createdRow = {
        "idProducto": '',
        "claveProducto": '',
        "claveUnidad": '',
        "nombreUnidad": '',
        "descripcion": '',
        "cantidad": 1,
        "valorUnitario": undefined,
        "totalsat": 0
      }

      let subtotal = (CantidadProds*ValUnitProds)
      
      setSubTotal(SubTotal + subtotal)
      setRowsProds([...RowsProds, createdRow])
  }
  const AddRow = () =>{
    if (RowsProds.length > 0) { 
        if ((RowsProds.filter((dataFilt, index) => dataFilt.descripcion === '').length > 0)) {
          
          Swal.fire({
            title: 'Error al agregar renglón',
            text: 'Debe seleccionar un producto',
            icon: 'error',
            position: 'top',
            timer: 2500,
            timerProgressBar: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        } else if (RowsProds.filter((dataFilt, index) =>dataFilt.valorUnitario === undefined || dataFilt.totalsat <= 0).length > 0) {
          
          Swal.fire({
            title: 'Error al agregar renglón',
            text: 'Hay un renglón con valor unitario menor a $0.00, revise los renglones',
            icon: 'error',
            position: 'top',
            timer: 2500,
            timerProgressBar: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }else{
          CrearRow()
        }
    }else {
      CrearRow()
    }


  }
  const DelRow = (id) =>{
    
    let totaly = 0

    const rowDeleted = RowsProds.filter((dataFilt, index) => index !== id)
    rowDeleted.map( res => totaly += res.totalsat )
    
    let impuesto = (totaly * 0.16)
    let subtotaly = (totaly - impuesto)
    
    setResumsImpuestos(impuesto)
    setResumsSubtotal(subtotaly)
    setResumsTotal(totaly)

    setRowsProds(rowDeleted)

  }
  
  const AsignarCantidad = (cantidad, indice) =>{
    if (cantidad > 1000) {
      Swal.fire({
        text: 'La cantidad no puede ser superior a 1000',
        icon: 'error',
        position: 'top',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
    }else if (cantidad < 1) {
      Swal.fire({
        text: 'La cantidad no puede ser inferior a 1',
        icon: 'error',
        position: 'top',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
    }else{
      
      let totaly = 0
      let nuevoArreglo = [...RowsProds]
      nuevoArreglo[indice].cantidad = (cantidad)
      nuevoArreglo[indice].totalsat = (cantidad * nuevoArreglo[indice].valorUnitario)
      nuevoArreglo.map( res => totaly += res.totalsat )
      
      let impuesto = (totaly * 0.16)
      let subtotaly = (totaly - impuesto)
      
      setResumsImpuestos(impuesto)
      setResumsSubtotal(subtotaly)
      setResumsTotal(totaly)
      setRowsProds(nuevoArreglo)

    }

  }
  const AsignarValorUnitario = (valor, indice) =>{
    if (valor > 9999) {
      Swal.fire({
        text: 'El valor unitario no puede ser superior a $9,999',
        icon: 'error',
        position: 'top',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
    }else {

      let totaly = 0
      let nuevoArreglo = [...RowsProds]
      nuevoArreglo[indice].valorUnitario = (valor)
      nuevoArreglo[indice].totalsat = (valor * nuevoArreglo[indice].cantidad)
      nuevoArreglo.map( res => totaly += res.totalsat )

      let impuesto = (totaly * 0.16)
      let subtotaly = (totaly - impuesto)
      
      setResumsImpuestos(impuesto)
      setResumsSubtotal(subtotaly)
      setResumsTotal(totaly)
      setRowsProds(nuevoArreglo)

    }
  }


  const AsignarDescripcion = (descripcion, indice) =>{
    
    let nuevoArreglo = [...RowsProds]
    nuevoArreglo[indice].descripcion = descripcion
    nuevoArreglo[indice].claveProducto = ''
    nuevoArreglo[indice].nombreUnidad = ''
    nuevoArreglo[indice].idProducto = ''
    nuevoArreglo[indice].claveUnidad = ''
    setRowsProds(nuevoArreglo)

    if (descripcion.length >= 3) {
      setFlagIndxProd(indice)
      ObtenerProductos(descripcion)
    }else{
      setFlagIndxProd()
    }
  }
  const EstablecerProductosFact = async(Producto, indice) =>{ 
    
    await axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetUnitKeysAll?search=${Producto.claveUnidad}`)
    .then(res =>{
        setUnidNameProds(`${res.data[0].key} - ${res.data[0].name}`)
        let nuevoArreglo = [...RowsProds]
        nuevoArreglo[indice].descripcion = Producto.descripcion
        nuevoArreglo[indice].claveProducto = Producto.claveProducto
        nuevoArreglo[indice].nombreUnidad = res.data[0].name
        nuevoArreglo[indice].idProducto = Producto.id
        nuevoArreglo[indice].claveUnidad = Producto.claveUnidad
        setRowsProds(nuevoArreglo)
        setFlagIndxProd()
    })
    .catch( err =>{
      Swal.fire({
        title: 'Error',
        text: 'El producto no puedo ser seleccionado',
        icon: 'error',
        position: 'top',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
    })

  }

  const EmitirFactura = async() =>{
    setFacturandoPreloader(true)

    if (Fltr_TypeFact === 1) {
      let dataFact = {
        "tipoFactura": Fltr_TypeFact,
        "idSucursal": 1,
        "idUsuario": UserData.idusuario,
        "idReceptor": 1,
        "tipoMoneda": TipoMoneda,
        "formaPago": FormaPago.toString(),
        "metodoPago": MetodoPago.toString(),
        "total": ResumsTotal,
        "observaciones": Observaciones
      }
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Emitir`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
        data: dataFact
      }
      try {
        await axios(config)

        Swal.fire({
          title: 'Factura generada',
          // text: 'Su sesión ha caducado',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        navigate('/Facturas')

      } catch (err) {
        if (err.response.status === 401) {
          Swal.fire({
            title: 'Error al generar la factura',
            text: 'Su sesión ha caducado',
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }else if (err.response.status === 400) {
          
          Swal.fire({
            title: 'Error al generar la factura',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }
      }
      
    }else if (Fltr_TypeFact >= 2 && Fltr_TypeFact <= 4) {

      let dataFact = {
        "tipoFactura": Fltr_TypeFact,
        "idSucursal": 1,
        "idUsuario": UserData.idusuario,
        "idReceptor": ClienteID,
        "tipoMoneda": TipoMoneda,
        "formaPago": FormaPago.toString(),
        "metodoPago": MetodoPago.toString(),
        "total": ResumsTotal,
        "observaciones": Observaciones,
        "conceptos": RowsProds,
      }
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Emitir`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
        data: dataFact
      }
      try { await axios(config)
        Swal.fire({
          title: 'Factura generada',
          // text: 'Su sesión ha caducado',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        navigate('/Facturas')
      } catch (err) {
        if (err.response.status === 401) {
          Swal.fire({
            title: 'Error al generar la factura',
            text: 'Su sesión ha caducado',
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }else if (err.response.status === 400) {
          
          Swal.fire({
            title: 'Error al generar la factura',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }
      }

      
    }else{
        alert(`TipoFact: ${Fltr_TypeFact}`)
    }
    setFacturandoPreloader(false)
  }
  const CancelarFactura = async()=>{
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Cancelar/Factura/${CancelarFactData.serie}/${CancelarFactData.folio}/03/${User.idusuario}?FechaCancelacion=${hoyFch}`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + User.token
      },
    }
    setLoading(true)
    try { await axios(config)
      
      Swal.fire({
        title: 'Factura cancelada',
        // text: '',
        icon: 'success',
        position: 'top',
        timer: 1800,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#52C150'
      })
      setLoading(false)
      setCancelarFactData()
      ObtenerFacturas()
      CloseModal_FacturasCancelarFact()
      
    } catch (err) {
      setLoading(false)
      if (err.response.status === 401) {
        Swal.fire({
          title: 'Error al generar la factura',
          text: 'Su sesión ha caducado',
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      }else if (err.response.status === 400) {
        
        Swal.fire({
          title: 'Error al generar la factura',
          text: `${err.response.data.message}`,
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      }
    }

  }
  const RetimbrarFactura = async(dataFact) =>{

    if (dataFact.estatus === 0) {
      setLoading(true)
      await axios.get(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Retimbrar/${dataFact.id}`, {
        headers: {
          'Authorization': 'Bearer ' + User.token
        }
      })
      .then(res =>{
        setLoading(false);
        Swal.fire({
          title: 'Factura timbrada',
          // text: ``,
          icon: 'success',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        ObtenerFacturas()
      })
      .catch(err =>{
        setLoading(true)
  
          setLoading(false)
          Swal.fire({
            title: 'Error al retimbrar factura',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })

      })
    }
      
      

  }
  const handleTotalito = (val) =>{
    let valorTotal = parseFloat(val)

    if (valorTotal >= 0 && valorTotal <= 99999) {
      let valorUnitario = valorTotal/1.16
      setResumsTotal(valorTotal)
      setResumsSubtotal(valorUnitario)
      setResumsSubDesc(valorUnitario - ResumsDescuento)
      setResumsImpuestos(valorTotal - valorUnitario)

    }
  }

  const NavAlter = () => {
      navigate('Agregar')
    
  }
  const ObtenerCFDIs = async() =>{
    await axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetUsesCFDIAll`)
    .then( res =>{
      setDataCFDIs(res.data?.map(res=>{return(res)}))
    })
    .catch( err =>{
      console.log(err)
    })
  }
  const ObtenerFacturas = () =>{              //Facturacion/Paginador?IdSucursal=1&TipoFactura=0&MetodoPago=0&Filtro=0&FechaInicio=01-01-2023&FechaFin=01-01-2023&Pagina=1&RegistrosPorPagina=10
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg === 0 ? 1 : Pg}&RegistrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`)
  }
  const BuscarFacturas = () =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${1}&RegistrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`)
  } 

  const ChangeRegs = (Regis) =>{
    GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${1}&RegistrosPorPagina=${Regis}`)
  }
  const Facturas_ProPrevPg = () =>{
    ProPrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${1}&RegistrosPorPagina=${RegsPg}`)
  }
  const Facturas_PrevPg = () =>{
    PrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg - 1}&RegistrosPorPagina=${RegsPg}`)
  }
  const Facturas_NextPg = () =>{
    NextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${Pg + 1}&RegistrosPorPagina=${RegsPg}`)
  }
  const Facturas_ProNextPg = () =>{
    ProNextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Facturacion/Paginador?IdSucursal=1&TipoFactura=${PagsFltr_TipoFactura === undefined ? 0 : PagsFltr_TipoFactura}&MetodoPago=${PagsFltr_TipoPago === undefined ? 0 : PagsFltr_TipoPago}&Filtro=${PagsFltr_Estatus === undefined ? 0 : PagsFltr_Estatus}&FechaInicio=${PagsFltr_FchInicio}&FechaFin=${PagsFltr_FchFinal}&Pagina=${MaxPg}&RegistrosPorPagina=${RegsPg}`)
  }



  const CheckAlterName = (alterType) =>{
    if (alterType === 'Add') {
      return ('Agregar producto')
    } else if (alterType === 'Edit'){
      return ('Editar producto')
    } else if (alterType === 'Del'){
      return ('Eliminar producto')
    }
  }
  
  const TypeFactisProds = () =>{
    if (Fltr_TypeFact > 1 && Fltr_TypeFact < 5) {
      
      return(true)
    }else{
      
      return(false)
    }
  }
  const formatMoney = (number) =>{
    return new Intl.NumberFormat('ES-MX' , { style: 'currency', currency: 'MXN'}).format(number)
  }

  

  return {
    Facturas_ProPrevPg, Facturas_PrevPg, Facturas_NextPg, Facturas_ProNextPg,
    InfoTab, Loading, Pg, MaxPg, RegsPg, PagsFltr_TipoFactura, PagsFltr_TipoPago, PagsFltr_Estatus, PagsFltr_FchInicio, PagsFltr_FchFinal, setPagsFltr_TipoFactura, setPagsFltr_TipoPago, setPagsFltr_Estatus, setPagsFltr_FchInicio, setPagsFltr_FchFinal,
    ObtenerFacturas, ChangeRegs, NavAlter, ObtenerCFDIs, DataCFDIs,
    formatMoney,

    Fltr_TypeFact, FormaPago, MetodoPago, TipoMoneda, Cliente, rfc, usocfdi, Observaciones,
    setFltr_TypeFact, setFormaPago, setMetodoPago, setTipoMoneda, setCliente, setrfc, setusocfdi, setObservaciones,
    Cantidad, UnidadSAT, ClaveSAT, DescriptSAT, ValUnit, Descuent, SubTotal,
    setCantidad, setUnidadSAT, setClaveSAT, setDescriptSAT, setValUnit, setDescuent, setSubTotal,
    handleSelectTypeFact,

    
    ResumsSubtotal, ResumsDescuento, ResumsSubDesc, ResumsImpuestos, ResumsTotal, 
    setResumsSubtotal,setResumsDescuento,setResumsSubDesc,setResumsImpuestos,setResumsTotal,

    EmitirFactura, handleTotalito, FacturandoPreloader, setFacturandoPreloader, BuscarFacturas, CancelarFactura,

    CheckAlterName,
    RowInfo, setRowInfo, RowsProds, setRowsProds, idProductProds, setidProductProds, ClveProds, setClveProds, UnidNameProds, setUnidNameProds, CantidadProds,
    setCantidadProds, ValUnitProds, setValUnitProds, DescriptProds, setDescriptProds, FlagSrchProds, setFlagSrchProds, ResProds, setResProds, RowsProds, setRowsProds, FlagSrchCltes, ResCltes,
    ObtenerProductos, EstablecerProds, ObtenerClientes, EstablecerClientes, TypeFactisProds, FlagIndxProd, setFlagIndxProd, 
    CrearRow, AddRow, DelRow,AsignarCantidad, AsignarValorUnitario, AsignarDescripcion, EstablecerProductosFact,
    Modal_FacturasReCorreo, AbrirModalReCorreo, CloseModal_FacturasReCorreo, ReCorreoData, DestinoCorreo, setDestinoCorreo, B64, setB64,
    Modal_FacturasVerPDF, OpenModal_FacturasVerPDF, CloseModal_FacturasVerPDF, AbrirModalVerPDF, ReenviarCorreo, CerrarModal_ReCorreo, FlagSendingMail,
    Modal_FacturasCancelarFact, OpenModal_FacturasCancelarFact, CloseModal_FacturasCancelarFact, AbrirModalCancelarFactura, CancelarFactData, Activedots, setActivedots, ActivarDots,
    AsignarFormaPago, AsignarMetodoPago, RetimbrarFactura

  }
}

