import React from 'react';
import { LayoutLoader } from './theme/LayoutLoader';
import imgHojaAzul from '../../../assets/SVGs/hojaazul.svg';
import imgHojaVerde from '../../../assets/SVGs/hojaverde.svg';

const Loader = (props) => {
    return (
        <LayoutLoader style={props.style}>
            <div class="loader">
                <div class="loaderImg">
                    <div class="duo duo1">
                        <div class="dot dot-a"> <img src={imgHojaVerde} alt="" /></div>
                        <div class="dot dot-b"> <img src={imgHojaAzul} alt="" /></div>
                    </div>
                    <div class="duo duo2">
                        <div class="dot dot-a"> <img src={imgHojaAzul} alt="" /></div>
                        <div class="dot dot-b"> <img src={imgHojaVerde} alt="" /></div>
                    </div>
                </div>
                <div className="loaderTexto">
                    <p>{props.tittle}...</p>
                </div>
            </div>
        </LayoutLoader>
    )
}

export default Loader