import React from 'react'

const Footer = () => {
  return (
    <div className='App-Footer'>
      <p className='txt-foot'>DESARROLLADO POR PKT1 MX</p>
    </div>
  )
}

export default Footer