import React from 'react'
import Modal from 'react-modal';
import { ModalLayout } from '../../../themes/layout';
import useMode from '../../hooks/useMode';

const ModalComponent = (props) =>{
  document.addEventListener('keydown', function(event) {
    if (event.key === 'Escape') {
      // Realiza acciones cuando se presiona la tecla Escape
    }
  });

  const{Darkmode,AzulFuerteLight,Blanco}=useMode()

  
  //    Configuraciones del custom modal

  //    Identificador     /     Descripcion

  //    ancho             =     Ancho del modal
  //    alto              =     Altura del modal
  //    StatusModal       =     Determina si esta activa o no (boolean)
  //    CerrarModal       =     Funcion para cerrar el modal
  //    Contenido         =     Contenido html que mostrara el modal
  //    Titulo            =     Titulo del modal


    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          marginRight: '-5%',
          overflowY: 'scroll',
          transform: 'translate(-50%, -50%)',
          background: '#F0F1F3',
          borderRadius: '8px',
          boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
          width: `${props.ancho}`, // puede ser props
          height: `${props.alto}`, // puede ser props
          overflowY: 'auto',
          zIndex: '999'
        },
      };

  return (
    <Modal
        isOpen={props.StatusModal}
        style={customStyles}
        // onRequestClose={props.CerrarModal}
    >
      <ModalLayout
        colorHeader={Darkmode === false ? AzulFuerteLight : 'red'}
        Blanco={Darkmode === false ? Blanco : 'red'}
      >
        <div className='customModal'>
            <div className='Header-Modal'>
               <div className="titulo-modal" style={props.Titulo ? {} : {display:'none'}}>
                  <h3 >{props.Titulo}</h3>
               </div>
               <div onClick={() => props.CerrarModal()} className="cerrarmodal">
                    <p>X</p>
               </div>
            </div>
            <div className='Body-modal'>
                {props.Contenido}
            </div>
        </div>
      </ModalLayout>
    </Modal>
  )
}

export default ModalComponent