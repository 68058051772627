import Header from "./components/Header/Header";
import { AuthProvider } from "./context/AuthContext";
import Navigation from "./routes/navigation";
import { LayoutContainer } from "./themes/layout";


function App() {
  return (
    <AuthProvider>
      <LayoutContainer>
        <Navigation></Navigation>
      </LayoutContainer>
    </AuthProvider>
  );
}

export default App;
