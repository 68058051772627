import React, { useEffect, useState } from 'react'
import Inpt from '../../utils/components/input/Inpt'
import AnLoader from '../../utils/components/Preloader/AnLoader'
import Btn from '../../utils/components/boton/Btn'
import Cruz from '../../assets/SVGs/Cruz.svg'
import lupa from '../../assets/SVGs/Lupa.svg'
import TablaPaginador from '../../utils/components/TablaPaginador/TablaPaginador'
import Paginacion from '../../utils/components/Paginacion/Paginacion'
import { useAbonos } from './hook/useAbonos'
import moment from 'moment'
import ReCorreo from '../../assets/SVGs/ReCorreo.svg'
import VerDoc from '../../assets/SVGs/watchDoc.svg'
import Equis from '../../assets/SVGs/x_deleting.svg'
import ModalComponent from '../../utils/components/Modal/Modal'

const Abonos = () => {

    const {
        Autorized,
        Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setLoading, setRegsPg, ProPrevPg, PrevPg, NextPg, ProNextPg,
        Activedots, setActivedots, ActivarDots, NavAlter, CancelarAbonoData, ReCorreoData, DestinoCorreo, B64, setDestinoCorreo,
        PagsFltr_TipoFactura, PagsFltr_TipoPago, PagsFltr_Estatus, PagsFltr_FchInicio, PagsFltr_FchFinal,
        setPagsFltr_TipoFactura, setPagsFltr_TipoPago, setPagsFltr_Estatus, setPagsFltr_FchInicio, setPagsFltr_FchFinal,
        ObtenerAbonos, BuscarCliente, setBuscarCliente, ChangeRegs, Abonos_ProPrevPg, Abonos_PrevPg, Abonos_NextPg, Abonos_ProNextPg, formatMoney,
        BuscarAbonos, AbrirModalReCorreo, AbrirModalVerPDF, AbrirModalCancelarFactura,
        Modal_FacturasReCorreo, OpenModal_FacturasReCorreo, CloseModal_FacturasReCorreo, CerrarModal_ReCorreo,
        Modal_FacturasVerPDF, OpenModal_FacturasVerPDF, CloseModal_FacturasVerPDF, FlagSendingMail,
        Modal_FacturasCancelarFact, OpenModal_FacturasCancelarFact, CloseModal_FacturasCancelarFact, ReenviarCorreo, CancelarAbono, RetimbrarAbono
    } =useAbonos()

    const CheckStatusFact_Names = (statu) =>{
      switch (statu) {
        case 0:
          return('Generada');

        case 1:
          return('Timbrada');

        case 2:
          return('Pendiente');

        case 3:
          return('Cancelada');

        default:
          break;
      }
    }
    const CheckStatusFact_Styles = (statu) =>{
      switch (statu) {
        case 0:
          return({background: '#09a306', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px', cursor: 'pointer'});

        case 1:
          return({background: '#4ce549', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px'});

        case 2:
          return({background: '#e7b424', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px'});

        case 3:
          return({background: '#f85858', padding: '8px', width: '80%', margin:'auto',  boxSizing: 'border-box', borderRadius: '5px'});

        default:
          break;
      }
    }

    const DotsItems = (estatus) =>{
      if ((Autorized('Abonos', 'ConsultarPDF') || Autorized('Abonos', 'EnviarCorreo') || Autorized('Abonos', 'Cancelar')) && estatus < 2) {
        return(true)
      }else{
        return(false)
      }
    }

    const DotsMenu = (indx) =>{
      if (InfoTab.length > 7) {
        if (indx > InfoTab.length - 5) {
          return('dots-menu')
        }else{
          return('dots-menu')
        }
      }
      // indx < 7 ? 'dots-menu' : 'dots-menu-inverted'

      //   ActiveDots === Clients.id
      //     ?
      //       (ResData.length > 7 ? indx > ResData.length - 5 : false)

      //       ? { display: "block", bottom: "36px" } //Se despliega el submenu hacia arriba
      //       : { display: "block", top: "36px" } //Se despliega en submenu hacia abajo

      //     : { display: "none" }
    }
    

    const RenderModalReCorreo = () =>{
      return(
        FlagSendingMail ?
        
          <AnLoader style={{left:'35%'}} tittle = {'Enviando Correo'}/>
        :
        
          <div className='ReCorreo-container'>
            <div className='ReCorreo-infos'>
  
              <div className='ReCorreo-DestinoInfo' style={{marginTop:'10px'}}>
                <Inpt
                  type={'text'}
                  tittle={'Destino'}
                  value={DestinoCorreo}
                  setValue={setDestinoCorreo}
                  placehold={ReCorreoData?.correoCliente}
  
                
                />
              </div>
              
              <div className='ReCorreo-InfoFact'>
                <p className='txt-ReCorreo'>Cliente:</p>
                <p className='txt-ReCorreo'>{ReCorreoData?.nombreCliente}</p>
              </div>
              
              <div className='ReCorreo-InfoFact'>
                <p className='txt-ReCorreo'>CFDI Factura:</p>
                <p className='txt-ReCorreo'>{`${ReCorreoData?.serie} - ${ReCorreoData?.folio}`}</p>
              </div>
  
            </div>
  
            
            <div className='btn-ReCorreo'>
              
              <Btn
                type={'accept'}
                descript={'Aceptar'} 
                clicked={()=>ReenviarCorreo(ReCorreoData, 1)}
              />
            </div>
          </div>
      )
    }


  
  
  const RenderModalVerPDF = () =>{
    return(
      
    <div className='ReCorreo-container'>
      <div className='ReCorreo-infos'>
        <object type='application/pdf' width='100%' height='100%' name='testeo' data={B64}></object>        
      </div>
    </div>
    )
  }

  const RenderModalCancelarFact = () =>{
    return(
      
    <div className='CancelFact-container'>
      <div className='CancelFact-infos'>
        
        <div className='CancelFact-InfoFact'>
          <p className='txt-CancelFact'>Cliente:</p>
          <p className='txt-CancelFact'>{CancelarAbonoData?.nombreCliente}</p>
        </div>
        
        <div className='CancelFact-InfoFact'>
          <p className='txt-CancelFact'>CFDI Factura:</p>
          <p className='txt-CancelFact'>{`${CancelarAbonoData?.serie} - ${CancelarAbonoData?.folio}`}</p>
        </div>

        <div className='CancelFact-InfoFact'>
          <p className='txt-CancelFact'>Monto:</p>
          <p className='txt-CancelFact'>{formatMoney(CancelarAbonoData?.monto)}</p>
        </div>
      </div>

      
      <div className='btn-CancelFact' style={{display:'flex', justifyContent:'center'}}>
        
        <Btn
          type={'accept'}
          descript={'Aceptar'} 
          clicked={()=>CancelarAbono(CancelarAbonoData.id)}
        />
      </div>
    </div>
    )
  }
  

  const RenderItemCabezeraTablaPaginador = () => {
    return (
      <>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p>ID</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Usuario</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Fecha</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 6}}>
            <p>Cliente</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>Importe</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Tipo pago</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
            <p>CFDI</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
            <p>Estatus</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
            <p></p>
        </div>
      </>
    )
  }
  const RenderItemCuerpoTablaPaginador = () => {
    return (
      <div style={{ background: '#fff' }}>
        <>
          {
            // InfoTab ?
              InfoTab?.map((res, indx) => {
                return (
                  <>
                    <div className='Cuerpo-TablaSinPaginador' style={{position:'relative'}}>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                          <p className='Texto-Tabla'>{res.id}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                          <p className='Texto-Tabla'>{res.usuario}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                          <p className='Texto-Tabla'>{res.fechaTimbrado}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 6, textAlign:'left' }}>
                          <p className='Texto-Tabla'>{res.nombreCliente}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                          <p className='Texto-Tabla'>{formatMoney(res.monto)}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                          <p className='Texto-Tabla'>{res.formaPago === 'Transferencia electrónica de fondos' ? 'Transferencia' : res.formaPago === 'Cheque nominativo' ? 'Cheque' : res.formaPago}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                          <p className='Texto-Tabla'>{res.serie} - {res.folio}</p>
                      </div>
                      <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                          <p className='Texto-Tabla' title={res.estatus === 0 ? 'Retimbrar' : ''} onClick={()=>RetimbrarAbono(res)} style={CheckStatusFact_Styles(res.estatus)}>
                            {CheckStatusFact_Names(res.estatus)}
                          </p>
                      </div>

                      <div className='ItemCuerpo-AccionesPaginador' style={(Autorized('Abonos', 'Paginador')) ? {flex:'1'} : { flex:'1', opacity:'0'}} onClick={()=>ActivarDots(res.id)}>

                          <div className={Activedots === res.id ? 'cont-dots-active' : 'cont-dots'} style={DotsItems(res.estatus)  ? {} : {display:'none',cursor:'pointer'}}>
                            <span className='dotAction' style={{ background: '#9597A7' }}></span>
                            <span className='dotAction' style={{ background: '#9597A7' }}></span>
                            <span className='dotAction' style={{ background: '#9597A7' }}></span>
                          </div>

                          {/* if (InfoTab > 7) {
                            if (indx > InfoTab.length - 5) {
                              return('dots-menu')
                            }else{
                              return('dots-menu-inverted')
                            }
                          } */}

                        <ul className={(( InfoTab.length < 6 ) || ((InfoTab.length > 6) && (indx < InfoTab.length - 3))) ? 'dots-menu' : 'dots-menu-inverted'} style={Activedots === res.id && DotsItems(res.estatus)  ? {} : {display:'none'}}>
                          
                          <li className='dm-row' style={Autorized('Abonos', 'ConsultarPDF') && DotsItems(res.estatus) ? {} : {display:'none'}} onClick={()=>AbrirModalVerPDF(res)} >
                            <div className='cont-rowIcon'>
                              <img className='row-icon' src={VerDoc} alt="" />
                            </div>
                            <p className='row-txt' >Ver PDF</p> 
                          </li>

                          
                          <li className='dm-row' style={Autorized('Abonos', 'EnviarCorreo') && DotsItems(res.estatus) ? {} : {display:'none'}} onClick={()=>AbrirModalReCorreo(res)} >
                            <div className='cont-rowIcon'>
                              <img className='row-icon' src={ReCorreo} alt="" />
                            </div>
                            <p className='row-txt' >Reenviar correo</p> 
                          </li>

                          
                          <li className='dm-row' style={Autorized('Abonos', 'Cancelar') && DotsItems(res.estatus) ? {} : {display:'none'}} onClick={()=>AbrirModalCancelarFactura(res)} >
                            <div className='cont-rowIcon' style={{background: '#f96262'}}>
                              <img className='row-icon' src={Equis} alt="" />
                            </div>
                            <p className='row-txt' >Cancelar</p> 
                          </li>

                        </ul>

                      </div>

                    </div>
                  </>
                )
              })
            // :
            // <div className='Cuerpo-TablaSinPaginador' style={{position:'relative'}}>
            //   <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
            //       <p className='Texto-Tabla'>No se encontraron registros</p>
            //   </div>
            // </div>
          }
        </>
      </div>

    )
  }

  useEffect(() => {
    ObtenerAbonos()
  
  }, [])
  

  return (
    
  Loading ?
    <AnLoader tittle = {'Cargando abonos'}></AnLoader>
  :
    <div className='Seccion-Area'>

    <div className='herramientas'>
      <div className='filtros'>

      <div className='fchCreacion'>

        <Inpt
          type={'text'}
          tittle={'Buscar cliente'}
          value={BuscarCliente}
          setValue={setBuscarCliente}

        />

      </div>

        <div className='fchCreacion'>

          <Inpt
            type={'date'}
            tittle={'Fecha Inicio'}
            value={PagsFltr_FchInicio}
            setValue={setPagsFltr_FchInicio}

          />

        </div>

        <div className='fchTimbrado'>
          
          <Inpt
            type={'date'}
            tittle={'Fecha Fin'}
            value={PagsFltr_FchFinal}
            setValue={setPagsFltr_FchFinal}

          />

        </div>
        
        <div className='tipoFactura'>

          <Inpt
            type={'select'}
            opciones={[
              { "key": 0, "description" : 'Todas'},
              { "key": 1, "description" : 'Comisiones UPS'},
              { "key": 2, "description" : 'Venta de Activos'},
              { "key": 3, "description" : 'Cobro de Uniformes'},
              { "key": 4, "description" : 'Ingresos Adicionales'},
              { "key": 5, "description" : 'Energia electrica'},
            ]}
            tittle={'Tipo Factura'}
            value={PagsFltr_TipoFactura}
            setValue={setPagsFltr_TipoFactura}

          />
        </div>

        <div className='estatus'>

          <Inpt
            type={'select'}
            opciones={[
              { "key": 0, "description" : 'Todas'},
              { "key": 1, "description" : 'Generadas'},
              { "key": 2, "description" : 'Timbradas'},
              { "key": 3, "description" : 'Pend. Cancelar'},
              { "key": 4, "description" : 'Canceladas'},
            ]}
            tittle={'Estatus'}
            value={PagsFltr_Estatus}
            setValue={setPagsFltr_Estatus}

          />
            

        </div>

        {/* <div className='metodoPago'>
        
        <Inpt
          type={'Método Pago'}
          opciones={[
            { "key": 0, "description" : 'Todos'},
            { "key": 1, "description" : 'PUE'},
            { "key": 2, "description" : 'PPD'},
          ]}
          tittle={'Método Pago'}
          value={PagsFltr_TipoPago}
          setValue={setPagsFltr_TipoPago}

        />

        </div> */}

        <div className='BotonBuscar'>
          <Btn
            type={'iconoMini'}
            imagen={lupa}
            forceBtnSty={{background:'#3BAF29'}}
            clicked={BuscarAbonos}
          
          />
        </div>



      </div>
      
      <div className='agregar' style={Autorized('Abonos', 'Agregar') ? {} : {display:'none'}}>
          <Btn
            type={'add'}
            imagen={Cruz}
            clicked={NavAlter}
          />
      </div>
    </div>

    <div className='tabla'>
      {
        Autorized('Abonos', 'Paginador')
        ?

          <TablaPaginador
          Cabezera={RenderItemCabezeraTablaPaginador}
          Cuerpo={RenderItemCuerpoTablaPaginador}
          />
          
        :
          <p>No autorizado</p>
      }
    </div>

    <div className='paginacion'>

      <Paginacion
        RegsPg={RegsPg}
        setRegsPg={ChangeRegs}

        Pg={Pg}
        MaxPg={MaxPg}
        NextPg={Abonos_NextPg}
        PrevPg={Abonos_PrevPg}
        ProNextPg={Abonos_ProNextPg}
        ProPrevPg={Abonos_ProPrevPg}
      />
      
      
    </div>
    
     <ModalComponent
      StatusModal={Modal_FacturasReCorreo}
      CerrarModal={CerrarModal_ReCorreo}
      Titulo={`Reenviar Correo`}
      Contenido={RenderModalReCorreo()}
      ancho={'40%'}
      alto={'45%'}

    />

    <ModalComponent
      StatusModal={Modal_FacturasVerPDF}
      CerrarModal={CloseModal_FacturasVerPDF}
      Titulo={`PDF Factura`}
      Contenido={RenderModalVerPDF()}
      ancho={'80%'}
      alto={'90%'}

    />
    
    <ModalComponent
      StatusModal={Modal_FacturasCancelarFact}
      CerrarModal={CloseModal_FacturasCancelarFact}
      Titulo={`Cancelar Factura`}
      Contenido={RenderModalCancelarFact()}
      ancho={'40%'}
      alto={'45%'}

    /> 
    

    </div>
  )
}

export default Abonos