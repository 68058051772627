import { useState } from "react";
import { usePaginacion } from '../../../utils/components/Paginacion/hook/usePaginacion'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import axios from 'axios'
import Swal from "sweetalert2";
import useModal from "../../../utils/components/Modal/hook/useModal";

export const useProductos = () => {
  
    const navigate = useNavigate()
    const {User} = useAuth()
    const {Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setRegsPg, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg } = usePaginacion()
    const {Modal_ProductoStatus, OpenModal_ProductoStatus, CloseModal_ProductoStatus} = useModal()
    
  
    const [Filtro, setFiltro] = useState(0)
    const [BuscarProd, setBuscarProd] = useState('')
  
    const [BuscarClveSat, setBuscarClveSat] = useState('')
    const [ClveProdSAT, setClveProdSAT] = useState()
    const [ClveUnidSAT, setClveUnidSAT] = useState()
    const [DescriptSAT, setDescriptSAT] = useState('')
    const [UnidadClve, setUnidadClve] = useState()

    const [ResClvesUnidad, setResClvesUnidad] = useState([])
    const [FlagClvesUnid, setFlagClvesUnid] = useState(false)

    const [ProductoStatusData, setProductoStatusData] = useState()
     
    // const NavAlter = () => {
    //   navigate('Agregar')
    
    // }

    
  const NavAlter = (nav, data) => {
    if (nav === 'Editar') {
      navigate(nav, {state:{
        "id": data.id,
        "claveProducto": data.claveProducto,
        "claveUnidad": data.claveUnidad,
        "descripcion": data.descripcion,
        "fecha": data.fecha,
        "estatus": true
      }})
        
    }else{
      navigate('Agregar')
    }
  }
  const NavBack = () =>{
    navigate('/Productos')
  }
  const AbrirModalProdStatus = (data) =>{
    setProductoStatusData(data)
    OpenModal_ProductoStatus()
  }

    
  const GetUnitKey = async(data) =>{
      await axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetUnitKeysAll?search=${data}`)
      .then(res =>{
          setClveUnidSAT(`${res.data[0].key} - ${res.data[0].name}`)
      })
      .catch( err =>{
          console.log(err)
      })
  }


    const EstablecerClaveProd = (data) =>{
        setClveProdSAT(data.clave)
        setDescriptSAT(data.descripcion)
        // if (DescriptSAT === '') {
        // }
    } 
    const EstablecerClaveUnid = (data) =>{
      setClveUnidSAT(`${data.key} - ${data.name}`)
      setFlagClvesUnid(false)
      setUnidadClve(data.key)
    }


    const ObtenerClvesUnidSat = (data) =>{
      setClveUnidSAT(data)

      if (data.length >= 3) {
        setFlagClvesUnid(true)
          axios.get(`${process.env.REACT_APP_API_URL_WS_V2}Sat/GetUnitKeysAll?search=${data}`)
          .then( res =>{
              if (res.status === 204) {
                  setResClvesUnidad([])
              }else{
                  setResClvesUnidad(res.data)
              }
          })
          .catch( err =>{
              console.log(err)
          })
      }else{
        setFlagClvesUnid(false)
      }
    }
    const ObtenerClvesProdSat = async(clve) =>{
        setBuscarClveSat(clve)
        GetPages(`${process.env.REACT_APP_API_URL_WS_V2}Sat/paginatorkey?search=${clve ? clve : ''}&cartaPorte=false&page=${Pg === 0 ? 1 : Pg}&registerbypage=50`)
    }

  
    const ObtenerProductos = () =>{
      GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${BuscarProd}&Filtro=${Filtro}&Pagina=${Pg === 0 ? 1 : Pg}&RegistrosPorPagina=${RegsPg}`)
    }
    const ChangeRegs = (Regis) =>{
      GetPages(`${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${BuscarProd}&Filtro=${Filtro}&Pagina=1&RegistrosPorPagina=${Regis}`)
    }
    const Productos_ProPrevPg = () =>{
      ProPrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${BuscarProd}&Filtro=${Filtro}&Pagina=${1}&RegistrosPorPagina=${RegsPg}`)
    }
    const Productos_PrevPg = () =>{
      PrevPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${BuscarProd}&Filtro=${Filtro}&Pagina=${Pg}&RegistrosPorPagina=${RegsPg}`)
    }
    const Productos_NextPg = () =>{
      NextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${BuscarProd}&Filtro=${Filtro}&Pagina=${Pg}&RegistrosPorPagina=${RegsPg}`)
    }
    const Productos_ProNextPg = () =>{
      ProNextPg(`${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Paginador?Buscar=${BuscarProd}&Filtro=${Filtro}&Pagina=${MaxPg}&RegistrosPorPagina=${RegsPg}`)
    }


    const AgregarProducto = async() =>{
      let datosProds = {
        "claveProducto": ClveProdSAT,
        "claveUnidad": UnidadClve,
        "descripcion": DescriptSAT,
        "estatus": true
      }
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Agregar/${User.idusuario}`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
        data : datosProds
      }

      await axios(config)
      .then((res)=>{
        Swal.fire({
          title: 'Producto agregado',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        navigate('/Productos')
      })
      .catch((err)=>{
        Swal.fire({
          title: 'Error agregar producto',
          text: `${err.response.data.message}`,
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      })
    }
    const EditarProducto = async(productoData) =>{
      let datosProds = {
        "claveProducto": ClveProdSAT,
        "claveUnidad": UnidadClve,
        "descripcion": DescriptSAT,
        "estatus": true
      }
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Actualizar/${productoData.id}/${User.idusuario}`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
        data : datosProds
      }

      await axios(config)
      .then((res)=>{
        Swal.fire({
          title: 'Producto actualizado',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        navigate('/Productos')
      })
      .catch((err)=>{
        Swal.fire({
          title: 'Error actualizar producto',
          text: `${err.response.data.message}`,
          icon: 'error',
          position: 'top',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      })
    }

    const HabilitarProducto = async(productoData) =>{
      
      let datosProds = {
        "claveProducto": productoData.claveProducto,
        "claveUnidad": productoData.claveUnidad,
        "descripcion": productoData.descripcion,
        "estatus": true
      }
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Actualizar/${productoData.id}/${User.idusuario}`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
        data : datosProds
      }
      
      try { await axios(config)
        
        Swal.fire({
          title: 'Producto habilitado',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150',
          
        })
        CloseModal_ProductoStatus()
        ObtenerProductos()
          
      } catch (err) {
        
        if (err.response.status === 401) {
          Swal.fire({
            title: 'Error al habilitar producto',
            text: 'Su sesión ha caducado',
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }else if (err.response.status === 400) {
          
          Swal.fire({
            title: 'Error al habilitar producto',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }
        
      }
    }

    
    const DeshabilitarProducto = async(productoData) =>{
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Productos/Baja/${productoData.id}/${User.idusuario}`,
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        },
      }
      
      try { await axios(config)
        
        Swal.fire({
          title: 'Producto deshabilitado',
          icon: 'success',
          position: 'top',
          timer: 2500,
          timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
        CloseModal_ProductoStatus()
        ObtenerProductos()
          
      } catch (err) {
        
        if (err.response.status === 401) {
          Swal.fire({
            title: 'Error al Deshabilitar producto',
            text: 'Su sesión ha caducado',
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }else if (err.response.status === 400) {
          
          Swal.fire({
            title: 'Error al Deshabilitar producto',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })
        }
        
      }
    }

    
  // const EditClte = async(previaData) =>{

  //   let dataClient = { //Este enredo es debido aque el servicio API, necesita que solo envies los datos modificados
  //     "idclientepkt1": IDpkt1 !== previaData.idclientepkt1 ? IDpkt1 : undefined ,
  //     "tipocliente": TipoCliente !== previaData.tipocliente ? TipoCliente === 'Interno' ? 'I' : 'E' : undefined,
  //     "nombre": Nombre !== previaData.nombre ? Nombre : undefined ,
  //     "correo": Correo !== previaData.correo ? Correo : undefined ,
  //     "rfc": RFC !== previaData.rfc ? RFC : undefined ,
  //     "regimenFiscal": RegimFisc !== previaData.regimenfiscal ? RegimFisc : undefined ,
  //     "usoCfdi": CFDI !== previaData.usocfdi ? CFDI : undefined ,
  //     "cp": CP !== previaData.cp ? CP : undefined ,
  //   }
  //   if (dataClient === previaData) {
      
      
  //     Swal.fire({
  //       title: 'Error al actualizar producto',
  //       text: 'No hay cambios en los datos del cliente',
  //       icon: 'error',
  //       position: 'top',
  //       confirmButtonText: 'Aceptar',
  //       confirmButtonColor: '#52C150'
  //     })

  //   } else {
      
  //     let config = {
  //       method: 'post',
  //       url: `${process.env.REACT_APP_API_URL_INTERNO_V2}Clientes/Actualizar/${previaData.id}`,
  //       headers: { 
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + User.token
  //       },
  //       data : dataClient
  //     };
  //     await axios(config)
  //     .then( res =>{
        
  //       Swal.fire({
  //         title: 'Datos Actualizados',
  //         // text: '',
  //         icon: 'success',
  //         position: 'top',
  //         timer: 2500,
  //         timerProgressBar: true,
  //         confirmButtonText: 'Aceptar',
  //         confirmButtonColor: '#52C150'
  //       })

  //       navigate('/Clientes')
        
  //     })
  //     .catch( err =>{
        
  //       Swal.fire({
  //         title: 'Error al actualizar cliente',
  //         text: `${err.response.data.message}`,
  //         icon: 'error',
  //         position: 'top',
  //         confirmButtonText: 'Aceptar',
  //         confirmButtonColor: '#52C150'
  //       })
        
  //     })
  //   }


  // }




    return {
        Pg, setPg, MaxPg, RegsPg, InfoTab, Loading, setRegsPg, ChangeRegs, Productos_ProPrevPg, Productos_PrevPg, Productos_NextPg, Productos_ProNextPg, ObtenerProductos, ObtenerClvesUnidSat, EstablecerClaveProd,
        BuscarProd, setBuscarProd, Filtro, setFiltro, NavAlter, ObtenerClvesProdSat, BuscarClveSat, ClveProdSAT, ClveUnidSAT, DescriptSAT, setBuscarClveSat, setClveProdSAT, setClveUnidSAT, setDescriptSAT,
        ResClvesUnidad, FlagClvesUnid, EstablecerClaveUnid, AgregarProducto, EditarProducto, GetUnitKey, NavBack, ProductoStatusData,
        AbrirModalProdStatus, Modal_ProductoStatus, OpenModal_ProductoStatus, CloseModal_ProductoStatus,
        HabilitarProducto, DeshabilitarProducto
    }
}

