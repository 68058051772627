import React from 'react'
import { TablaLayout } from '../../../themes/layout';
// import useMode from '../../../hooks/useMode'

const TablaPaginador = (props) => {
    // const{Darkmode}=useMode()
    let Darkmode = false;

    return (
        <TablaLayout
          
          // BkHeader={Darkmode === false ? '#5B5F83' : 'red'}
          BkHeader={'#5B5F83'}
        >
           <div className='ContainerTablaBodyP'
           ref={props.identificador}
           >
              <div style={props.modal== true ?{display:'none'} : {background: 'transparent',boxShadow: '-1px 9px 1px rgba(0, 0, 0, 0.35)'}}>
                <h3>{props.Titulo}</h3>
              </div>
          <div className='Cabezera-TablaPaginador'>{props?.Cabezera()}</div>
          <div className='Cuerpoprincipal' >{props?.Cuerpo()}</div>
          </div>
        </TablaLayout>
      )
}

export default TablaPaginador