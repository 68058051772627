import React from 'react'
import { LayoutLogin, LayoutPreloader } from '../themes/layout'
import CardLogin from '../components/login/CardLogin'
import AnLoader from '../utils/components/Preloader/AnLoader'
import { useAuth } from '../context/AuthContext'

const Login = () => {
  const {Logging} = useAuth()
  return (
    
    <LayoutPreloader>
    {
      Logging ?
      <AnLoader tittle='Iniciando Sesión'></AnLoader>
      :
      
    <LayoutLogin>
      <div className='login-principal' style={{backgroundImage: `url('https://api.pktuno.mx//IMG/fondopkt1.png')`}}>
            <CardLogin></CardLogin>
        </div>
    </LayoutLogin>
    }
    
    </LayoutPreloader>
  )
}

export default Login