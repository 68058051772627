import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'

export const useCardLogin = () => {

    const navigate  = useNavigate()
    const {IniciarSesion} =useAuth()

    const [username, setusername] = useState()
    const [password, setpassword] = useState()

    const Logearse = async(e) =>{
        e.preventDefault()
        try {    
            await IniciarSesion(username, password)
            navigate('/')

        } catch (err) {
            console.error(err)
        }
        
        
    }

    
  return {
    username, setusername,
    password, setpassword,
    Logearse

  }
}
