import React from 'react'
import NextI from '../../../assets/SVGs/next-i.svg'
import NextD from '../../../assets/SVGs/next-d.svg'
import { LayoutPaginacion } from '../../../themes/layout'
import { usePaginacion } from './hook/usePaginacion'
import Inpt from '../input/Inpt'

const Paginacion = (props) => {
  
    // const { MaxPg, RegsPg, TextoSearch, Loading,  NextPg,  PrevPg,  ProNextPg, ProPrevPg} = usePaginacion() 
  return (
    
    <LayoutPaginacion>

      <div className='RegistrosPag'>
        
        <Inpt
          type={'select'}
          value={props.RegsPg}
          setValue={props.setRegsPg}
          // metodo={props.ChangeRegs}
          tittle={'Registros:'}
          opciones={[
              {"key": 10, "description": 10},
              {"key": 15, "description": 15},
              {"key": 20, "description": 20}
            ]}
        />

      </div>
        
      <div className="Paginacion">

        <div className="prev-plus"
              onClick={() =>  props.ProPrevPg()}
              style={
                  props.Pg <= 1
                  ? { background: "#9597a73d", cursor: "unset" }
                  : {}
              }
        >
          <img draggable={false} src={NextI} alt="" />
          <img draggable={false} src={NextI} alt="" />
        </div>

        <div className="prev"
              onClick={() =>  props.PrevPg(props.Pg)}
              style={
                props.Pg <= 1
                  ? { background: "#9597a73d", cursor: "unset" }
                  : {}
              }
        >
          <img draggable={false} src={NextI} alt="" />
        </div>
        
        <div className="pg">{`${props.Pg} / ${props.MaxPg}`}</div>

        <div className="next"
              onClick={() =>  props.NextPg(props.Pg)}
              style={
                props.Pg === props.MaxPg
                  ? { background: "#9597a73d", cursor: "unset" }
                  : {}
              }
        >
        <img draggable={false} src={NextD} alt="" />
        </div>

        <div className="next-plus"
              onClick={() =>  props.ProNextPg()}
              style={
                props.Pg ===  props.MaxPg
                  ? { background: "#9597a73d", cursor: "unset" }
                  : {}
              }
        >
          <img draggable={false} src={NextD} alt="" />
          <img draggable={false} src={NextD} alt="" />
        </div>

      </div>
    </LayoutPaginacion>
  )
}

export default Paginacion