import {createContext, useState, useContext, useEffect} from 'react'
import jwt from 'jwt-decode'
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment/moment';

const AuthContext = createContext();

export const useAuth = () =>{
    const context = useContext(AuthContext)
    if (!context) throw new Error('Provider no declarado')
    return context
}

export const AuthProvider = ({children}) => {


    const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')))
    const [UserData, setUserData] = useState()
    const [Logging, setLogging] = useState(false)
    const [SesionTime, setSesionTime] = useState()

    const IniciarSesion = async(user, password) =>{
      setLogging(true)
      const config = {
        method:'post',
        url: `${process.env.REACT_APP_API_URL_WS_V3}Autenticacion/Token/Interno`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        data: {
          "nombreUsuario": user,
          "pass": password
        },
      }
      
      await axios(config)
      .then( async(res) =>{
        let dcodetok = jwt(res.data.token)

        if (dcodetok.role === 'Administrador' || dcodetok.FactuCorp) { //Verificar si el usuario tiene acceso al sistema (FactuCorp)
          setUser(res.data)
          localStorage.removeItem('User')
          localStorage.setItem('User', JSON.stringify(res.data))

          await ObtenerUsuario(res.data.idusuario, dcodetok)
          // AutoRefreshToken(moment().format('YYYY-MM-DD HH:mm:ss'), moment.unix(dcodetok.exp).format('YYYY-MM-DD HH:mm:ss'))
          
        }else{
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'No tienes acceso al sistema',
            icon: 'error',
            position: 'top',
            // timer: 1500,
            // timerProgressBar: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
          })

        }
      })
      .catch(err=>{
        Swal.fire({
          title: 'Error al iniciar sesión',
          text: `${err.response.data.message}`,
          icon: 'error',
          position: 'top',
          // timer: 1500,
          // timerProgressBar: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#52C150'
        })
      })
      setLogging(false)
    }
    const CerrarSesion = async() =>{
      localStorage.removeItem('User')
      localStorage.removeItem('UserData')
      localStorage.removeItem('ActCat')
      setUser()
      setUserData()
      
      Swal.fire({
        title: 'Sesión cerrada exitosamente',
        icon: 'success',
        position: 'top',
        timer: 1500,
        timerProgressBar: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#3BAF29'
    })
    .then( res =>{
          
      // window.location.reload()
  })

    }
    

    
    const ObtenerUsuario = async(idUsr, decodtok) =>{
    
      const config = {
        method:'get',
        url: `${process.env.REACT_APP_API_URL_WS_V2}Usuario/getuser/${idUsr}`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }

      await axios(config)
      .then(res =>{
        


        const datosusuario = {
          'descripcion': res.data.descripcion,
          'estatusasesor': res.data.estatusasesor,
          'idsucursal': res.data.idfranquicia,
          'idusuario': res.data.idusuario,
          'nombreusuario': res.data.nombreusuario,
          'telefono': res.data.telefono,
          'DecodedToken': decodtok

        }
        
        setUserData(datosusuario)
        
        localStorage.removeItem('UserData')
        localStorage.setItem('UserData', JSON.stringify(datosusuario))

        
        const fechaObjeto1 = new Date(moment().format('YYYY-MM-DD HH:mm:ss'));
        const fechaObjeto2 = new Date(moment.unix(datosusuario.DecodedToken.exp).format('YYYY-MM-DD HH:mm:ss'));

        const tiempoSesion = fechaObjeto2.getTime() - fechaObjeto1.getTime();
        setSesionTime(tiempoSesion)

        setLogging(false)
        
        
      })
      .catch(err=>{
        Swal.fire({
            title: 'Error al iniciar sesión',
            text: `${err.response.data.message}`,
            icon: 'error',
            position: 'top',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#52C150'
        })
      })  
      setLogging(false)

    }
    const Autorized = (RequiredAcces, RequiredAction) =>{
      
      if (UserData?.DecodedToken.role === 'Administrador') {
        return(true)
      }else if (UserData?.DecodedToken.FactuCorp?.includes(`${RequiredAcces}-Todo`)) {
        return(true)
      }else if (UserData?.DecodedToken.FactuCorp?.includes(`${RequiredAcces}-${RequiredAction}`)) {
        return(true)
      }else{
        return(false)
      }
    }

    
    const RefrescarSesion = async(Token, refreshToken) =>{
      let datapost = {  //Datos de sesion recien caudados
        "token": Token,
        "refreshtoken": refreshToken

      }
      const config = {  //Request para refrescar sesion
        method:'post',
        url: `${process.env.REACT_APP_API_URL_WS_V3}Autenticacion/Token/Refrescar`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        data: datapost
      }

      try {
        const res = await axios(config) //Refrescar sesion, si fue exitoso continua
        let UsuarioRefreshed = { //Datos de sesion de usuario (id, token y refreshtoken)
          
            "idusuario": 740,
            "token": res.data.token,
            "refreshToken": res.data.refreshToken
        }
        
        let UsuarioDatosRefreshed = JSON.parse(localStorage.getItem('UserData'))
        UsuarioDatosRefreshed.DecodedToken = jwt(res.data.token)  //Nuevo token decodificado asignado al valor de la info del usuario

        localStorage.removeItem('User')
        localStorage.removeItem('UserData')
        localStorage.setItem('User', JSON.stringify(UsuarioRefreshed))
        localStorage.setItem('UserData', JSON.stringify(UsuarioDatosRefreshed))
        
        setUser(UsuarioRefreshed)
        setUserData(UsuarioDatosRefreshed)

      const fechaObjeto1 = new Date(moment().format('YYYY-MM-DD HH:mm:ss'));
      const fechaObjeto2 = new Date(moment.unix(UsuarioDatosRefreshed.DecodedToken.exp).format('YYYY-MM-DD HH:mm:ss'));

      const tiempoSesion = fechaObjeto2.getTime() - fechaObjeto1.getTime();
      setSesionTime(tiempoSesion)
        
        // AutoRefreshToken()
        // window.location.reload()

      } catch (err) {
        console.error(err)
      }
    }

    
    // const AutoRefreshToken = async(MomentoActual, MomentoExpiracion) =>{
    //   const fechaObjeto1 = new Date(MomentoActual);
    //   const fechaObjeto2 = new Date(MomentoExpiracion);

    //   const tiempoSesion = fechaObjeto2.getTime() - fechaObjeto1.getTime();
    //   setSesionTime(tiempoSesion)
      


    //   if (tiempoSesion > 100) {
    //     const Usuario  = JSON.parse(localStorage.getItem('User'))
    //     setInterval(() => {
    //       RefrescarSesion(Usuario.token, Usuario.refreshToken)

    //     }, tiempoSesion);

        
    //   }

    // }

    
    useEffect(() => {
      const Usuario  = JSON.parse(localStorage.getItem('User'))
      const UsuarioDatosRefreshed = JSON.parse(localStorage.getItem('UserData'))
      if (UserData === null || UserData === undefined) {
        setUser(Usuario)
        setUserData(UsuarioDatosRefreshed)
        
      }
    }, [])
    

    useEffect( () => {
      
      setTimeout(() => {
        RefrescarSesion(User?.token, User?.refreshToken)
      }, SesionTime);
    }, [User])
    
  return (
    <AuthContext.Provider value={{
      User, UserData, Logging,
      IniciarSesion, CerrarSesion, RefrescarSesion, Autorized //, AutoRefreshToken        //, ErrorsAlert

    }}>{children}</AuthContext.Provider>
  )
}
