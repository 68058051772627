import React, { useEffect, useState } from 'react'
import Inpt from '../../utils/components/input/Inpt'
import { LayoutAddFactura } from '../../themes/layout'
import { useFacturacion } from './hook/useFacturacion'
import Btn from '../../utils/components/boton/Btn'
import Cruz from '../../assets/SVGs/Cruz.svg'
import Editar from '../../assets/SVGs/EditarBlanco.svg'
import Eliminar from '../../assets/SVGs/Trash.svg'
import ModalComponent from '../../utils/components/Modal/Modal'
import AnLoader from '../../utils/components/Preloader/AnLoader'
import axios from 'axios'
import { useAuth } from '../../context/AuthContext'
import { useProductos } from '../Productos/hook/useProductos'
import Swal from 'sweetalert2'

const AddFact = () => {
  
  const {
    ObtenerCFDIs, DataCFDIs,Fltr_TypeFact, FormaPago, MetodoPago, TipoMoneda, Cliente, rfc, usocfdi, Observaciones,
    setFltr_TypeFact, setFormaPago, setMetodoPago, setTipoMoneda, setCliente, setrfc, setusocfdi, setObservaciones,
    Cantidad, UnidadSAT, ClaveSAT, DescriptSAT, ValUnit, Descuent, SubTotal, Totalito,
    setCantidad, setUnidadSAT, setClaveSAT, setDescriptSAT, setValUnit, setDescuent, setSubTotal,
    ResumsSubtotal, ResumsDescuento, ResumsSubDesc, ResumsImpuestos, ResumsTotal, 
    setResumsSubtotal,setResumsDescuento,setResumsSubDesc,setResumsImpuestos,setResumsTotal,
    handleSelectTypeFact, formatMoney, EmitirFactura, handleTotalito, FacturandoPreloader,
    Modal_AlterProducto, CloseModal_AlterProductoRow, OpenModal_AddRow, OpenModal_EditRow, OpenModal_DelRow, CheckAlterName,
    RowInfo, setRowInfo, idProductProds, setidProductProds, ClveProds, setClveProds, UnidNameProds, setUnidNameProds, CantidadProds,
    setCantidadProds, ValUnitProds, setValUnitProds, DescriptProds, setDescriptProds, FlagSrchProds, setFlagSrchProds, ResProds, setResProds, FlagSrchCltes, ResCltes,
    ObtenerProductos, ObtenerClientes, EstablecerClientes, TypeFactisProds, FlagIndxProd, setFlagIndxProd, RowsProds, setRowsProds, CrearRow, AddRow, DelRow,
    AsignarCantidad, AsignarValorUnitario, AsignarDescripcion, EstablecerProductosFact, AsignarFormaPago, AsignarMetodoPago
    } = useFacturacion()

  
  useEffect(() => {
    ObtenerCFDIs()
    
  }, [])

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <LayoutAddFactura>
      {
        FacturandoPreloader ?
        <AnLoader tittle = 'Generando factura'></AnLoader>
        :
        
      <div className='AddFact'>

        <div className='inFact-container'>
          <div className='infoFactura-flex'>
            
            <div className='inpt-container' style={{position:'relative'}}>
              <p className='txt-inpt'>Tipo de factura:</p>
              <div className='Tipo-Fact'>
                <Inpt
                  type={'select'}
                  opciones={[
                    { "key": 1, "description" : 'Comisiones UPS'},
                    { "key": 2, "description" : 'Venta de Activos'},
                    { "key": 3, "description" : 'Cobro de Uniformes'},
                    { "key": 4, "description" : 'Ingresos Adicionales'},
                    { "key": 5, "description" : 'Energía Eléctrica'},
                  ]}
                  // tittle={'Tipo de factura'}
                  value={Fltr_TypeFact}
                  setValue={handleSelectTypeFact}
                  defaultOpc={'Seleccionar Tipo'}

                />

              </div>
              
              <p className='devcaution' style={Fltr_TypeFact > 4 ? {} : {display:'none'}}>* Este tipo de factura se encuentra en desarrollo *</p>

            </div>

            <div className='datosPago-flex' style={{flex:'1'}}> {/* Datos comprobante */}
              
              <div className='cont-Titulo'> {/* Titulo */}
                  <div className='cinta-Titulo-lefted'>
                      <p className='txt-cintaTitulo' >Datos de comprobante</p>
                  </div>
              </div>
              
              <div className='inDatosPago-cont'> {/* Datos de comprobante */}

                <div className='inptElmnt-container'> 
                  <p className='txt-inpt' style={{flex:'1'}}>Forma de Pago:</p>
                  <div className='in-FactData' style={{flex:'2'}}>
                    <Inpt
                      type={'select'}
                      opciones={[
                        { "key": '01', "description" : 'Efectivo'},
                        { "key": '02', "description" : 'Cheque Nominativo'},
                        { "key": '03', "description" : 'Transferencia electrónica de fondos'},
                        { "key": '04', "description" : 'Tarjeta Crédito'},
                        { "key": '28', "description" : 'Tarjeta Debito'},
                        { "key": '99', "description" : 'Crédito'},
                      ]}
                      disabled={false} //Anteriormente se validaba esto:  Fltr_TypeFact <= 1

                      // [
                      //   {
                      //     "clave": "01",
                      //     "descripcion": "Efectivo"
                      //   },
                      //   {
                      //     "clave": "02",
                      //     "descripcion": "Cheque nominativo"
                      //   },
                      //   {
                      //     "clave": "03",
                      //     "descripcion": "Transferencia electrónica de fondos"
                      //   },
                      //   {
                      //     "clave": "04",
                      //     "descripcion": "Tarjeta de crédito"
                      //   },
                      //   {
                      //     "clave": "28",
                      //     "descripcion": "Tarjeta de débito"
                      //   },
                      //   {
                      //     "clave": "99",
                      //     "descripcion": "Crédito"
                      //   }
                      // ]
                      // tittle={'Forma de Pago'}
                      value={FormaPago}
                      setValue={AsignarFormaPago}
                      forceLayoutSty={{width:'100%'}}

                    />

                  </div>
                </div>
                <div className='inptElmnt-container'> 
                  <p className='txt-inpt' style={{flex:'1'}}>Método de pago:</p>
                  <div className='in-FactData' style={{flex:'2'}}>
                    <Inpt
                      type={'select'}
                      opciones={[
                        { "key": 'PUE', "description" : 'PUE - Pago en una sola exhibición'},
                        { "key": 'PPD', "description" : 'PPD - Pago en parcialidades o diferido'},
                      ]}
                      // tittle={'Método de pago'}
                      value={MetodoPago}
                      setValue={AsignarMetodoPago}
                      forceLayoutSty={{width:'100%'}}
                      disabled={false} //Anteriormente == Fltr_TypeFact <= 1

                    />

                  </div>
                </div>
                <div className='inptElmnt-container'>
                  <p className='txt-inpt' style={{flex:'1'}}>Moneda:</p>
                  <div className='in-FactData' style={{flex:'2'}}>
                    <Inpt
                      type={'select'}
                      opciones={[
                        { "key": 'MXN', "description" : 'MXN - Peso Mexicano'},
                        // { "key": 'USD', "description" : 'USD - Dolar estadounidense'}
                      ]}
                      // tittle={'Moneda'}
                      value={TipoMoneda}
                      setValue={setTipoMoneda}
                      forceLayoutSty={{width:'100%'}}
                      disabled={Fltr_TypeFact < 1}

                    />

                  </div>
                </div>

              </div>


                  
            </div>
                
            <div className='datosClte-flex' style={{flex:'1'}}> {/* Datos  receptor/cliente */}
              <div className='cont-Titulo'>
                  <div className='cinta-Titulo-lefted'>
                      <p className='txt-cintaTitulo' >Datos de Receptor</p>
                  </div>
              </div>
              <div className='inDatosClte-cont'>

                <div className='inptElmnt-container'>

                  <p className='txt-inpt' style={{flex:'1'}}>Cliente:</p>
                  <div className='in-FactData' style={{flex:'2', position:'relative'}}>
                  <Inpt
                      type={'text'}
                      // tittle={'Cliente'}
                      value={Cliente}
                      setValue={ObtenerClientes}
                      forceLayoutSty={{width:'100%'}}
                      disabled={Fltr_TypeFact < 1}
                    />
                    
                    <div className='list-Clientes' style={FlagSrchCltes ? {} : {display:'none'} }>
                      {
                      ResCltes?.map(Cliente =>{
                        return(
                          <div className='listed-client' style={{fontWeight:'bold', cursor: 'pointer'}} onClick={()=> EstablecerClientes(Cliente)}>{Cliente.nombre}</div>
                        )
                      })}
                    </div>
                  </div>


                </div>

                <div className='inptElmnt-container'>
                
                  <p className='txt-inpt' style={{flex:'1'}}>RFC:</p>
                  <div className='in-FactData' style={{flex:'2'}}>

                    <Inpt
                        type={'text'}
                        // tittle={'RFC'}
                        value={rfc}
                        setValue={setrfc}
                        maxLength={13}
                        forceLayoutSty={{width:'100%'}}
                        disabled={Fltr_TypeFact < 1}
                      />
                  </div>

                </div>

                <div className='inptElmnt-container'>
                  <p className='txt-inpt' style={{flex:'1'}}>Uso de CFDI:</p>
                  <div className='in-FactData' style={{flex:'2'}}>

                    <Inpt
                        type={'select'}
                        // tittle={'Uso de CFDI'}
                        value={usocfdi}
                        setValue={setusocfdi}
                        opciones={DataCFDIs}
                        disabled={Fltr_TypeFact < 1}
                        forceLayoutSty={{width:'100%'}}
                      />
                  </div>
                </div>



              </div>
            </div>

          </div>
        </div>

        <div className='resumFact-container' >

          <div className='cont-Titulo' style={{marginBottom:'15px'}}>
              <div className='cinta-Titulo-lefted'>
                  <p className='txt-cintaTitulo' >Resumen</p>
              </div>
          </div>

          <div className='Info-Resumen' style={ Fltr_TypeFact === 0 ? {display:'none'} : {} }>
            <div className='resumen-head'>  {/* Rows Header */}
              <div className='resumen-elemnt' style={{flex:'2'}}>Cantidad</div>
              <div className='resumen-elemnt' style={{flex:'4'}}>Unidad SAT</div>
              <div className='resumen-elemnt' style={{flex:'2'}}>Clave SAT</div>
              <div className='resumen-elemnt' style={{flex:'6'}}>Descripción / Concepto</div>
              <div className='resumen-elemnt' style={{flex:'2'}}>Valor unitario</div>
              <div className='resumen-elemnt' style={{flex:'2'}}>Subtotal</div>
              <div className='resumen-elemnt' style={ TypeFactisProds() ? {flex:'1'} :  {display:'none'} }></div>
            </div>

            <div className='resumen-body' style={ TypeFactisProds() ? {display:'none'} : {}} > {/* Row UPS */}
              <p className='resumen-elemnt' style={{flex:'2'}}>{ Cantidad }</p>
              <p className='resumen-elemnt' style={{flex:'4'}}>{ UnidadSAT }</p>
              <p className='resumen-elemnt' style={{flex:'2'}}>{ ClaveSAT }</p>
              <p className='resumen-elemnt' style={{flex:'6'}}>{ DescriptSAT }</p>
              <p className='resumen-elemnt' style={{flex:'2'}}>{ formatMoney(ResumsSubtotal) }</p>
              <p className='resumen-elemnt' style={{flex:'2'}}>{ formatMoney(ResumsSubtotal) }</p>
            </div>

            <div className='resumen-body' style={TypeFactisProds() ? {flexDirection:'column'} : {display:'none'}} > {/* Row Productos */}
              {
                RowsProds?.map( (RowRes, indxRow) =>{
                  return(
                    <div className='resumenBody-cont'>

                      <div className='resumen-elemnt' style={{flex:'2'}}> {/* Cantidad */}
                        {/* <div>  */}
                          <Inpt
                            type={'number'}
                            value={RowsProds[indxRow].cantidad}
                            setValue={(e)=>AsignarCantidad( e, indxRow)} 
                            minVal={1}
                            maxVal={1000}
                            forceInputSty={{textAlign:'center', width:'75%'}}
                          />
                        {/* </div> */}
                      </div>

                      <div className='resumen-elemnt' style={{flex:'4', textAlign:'center'}}  >{ `${RowRes.claveUnidad} - ${RowRes.nombreUnidad}` }</div> {/* Unidad SAT */}
                      
                      <div className='resumen-elemnt' style={{flex:'2'}}>{ RowRes?.claveProducto }</div> {/* Clave SAT */}
                      
                      <div className='resumen-elemnt' style={{flex:'6'}}> {/* Descripcion/Concepto */}
                        <div className='In-ProdName' style={{position:'relative'}}>
                          <Inpt
                            type='text'
                            value={RowRes.descripcion}
                            setValue={(e)=>AsignarDescripcion(e, indxRow)}
                            placehold={RowRes.descripcion ? RowRes.descripcion : ''}
                          />
                          
                          <div className='list-Prods'   style={ FlagIndxProd === indxRow ? {} : {display:'none'} }>
                              {
                                  ResProds?.map(Prod =>{
                                      return(
                                          <div className='listed-prod' style={{fontWeight:'bold', cursor: 'pointer'}} onClick={()=> EstablecerProductosFact(Prod, indxRow)}>
                                            {Prod.descripcion}
                                          </div>
                                      )
                                  })
                              }
                          </div>
                        </div>
                      </div>
                      
                      <div className='resumen-elemnt' style={{flex:'2'}}> {/* Valor Unitario */}

                          <Inpt
                              type={'number'}
                              value={RowRes.valorUnitario}
                              setValue={(e)=>AsignarValorUnitario( e, indxRow)} //RowsProds[indxRow].descripcion
                              forceInputSty={{textAlign:'right'}}
                              minVal={1}
                              maxVal={9999}
                            />
                        
                      </div>
                      
                      
                      <div className='resumen-elemnt' style={{flex:'2'}}>{ formatMoney(RowRes?.totalsat) }</div> {/* Subtotal */}
                      
                      <div className='resumen-elemnt' style={{flex:'1'}}> {/* Acciones */}

                        {/* <div className='action-row' onMouseEnter={(e)=>setRowInfo(RowRes)}>
                          <Btn
                            type={'iconoMini'}
                            imagen={Editar}
                            forceBtnSty={{background:'#3BAF29'}}
                            clicked={OpenModal_EditRow}
                          
                          />
                        </div> */}

                        <div className='action-row'>
                          <Btn
                            type={'iconoMini'}
                            imagen={Eliminar}
                            forceBtnSty={{background:'#F40000'}}
                            clicked={()=>DelRow(indxRow)}
                          
                          />
                        </div>
                      </div>

                    </div>
                  )
                })
              }   

              
              <div className='newRow' >
                  <Btn
                    type={'add'}
                    imagen={Cruz}
                    clicked={AddRow}
                  />
              </div>
            </div>



          </div>

          <div className='PaymentArea' style={Fltr_TypeFact < 1 ? {display:'none'} : {}}>
            
            <div className='observs'>  
              <div className='in-Payobserved'>
                <Inpt
                    type={'text'}
                    tittle={'Observaciones'}
                    value={Observaciones}
                    setValue={setObservaciones}
                    maxLength={50}
                  />
              </div>

              <div className='boton-aceptar'>
                <Btn
                  type={'accept'}
                  descript={'Generar Factura'}
                  clicked={EmitirFactura}
                  disabled={ResumsTotal < 1 || rfc.length < 12}
              
              />
                  
              </div>
              
            </div>
            
            <div className='totals'>
              <div className='totalsResumed'>
                
                  <div className='Resumed-subtotal'>
                    <div className='ResumedName'>Subtotal</div>
                    <p className='Resumed-text'>{formatMoney(ResumsSubtotal)}</p>
                  </div>
                  
                  
                  {/* <div className='Resumed-subDesc'>
                    <div className='ResumedName'>Subtotal - Descuento</div>
                    <p className='Resumed-text'>{formatMoney(ResumsSubDesc)}</p>
                  </div> */}
                  
                  <div className='Resumed-impTrasl'>
                    <div className='ResumedName'>Impuestos - Traslado</div>
                    <p className='Resumed-text'>{formatMoney(ResumsImpuestos)}</p>
                  </div>
                  
                  <div className='Resumed-total'>
                    <div className='ResumedName'>Total</div>
                    <p className='Resumed-text' style={ Fltr_TypeFact === 1 || Fltr_TypeFact === 5 ? {display:'none'} : {}}>{formatMoney(ResumsTotal)}</p>
                    <div className='InptTotalito' style={ Fltr_TypeFact === 1 || Fltr_TypeFact === 5 ? {flex:'1'} : {display:'none'}}>
                    <Inpt
                      forceLayoutSty={{width:'60%'}}
                      type={'number'}
                      value={ResumsTotal}
                      setValue={handleTotalito}
                      forceInputSty={{textAlign: 'right'}}

                    />
                    </div>
                  </div>




              </div>
            </div>

          </div>

        </div>

        
        {/* <ModalComponent
            StatusModal={Modal_AlterProducto !== ''}
            CerrarModal={CloseModal_AlterProductoRow}
            Titulo={CheckAlterName(Modal_AlterProducto)}
            Contenido={RenderItemAlterProductoRow()}
            ancho={'80%'}
            alto={'45%'}
            forceLayoutSty

          /> */}

    </div>
      }
    </LayoutAddFactura>
  )
}

export default AddFact