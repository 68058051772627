import React, { useEffect, useState } from "react";
import { useAuth } from '../../../context/AuthContext'
import axios from 'axios'
import { useNavigate } from "react-router-dom";

export const useSidebar = () => {
  
  const navigate = useNavigate()
  const { Autorized } = useAuth()
  const [ActualCategory, setActualCategory] = useState(localStorage.getItem('ActCat') ? JSON.parse(localStorage.getItem('ActCat')) : 'inicio');

  const MoveCategory = (categ) => {
    localStorage.setItem('ActCat', JSON.stringify(categ))
    setActualCategory(categ)
    navigate(`/${categ}`)
  }

  

  return {
    ActualCategory,
    setActualCategory,
    MoveCategory,
    Autorized
  };
};
