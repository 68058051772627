import axios from 'axios'
import React, { useState } from 'react'
import { useAuth } from '../../../../context/AuthContext'
import Swal from 'sweetalert2'

export const usePaginacion = (props) => {
  
    const {User} = useAuth()

    const [TextoSearch, setTextoSearch] = useState('')
    const [Pg, setPg] = useState(0)
    const [MaxPg, setMaxPg] = useState(0)
    const [RegsPg, setRegsPg] = useState(10)
    const [Loading, setLoading] = useState(false)
    const [InfoTab, setInfoTab] = useState()

    const GetPages = async(url) =>{
          setLoading(true)
          let config = {
            method: "get",
            url: url,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + User.token,
            },
          };

          await axios(config)
          .then(res=>{

            if (res.data.result) {
              
              //Se han cambiado los valores esperados en la respuesta para realizar pruebas en el apartado de facturacion, que aun no tiene los servicios actualizados para version de roles

              setPg(res.data.currentPage === undefined ? 0 : res.data.currentPage)
              setMaxPg(res.data.totalPages === undefined ? 0 : res.data.totalPages)
              setRegsPg(res.data.recordsByPage === undefined ? 10 : res.data.recordsByPage)
              setInfoTab(res.data.result)   
              setLoading(false)
            } else {
              
              setPg(res.data.paginaActual === undefined ? 0 : res.data.paginaActual)
              setMaxPg(res.data.totalPaginas === undefined ? 0 : res.data.totalPaginas)
              setRegsPg(res.data.registrosPorPagina === undefined ? 10 : res.data.registrosPorPagina)
              setInfoTab(res.data.resultado)   
              setLoading(false)
            }

          })
  
          .catch(err =>{ 
  
            
            console.error(err.response)
            setLoading(false)

  
            if (err.response.status === 401) {
              
              // Swal.fire({
              //   title: 'Error al obtener los datos',
              //   text: `Fallo al autenticar acceso`,
              //   icon: 'error',
              //   position: 'top',
              //   confirmButtonText: 'Aceptar',
              //   confirmButtonColor: '#52C150'
              // })
            }

            // if (err.message === 'Network Error') {
            //   Swal.fire({
            //     title: 'Error',
            //     text: `Fallo de conexion a internet`,
            //     icon: 'error',
            //     position: 'top',
            //     confirmButtonText: 'Aceptar',
            //     confirmButtonColor: '#52C150'
            //   })
            // }else{
            //   Swal.fire({
            //     title: 'Error',
            //     text: `${err.message}`,
            //     icon: 'error',
            //     position: 'top',
            //     confirmButtonText: 'Aceptar',
            //     confirmButtonColor: '#52C150'
            //   })
            // }
          })
      }
  
    const NextPg = (url) =>{
        if (Pg < MaxPg) {
            // setPg(Pg +=1);
            // alert(Pg +' - '+ MaxPg)
            GetPages(url);
        }
    }
    const PrevPg = (url) =>{
        if (Pg > 1) {
            // setPg(Pg -=1);
            // setActiveDots("");
            GetPages(url);
        }
    }
    const ProNextPg = (url) =>{
        if (Pg < MaxPg) {
            // setPg(MaxPg);
            // setActiveDots("");
            GetPages(url);
        }
    }
    const ProPrevPg = (url) =>{
        if (Pg > 1) {
            // setPg(1); 
            // setActiveDots("");
            GetPages(url);
        }
    }

    const ChangeRegs = (Regis) =>{
        alert('chrgs')
        setRegsPg(Regis)
        GetPages(props.BaseUrl)
    }
    // https://pkt1internodesarrollo.paq1.com.mx/Api/V3/        Clientes/Paginador?     TipoCliente=1   &Filtro=1   &Buscar=c   &Pagina=1   &Registros=10
  
    return {
      Pg, MaxPg, RegsPg, Loading, InfoTab, setLoading,
      GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg

  }
}
